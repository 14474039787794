import { ECAutocomplete, ECAutocompleteStyled } from '../ECAutocomplete';
import { ECBox } from '../ECBox';
import { ECTextField } from '../ECTextField';
import { ECTypography } from '../ECTypography';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as _ from 'lodash';
import { ECCircularProgress } from '../ECCircularProgress';
import { FormHelperText, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { ArrowDropDown } from '@mui/icons-material';
import { ECChip } from '../ECChip';
import { Chip } from '../ECChipAutocomplete';
import { ECButton } from '../ECButton';
import { Close } from '@mui/icons-material';
import { ECMenuItem } from '../ECSelect';
import { ECCheckbox } from '../ECCheckbox';

interface ECAutocompletePaginatedProps {
  fieldName: string;
  value?: any;
  disabled?: boolean;
  isReadOnlyForm?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  label?: string;
  variant?: any;
  sx?: any;
  isLoading?: boolean;
  noMoreDataToFetch?: boolean;
  queryParams?: any;
  shouldUseOriginalQueryParams?: boolean;
  useQuery?: any;
  obAlias?: string;
  st?: string;
  sbAlias?: string;
  error?: boolean;
  validationMessage?: string;
  required?: boolean;
  multiple?: boolean;
  groupBy?: (option: any) => string;
  groupByTransform?: (options: any[]) => any[];
  onLoadMoreData?: (fieldName: string, newValue?: string) => void;
  onChange?: (newValue) => void;
  helperText?: string;
  limitTags?: number;
  shouldUseFirstOptionAsDefault?: boolean;
  filterOptionsIds?: string[];
  extraSearchField?: string;
  optionNameAttribute?: string;
  extraSearchFieldGroup?: 'or' | 'and';
  extraSearchFieldsAlias?: string[];
  renderCustomOption?: (option: any) => React.ReactNode;
  renderCustomSelectedValue?: (value: any) => React.ReactNode;
  shouldSkipQuery?: boolean;
}

const DEBOUNCE_TIME = 500;
const PER_PAGE = 10;

export const ECAutocompletePaginated = ({
  fieldName,
  value,
  disabled,
  isReadOnlyForm,
  readOnly,
  placeholder,
  label,
  variant,
  isLoading: isLoadingFromProps,
  sx,
  noMoreDataToFetch: noMoreDataToFetchFromProps,
  queryParams,
  shouldUseOriginalQueryParams,
  useQuery,
  obAlias,
  sbAlias,
  st,
  error,
  validationMessage,
  multiple,
  onLoadMoreData,
  onChange,
  helperText,
  limitTags,
  shouldUseFirstOptionAsDefault,
  optionNameAttribute = 'name',
  filterOptionsIds,
  extraSearchField,
  extraSearchFieldGroup,
  extraSearchFieldsAlias,
  renderCustomOption,
  renderCustomSelectedValue,
  shouldSkipQuery,
  groupBy,
  groupByTransform,
}: ECAutocompletePaginatedProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const [page, setPage] = useState(0);

  const params = useMemo(() => {
    const initialParams = shouldUseOriginalQueryParams
      ? queryParams
      : queryParams
        ? {
            p: page,
            t: PER_PAGE,
            sb: sbAlias || obAlias || 'name',
            s: text ? `*${text}*` : '',
            st: st || undefined,

            // extra search field. If more fields are added in the future, please create and array and map
            //field 1
            sglo: extraSearchFieldGroup ? extraSearchFieldGroup : undefined,
            sg: extraSearchField ? 0 : undefined,
            // field 2
            sb1: extraSearchField ? extraSearchField : undefined,
            s1: extraSearchField && text ? `*${text}*` : undefined,
            sg1: extraSearchField ? 0 : undefined,
            sglo1: extraSearchFieldGroup ? extraSearchFieldGroup : undefined,

            ob: obAlias || 'name',
            o: 'a',
            ...queryParams,
          }
        : {
            p: page,
            t: PER_PAGE,
            sb: sbAlias || obAlias || 'name',
            s: text ? `*${text}*` : '',
            st: st || undefined,

            // extra search field. If more fields are added in the future, please create and array and map
            // field 1
            sglo: extraSearchFieldGroup ? extraSearchFieldGroup : undefined,
            sg: extraSearchField ? 0 : undefined,
            // field 2
            sb1: extraSearchField ? extraSearchField : undefined,
            s1: extraSearchField && text ? `*${text}*` : undefined,
            sg1: extraSearchField ? 0 : undefined,
            sglo1: extraSearchFieldGroup ? extraSearchFieldGroup : undefined,

            ob: obAlias || 'name',
            o: 'a',
          };

    const finalParams = shouldUseOriginalQueryParams
      ? initialParams
      : Object.assign(
          {},
          initialParams,
          ...(extraSearchFieldsAlias?.map((alias, index) => ({
            [`sb${index + 1}`]: alias,
            [`s${index + 1}`]: text ? `*${text}*` : undefined,
            [`sg${index + 1}`]: 0,
            [`sglo${index + 1}`]: 'or',
          })) || []),
        );

    return finalParams;
  }, [
    extraSearchField,
    extraSearchFieldGroup,
    extraSearchFieldsAlias,
    obAlias,
    page,
    queryParams,
    sbAlias,
    shouldUseOriginalQueryParams,
    text,
  ]);

  const { data, isFetching } =
    useQuery?.(params, {
      skip: disabled || shouldSkipQuery,
    }) || {};

  useEffect(() => {
    setPage(0);
    setOptions([]);
  }, [queryParams]);

  useEffect(() => {
    if (!value) {
      setText('');
      setTextForInput('');
    }
  }, [value]);

  const noMoreDataToFetch = useMemo(() => {
    if (!data) {
      return false;
    }

    if (!data?.config?.pagination) {
      return true;
    }
    if (data.config.pagination.totalCount === -1) {
      return !(data.data.length === (queryParams?.t || PER_PAGE));
    }

    return (
      (page + 1) * (queryParams?.t || PER_PAGE) >=
      data?.config?.pagination?.totalCount
    );
  }, [data, page, queryParams]);

  const handleClick = useCallback(
    data => () => {
      if (multiple) {
        const isOptionAlreadySelected =
          value && value?.findIndex(v => v.id === data.id) !== -1;
        if (isOptionAlreadySelected) {
          onChange?.(value?.filter(v => v.id !== data.id));
        } else {
          onChange?.([...(value || []), data]);
        }
      } else {
        onChange?.(data);
        setTextForInput(data?.name);
        closeOptions();
      }
    },
    [value, multiple, onChange],
  );

  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (!!data && !isFetching) {
      setOptions(prevOptions => {
        return _.flatten(
          _.uniqBy(
            [
              _.isEmpty(value) ? undefined : value,
              ...prevOptions,
              ...(data?.data || data),
            ],
            'id',
          )?.filter(
            option =>
              option && !filterOptionsIds?.includes(option.id) && !!option?.id,
          ),
        );
      });

      const isEndpointPaginated = !!data?.config;
      if (page === 0 && isEndpointPaginated) {
        setPage(1);
      }
    }
  }, [data, isFetching, filterOptionsIds]);

  const optionsWithLoading = useMemo(() => {
    if (
      !_.compact(options)?.length ||
      (options?.[0]?.id &&
        options?.length === 1 &&
        options?.[0]?.id === value?.id)
    ) {
      return [{ id: 'empty', name: 'No options' }];
    }

    if (noMoreDataToFetch) {
      return _.uniqBy([...options], 'id');
    }

    return _.uniqBy([...options, { id: 'loading', name: 'Loading...' }], 'id');
  }, [options, noMoreDataToFetch, value?.id]);

  const renderAutocompleteOption = useCallback(
    (props, option, state) => {
      if (!option || option?.[optionNameAttribute] === value?.name) {
        return;
      }

      if (option.id === 'empty') {
        return (
          <ECBox
            key={`autocomplete-option-${option?.id}`}
            id={`autocomplete-id-${option?.id}-${option?.[optionNameAttribute]}`}
            bgcolor={theme => theme.palette.common.white}
            height={42}
          >
            <ECBox display="flex" p={1}>
              <ECTypography variant="body1">No options</ECTypography>
            </ECBox>
          </ECBox>
        );
      }

      if (option.id === 'loading') {
        return (
          <ECBox
            key={`autocomplete-option-${option?.id}`}
            id={`autocomplete-id-${option?.id}-${option?.[optionNameAttribute]}`}
            bgcolor={theme => theme.palette.common.white}
            height={42}
          >
            <ECBox display="flex" justifyContent="center" p={1}>
              <ECCircularProgress size={20} />
            </ECBox>
          </ECBox>
        );
      }

      return (
        <ECBox
          data-name={option?.id === optionsWithLoading?.[0]?.id ? 'first' : ''}
          key={`autocomplete-option-${option?.id}`}
          id={`autocomplete-id-${option?.id}-${option?.[optionNameAttribute]}`}
          bgcolor={theme => theme.palette.common.white}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              bgcolor: theme => theme.palette.primary.outlinedHoverBackground,
            },
          }}
          p={1}
          onClick={handleClick(option)}
        >
          {renderCustomOption ? (
            renderCustomOption(option)
          ) : (
            <ECTypography variant="body1">
              {option?.[optionNameAttribute]}
            </ECTypography>
          )}
        </ECBox>
      );
    },
    [optionsWithLoading, handleClick, renderCustomOption, value?.name],
  );

  const openOptions = () => setIsOpen(true);
  const closeOptions = () => setIsOpen(false);

  const handleChange = (event, value, reason, details) => {
    setTextForInput(value?.name);
    onChange?.(value);
  };

  const [hasPopulatedFirstOption, setHasPopulatedFirstOption] = useState(false);

  useEffect(() => {
    if (
      !hasPopulatedFirstOption &&
      !value &&
      shouldUseFirstOptionAsDefault &&
      typeof optionsWithLoading?.[0]?.id === 'number'
    ) {
      setHasPopulatedFirstOption(true);
      handleChange(_, optionsWithLoading?.[0], _, _);
    }
  }, [optionsWithLoading]);

  const debouncedHandleSearchChange = useRef(
    _.debounce((e, newValue, reason) => {
      if (reason === 'reset' || value?.id === '') {
        return;
      }

      const optionEl = document.querySelector(`[data-name="first"]`);
      optionEl?.scrollIntoView({ block: 'nearest', inline: 'start' });

      if (value?.id && newValue === value?.name) {
        return;
      }

      setText(newValue);
      setPage(0);
      setOptions([]);
    }, DEBOUNCE_TIME),
  ).current;

  const [textForInput, setTextForInput] = useState(value?.name);
  const handleInputChange = (e, newValue, reason) => {
    if (reason === 'reset') {
      return;
    }
    setTextForInput(newValue);
    debouncedHandleSearchChange(e, newValue, reason);
  };

  const handleBlur = () => {
    setTextForInput(value?.name);
  };

  const handleClear = () => {
    if (multiple) {
      onChange?.([]);
      setText('');
      setTextForInput('');
    } else {
      setText('');
      setTextForInput('');
      onChange?.('');
    }
  };

  const isReadOnly = isReadOnlyForm || readOnly;

  if (!!renderCustomSelectedValue && !!value) {
    return (
      <ECBox display="flex" flexDirection="column">
        <ECTypography variant="h6" mb={1}>
          {placeholder}
        </ECTypography>
        <ECBox
          display="fle3x"
          flexDirection="column"
          bgcolor={theme => theme.palette.action.hover}
          borderRadius={1}
        >
          {!readOnly && (
            <ECBox display="flex" justifyContent="flex-end" p={1}>
              <ECButton
                color="error"
                variant="text"
                startIcon={
                  <Close sx={theme => ({ color: theme.palette.error.dark })} />
                }
                onClick={handleClear}
              >
                Remove
              </ECButton>
            </ECBox>
          )}

          {renderCustomSelectedValue(value)}
        </ECBox>
      </ECBox>
    );
  }

  if (data && !data?.config && !isFetching) {
    return (
      <ECAutocomplete
        disablePortal
        value={value}
        options={(data?.data || data)?.length ? data?.data || data : []}
        sx={{
          '.MuiFilledInput-root': {
            paddingTop: placeholder ? undefined : '10px',
            paddingBottom: placeholder ? undefined : '10px',
            paddingRight: '8px !important',
          },
          ...sx,
        }}
        placeholder={placeholder}
        label={label}
        getOptionKey={option => option?.id}
        getOptionLabel={option => option?.[optionNameAttribute] ?? ''}
        isOptionEqualToValue={(option, value) => {
          return value?.id
            ? option?.id === value?.id
            : option?.[optionNameAttribute] === value?.name;
        }}
        onChange={(event, newValue) => {
          onChange?.(newValue);
        }}
        readOnly={isReadOnly}
        errorMessage={validationMessage}
        multiple={multiple}
        filterSelectedOptions={multiple}
        disableCloseOnSelect={multiple}
        renderTags={
          multiple
            ? (value, getTagProps) => {
                return value.map((option: Chip, index: number) => (
                  <ECChip
                    label={option?.label || option?.[optionNameAttribute]}
                    variant="outlined"
                    {...getTagProps({ index })}
                    onDelete={
                      isReadOnly ? undefined : getTagProps({ index }).onDelete
                    }
                  />
                ));
              }
            : undefined
        }
        renderOption={
          multiple
            ? (props, option, { selected }) => {
                return (
                  <ECMenuItem
                    {...props}
                    sx={{ width: '100%' }}
                    key={option.fieldName}
                  >
                    <ECCheckbox checked={selected} />
                    {option.label || option.name}
                  </ECMenuItem>
                );
              }
            : undefined
        }
      />
    );
  }

  return (
    <>
      <ECAutocompleteStyled
        id={fieldName}
        disablePortal
        sx={{
          '.MuiFilledInput-root': {
            paddingTop: placeholder ? undefined : '10px',
            paddingBottom: placeholder ? undefined : '10px',
            paddingRight: '8px !important',
          },
          ...sx,
        }}
        multiple={multiple}
        value={value}
        disabled={disabled}
        groupBy={
          groupBy && groupByTransform ? option => groupBy(option) : undefined
        }
        options={
          groupBy && groupByTransform
            ? groupByTransform(optionsWithLoading)
            : optionsWithLoading
        }
        loading={isFetching}
        getOptionKey={option => option?.id}
        filterOptions={x => x}
        readOnly={isReadOnly}
        getOptionLabel={option => option?.[optionNameAttribute] ?? ''}
        isOptionEqualToValue={(option, value) =>
          value?.id
            ? option?.id === value?.id
            : option?.[optionNameAttribute] === value?.name
        }
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        popupIcon={null}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;
            if (
              listboxNode.scrollTop + listboxNode.clientHeight >=
                listboxNode.scrollHeight - 1 &&
              !noMoreDataToFetch &&
              !isFetching
            ) {
              setPage(prevPage => prevPage + 1);
            }
          },
          role: 'list-box',
        }}
        inputValue={
          textForInput !== undefined ? textForInput : value?.name || text
        }
        limitTags={isReadOnly ? limitTags : undefined}
        getLimitTagsText={more => `& ${more} more`}
        renderInput={params => (
          <ECTextField
            {...params}
            placeholder={placeholder}
            label={label}
            error={!!validationMessage}
            variant={isReadOnlyForm || readOnly ? 'standard' : variant}
            hiddenLabel={!label}
            sx={{
              '&:hover': {
                '.clear': {
                  display: 'inline-flex',
                },
              },
            }}
            value={
              textForInput !== undefined ? textForInput : value?.name || text
            }
            InputProps={{
              ...params.InputProps,
              readOnly: isReadOnlyForm || readOnly,
              sx: {
                '&::before': {
                  visibility: isReadOnly || disabled ? 'hidden' : 'visible',
                },
                '&::after': {
                  visibility: isReadOnly || disabled ? 'hidden' : 'visible',
                },
              },
              endAdornment:
                isReadOnly || disabled ? null : (
                  <ECBox gap={2}>
                    {isFetching ? <ECCircularProgress size={14} /> : null}
                    <IconButton
                      className="clear"
                      onClick={handleClear}
                      sx={{ display: 'none' }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                    <ArrowDropDown fontSize="small" sx={{ opacity: 0.7 }} />
                  </ECBox>
                ),
            }}
          />
        )}
        filterSelectedOptions={multiple}
        disableCloseOnSelect={multiple}
        onOpen={openOptions}
        onClose={closeOptions}
        open={isOpen}
        renderOption={renderAutocompleteOption}
        onChange={handleChange}
        renderTags={
          multiple
            ? (value, getTagProps) => {
                return value.map((option: Chip, index: number) => (
                  <ECChip
                    key={`${option?.label || option?.[optionNameAttribute]}-${index}`}
                    label={option?.label || option?.[optionNameAttribute]}
                    color={option?.color}
                    variant="outlined"
                    {...getTagProps({ index })}
                    onDelete={
                      isReadOnly ? undefined : getTagProps({ index }).onDelete
                    }
                  />
                ));
              }
            : undefined
        }
      />
      {validationMessage && (
        <FormHelperText
          sx={theme => ({
            color: theme.palette.error.main,
          })}
        >
          {validationMessage}
        </FormHelperText>
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
};
