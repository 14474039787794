import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Filter } from 'types/Filter';
import { P } from 'types/Permission';
import { DateRangeFilterType } from 'app/components/ECDateTimePicker';
import { TreeNode } from 'app/components';

export const GLOBAL_SEARCH_MODULE_OPTIONS = [
  {
    label: 'All',
  },
  {
    label: 'Work Orders',
  },
  {
    label: 'Proposals',
  },
  {
    label: 'Invoices',
  },
];

export const enum GlobalSearchModules {
  All = 0,
  WorkOrders = 1,
  Proposals = 2,
  Invoices = 3,
}

export const GLOBAL_SEARCH_MODULE_OPTION_VALUES = [
  GlobalSearchModules.All,
  GlobalSearchModules.WorkOrders,
  GlobalSearchModules.Proposals,
  GlobalSearchModules.Invoices,
];

export interface PageScopes {
  canGetAll?: P;
  canAdd?: P;
  canEdit?: P;
  canDelete?: P;
  canGetById?: P;
}
interface ActiveFilter {
  name?: string; // active filter might have a name might not
  isEdited?: boolean; // acive filter is edited when a chip is closed
  isAdvanced?: boolean; // acive filter is advanced when it is for an advanced search, not simple
  dateAt?: Date | string | null;
  dateAfter?: Date | string | null;
  dateBefore?: Date | string | null;
  dateRangeFilterType?: DateRangeFilterType;
  selectedOption?: string;
  activeStatus?: boolean; // true => brings only active items, false => brings only inactive items
  missingVendorId?: boolean; // true => brings only items with missing vendordor Ids
  selfPerforming?: boolean; // true => brings only self performing regions, false => brings only non self performing regions
  assignedToMe?: boolean; // true => brings only assigned to me items, false => brings all items
  assignedLocations?: boolean;
  awaitingInvoice?: boolean;
  assetType?: boolean;
  techNotAssigned?: boolean; // true => brings only items without technician assigned
  statuses?: string[]; // filter for status
  eta?: string;
  setBy?: string;
  concepts?: { name: string; id: number }[];
  isSelectAllConcepts?: boolean;
  assetTypes?: { name: string; id: number }[]; // filter for asset types name
  isSelectAllAssetTypes?: boolean;
  locations?: { name: string; id: number }[]; // filter for locations
  manufacturer?: any;
  isSelectAllManufacturers?: boolean;
  internalTechs?: { name: string; id: number }[];
  customers?: { name: string; id: number }[];
  isSelectAllCustomers?: boolean;
  isSelectAllInternalTechs?: boolean;
  isSelectAllLocations?: boolean;
  categories?: string[] | null; // filter for categories
  statusAlias?: string; // alis used in the query to filter by status (active | inactive)
  dateAlias?: string;
  dateAliasLabel?: string;
  filterFields?: Filter[]; // one or several `field: value`
  endpoint?: string;
  simpleSearchQuery?: string;
  hierarchy?: TreeNode[];
  users?: TreeNode[];
}
interface FilterState {
  activeFilter: Record<string, ActiveFilter>;
  tableLayout: ActiveFilter;
  endpoint: string;
}

interface GlobalFilter {
  module?: number;
  globalQuery?: string;
  mustTriggerListQuery?: boolean;
}

interface Snackbar {
  id: string;
  severity: 'error' | 'success';
  message: string;
  onClose?: () => void;
}
interface PageState {
  title: string;
  filter: FilterState;
  globalFilter: GlobalFilter;
  scopes: PageScopes;
  snackbar: Snackbar;
}

export const initialState: PageState = {
  scopes: {
    canGetAll: P.Unset,
    canAdd: P.Unset,
    canEdit: P.Unset,
    canDelete: P.Unset,
    canGetById: P.Unset,
  },
  title: '',
  filter: {
    activeFilter: {},
    tableLayout: {},
    endpoint: '',
  },
  globalFilter: {
    module: 0,
    globalQuery: '',
  },
  snackbar: {
    id: '',
    severity: 'error',
    message: '',
  },
};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setActiveFilter(state, action: PayloadAction<ActiveFilter>) {
      const path = window.location.href;

      const pathWithoutParams = path.split('?')?.[0];
      const storedFilter = state.filter.activeFilter?.[pathWithoutParams] || {};

      const newActiveFilter = {
        ...storedFilter,
        ...action.payload,
        // if name is not passed here the previous name is kept on the screen
        // yes we can use spread operator but just for clarity for other developersw
        name: action.payload.name ?? storedFilter.name,
        isEdited: action.payload.isEdited ?? false,
        isAdvanced: action.payload.isAdvanced ?? storedFilter.isAdvanced,
        filterFields: action.payload.filterFields ?? storedFilter.filterFields,
        isSelectAllAssetTypes:
          action.payload.isSelectAllAssetTypes ??
          storedFilter.isSelectAllAssetTypes,
        isSelectAllLocations:
          action.payload.isSelectAllLocations ??
          storedFilter.isSelectAllLocations,
        isSelectAllManufacturers:
          action.payload.isSelectAllManufacturers ??
          storedFilter.isSelectAllManufacturers,
        isSelectAllInternalTechs:
          action.payload.isSelectAllInternalTechs ??
          storedFilter.isSelectAllInternalTechs,
        categories:
          action.payload.categories === null
            ? action.payload.categories
            : action.payload.categories ?? storedFilter.categories,
        selfPerforming:
          action.payload.selfPerforming ?? storedFilter.selfPerforming,
        assignedToMe: action.payload.assignedToMe ?? storedFilter.assignedToMe,
        assignedLocations:
          action.payload.assignedLocations ?? storedFilter.assignedLocations,
        missingVendorId:
          action.payload.missingVendorId ?? storedFilter.missingVendorId,
        assetType: action.payload.assetType ?? storedFilter.assetType,
        dateAlias: action.payload.dateAlias ?? storedFilter.dateAlias,
        statusAlias: action.payload.statusAlias ?? storedFilter.statusAlias,
        endpoint: action.payload.endpoint ?? storedFilter.endpoint,
        concepts: action.payload.concepts ?? storedFilter.concepts,
        isSelectAllConcepts:
          action.payload.isSelectAllConcepts ??
          storedFilter.isSelectAllConcepts,
        setBy: action.payload.setBy ?? storedFilter.setBy,
      };

      state.filter.activeFilter = {
        ...state.filter.activeFilter,
        [pathWithoutParams]: { ...newActiveFilter },
      };
    },
    setActiveTableLayout(state, action: PayloadAction<ActiveFilter>) {
      state.filter.tableLayout = { ...action.payload };
    },
    setCleanActiveFilter(state) {
      state.filter.activeFilter = { ...initialState.filter.activeFilter };
    },
    setGlobalFilter(state, action: PayloadAction<GlobalFilter>) {
      const newGlobalFilter = {
        // if name is not passed here the previous name is kept on the screen
        // yes we can use spread operator but just for clarity for other developers
        module: action.payload.module ?? state.globalFilter.module,
        globalQuery:
          action.payload.globalQuery ?? state.globalFilter.globalQuery,
        mustTriggerListQuery:
          action.payload.mustTriggerListQuery ??
          state.globalFilter.mustTriggerListQuery,
      };
      state.globalFilter = newGlobalFilter;
    },
    setFilterEndpoint(
      state,
      action: PayloadAction<
        string | { endpoint: string; activeStatus?: boolean }
      >,
    ) {
      state.filter.endpoint =
        action.payload instanceof Object
          ? action.payload.endpoint
          : action.payload;
    },
    setPageScopes(state, action: PayloadAction<PageScopes>) {
      state.scopes = { ...action.payload };
    },
    setSnackbar(state, action: PayloadAction<Omit<Snackbar, 'id'>>) {
      state.snackbar = { ...action.payload, id: new Date().toString() };
    },
  },
});

export const {
  setActiveFilter,
  setCleanActiveFilter,
  setGlobalFilter,
  setFilterEndpoint,
  setTitle,
  setPageScopes,
  setSnackbar,
} = pageSlice.actions;
export default pageSlice.reducer;
