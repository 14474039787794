import {
  ECBox,
  ECTypography,
  ECSPForm,
  ECPaper,
  ECSPHowItWorks,
} from 'app/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';
import { setTitle } from 'store/slice/page';
import { bgImage } from './assets';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

export function SPDirectorySearch() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch('/panel/*');

  const handleSearch = (trade, location, name) => () => {
    navigate(`${match?.pathnameBase}/service-providers/sp-directory/results`, {
      state: { trade, location, name },
    });
  };

  useEffect(() => {
    dispatch(setTitle(t('translation:panelDrawer.spDirectory')));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{t('translation:panelDrawer.spDirectory')}</title>
      </Helmet>
      <ECBox display="flex" height="100%" width="100%">
        <img
          width="100%"
          style={{ position: 'absolute' }}
          src={bgImage}
          alt="detail"
        />
        <ECBox
          display="flex"
          height="100%"
          width="100%"
          flexDirection="column"
          pt="31vh"
          alignItems="center"
          zIndex={1}
        >
          <ECTypography
            textAlign="center"
            variant="h4"
            color={theme => ({ color: theme.palette.common.white })}
          >
            {t('translation:spDirectory.search.title')}
          </ECTypography>
          <ECTypography
            textAlign="center"
            fontWeight="bold"
            variant="h4"
            color={theme => ({ color: theme.palette.common.white })}
          >
            {t('translation:spDirectory.search.titleBold')}
          </ECTypography>

          <ECBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <ECPaper elevation={3} sx={{ width: '70vw', mt: 4, p: 2, pt: 3 }}>
              <ECSPForm onSearch={handleSearch} variant="standard" />
            </ECPaper>
          </ECBox>

          <ECSPHowItWorks position="relative" bottom={0} />
        </ECBox>
      </ECBox>
    </>
  );
}
