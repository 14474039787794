import { useMemo, useState } from 'react';
import { ECBox, ECGrid, ECTypography } from 'app/components';
import { limitStringMaxCharacters } from 'utils/strings/limit-string-max-characters';
import { CopyWorkorderContainer } from './CopyWorkorderContainer';
import DateWorkordersSection from './DateWorkordersSection';
import { Skeleton } from '@mui/material';

interface DispatchSwimlaneBoardProps {
  technicians: any[];
  originalContainerId?: string;
  dragPanelId?: string;
  isLoading?: boolean;
  techniciansSelectedLength?: number;
  setPreventDND?: (value: boolean) => void;
  preventDND?: boolean;
}

const startingDragColor = '#e1f5fe';

export const DispatchSwimlaneBoard = ({
  technicians,
  originalContainerId,
  dragPanelId,
  isLoading,
  techniciansSelectedLength,
  setPreventDND,
  preventDND,
}: DispatchSwimlaneBoardProps) => {
  const loadingSkeletons = useMemo(() => {
    return Array.from(
      new Array(
        techniciansSelectedLength && techniciansSelectedLength < 10
          ? techniciansSelectedLength
          : 10,
      ),
    ).map((_, index) => {
      return (
        <ECBox key={index} minWidth="254px" maxWidth="254px">
          <Skeleton variant="rectangular" height={'100%'} />
        </ECBox>
      );
    });
  }, [techniciansSelectedLength]);

  const techniciansMemoized = useMemo(() => {
    const containerTechId = Number(originalContainerId?.split('|')?.[0]);
    return technicians?.map(technician => {
      return (
        <ECBox
          minWidth="254px"
          maxWidth="254px"
          key={technician.id}
        >
          <ECBox
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={themes => ({
              backgroundColor:
                containerTechId === technician.id
                  ? startingDragColor
                  : themes.palette.grey[100],
            })}
            p={1}
          >
            <ECTypography lineHeight={'24px'}>
              {limitStringMaxCharacters(technician.assigneeFullName, 26)}
            </ECTypography>
            <ECTypography
              sx={{ opacity: '0.5' }}
              variant="caption"
              fontSize={'14px'}
              lineHeight={undefined}
            >
              {technician.serviceProviderName}
            </ECTypography>
          </ECBox>

          <ECBox
            sx={themes => ({
              backgroundColor:
                containerTechId === technician.id
                  ? startingDragColor
                  : themes.palette.grey[100],
              overflowX:'hidden',
                overflowY:'auto',
                display: 'flex',
                flexDirection: 'column',
            })}
            pb={1}
            px={1}
            key={technician.assigneeID}
            height={'calc(100% - 65px)'}
            gap='1rem'
          >
            {technician.dates?.map(date => {
              return (
                <DateWorkordersSection
                  key={`${technician.id}-${technician.assigneeFullName}-${date.date}`}
                  technicianId={technician.id}
                  technicianName={technician.assigneeFullName}
                  date={date}
                  dragPanelId={dragPanelId}
                  setPreventDND={setPreventDND}
                  preventDND={preventDND}
                />
              );
            })}
          </ECBox>
        </ECBox>
      );
    });
  }, [technicians, dragPanelId, originalContainerId, preventDND, setPreventDND]);

  return (
    <ECGrid
      display="flex"
      gap={2}
      p="16px 32px 0px 32px"
      maxWidth={'100%'}
      overflow={'auto'}
      height="calc(100% - 56px)"
    >
      {isLoading ? loadingSkeletons : techniciansMemoized}
    </ECGrid>
  );
};
