import { ECBox } from '../../../components/ECBox';
import { ECTypography } from '../../../components/ECTypography';
import { WorkOrderCard } from 'types/WorkOrders';
import _ from 'lodash';
import { ECChip, presetColors } from '../../../components/ECChip';
import { limitStringMaxCharacters } from 'utils/strings/limit-string-max-characters';
import { Error } from '@mui/icons-material';
import { ECTooltip } from 'app/components/ECTooltip';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DispatchWorkOrderModal } from './DispatchWorkOrderDetailsModal';
import { CopyWorkorderContainer } from './CopyWorkorderContainer';

interface DispatchWorkOrderCardProps {
  workOrder: WorkOrderCard;
  dragPanelId?: string;
  dragWOId?: string;
  setPreventDND?: (value: boolean) => void;
}

export const DispatchWorkOrderCard = ({
  workOrder,
  dragPanelId,
  dragWOId,
  setPreventDND,
}: DispatchWorkOrderCardProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCopyWoDrawerOpen, setIsCopyWoDrawerOpen] = useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<any>(null);
  const handleOpenCopyWoDrawer = (value: boolean, workOrder: any): void => {
    setIsCopyWoDrawerOpen(value);
    setSelectedWorkOrder(workOrder);
  };

  const memoizedCopyWorkorderContainer = useMemo(
    () => (
      <CopyWorkorderContainer
        selectedWorkOrder={selectedWorkOrder}
        isCopyWoDrawerOpen={isCopyWoDrawerOpen}
        onClose={() => setIsCopyWoDrawerOpen(false)}
      />
    ),
    [selectedWorkOrder, isCopyWoDrawerOpen, setIsCopyWoDrawerOpen],
  );
  useEffect(() => {
    if (setPreventDND) {
      setPreventDND(isCopyWoDrawerOpen || isOpenModal);
    }
  }, [isOpenModal, isCopyWoDrawerOpen, setPreventDND]);

  const onCloseModal = useCallback((event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setIsOpenModal(false);
  }, []);

  if (!workOrder) {
    return <></>;
  }

  const priorityColor = workOrder?.priorityColor || 'Grey';

  return (
    <>
      <ECBox
        key={workOrder.id}
        display="flex"
        borderColor={theme => theme.palette.other.divider}
        boxShadow={2}
        p={1}
        borderRadius={'0px 4px'}
        bgcolor={theme => theme.palette.common.white}
        borderLeft={theme =>
          priorityColor
            ? '4px ' +
              presetColors.find(color => color.title === priorityColor)?.color +
              ' solid'
            : undefined
        }
        mb={1}
        maxWidth={dragPanelId ? '200px' : 'auto'}
        minWidth={'200px'}
        onClick={() => setIsOpenModal(true)}
        sx={{
          cursor: dragPanelId === 'unAssignedPanel' ? 'not-allowed' : 'grab',
          '&:hover': { boxShadow: 4 },
          transform: dragPanelId ? 'rotate(8deg)' : 'none',
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        <ECBox display="flex" flexDirection="column" width="100%">
          <ECBox display={'flex'} alignItems={'center'}>
            {workOrder.overDue && (
              <ECTooltip title="Overdue" arrow>
                <Error
                  sx={{
                    color: 'red',
                    height: '18px',
                    width: '18px',
                    marginRight: 1,
                  }}
                />
              </ECTooltip>
            )}
            <ECTypography variant="h6" mr={1} fontSize="16px">
              #{workOrder.id}
            </ECTypography>
            <ECTooltip title={workOrder.priorityName}>
              <ECChip
                sx={{ padding: '0px', height: '20px' }}
                color={priorityColor}
                borderColor={priorityColor}
                label={limitStringMaxCharacters(
                  workOrder.priorityName ?? '',
                  7,
                )}
              />
            </ECTooltip>
          </ECBox>
          <ECTypography variant="caption" sx={{ opacity: '0.5', textOverflow: 'ellipsis' }}>
            {limitStringMaxCharacters(
              `Store #${workOrder.locationStoreNumber} • ${workOrder.locationName}`,
              33,
            )}
          </ECTypography>
          <ECTypography variant="caption" sx={{ opacity: '0.5', textOverflow: 'ellipsis' }}>
            {limitStringMaxCharacters(
              `${workOrder.assetName} • ${workOrder.problem}`,
              30,
            )}
          </ECTypography>
        </ECBox>
      </ECBox>
      {memoizedCopyWorkorderContainer}
      {isOpenModal && (
        <DispatchWorkOrderModal
          workOrderId={workOrder.id}
          priorityColor={priorityColor}
          isOpen={isOpenModal}
          onClose={onCloseModal}
          openCopyWoDrawer={handleOpenCopyWoDrawer}
        />
      )}
      {Number(dragWOId) === workOrder.id && (
        <ECBox
          key="dragPanelItem"
          display="flex"
          borderColor={theme => theme.palette.other.divider}
          boxShadow={2}
          p={1}
          borderRadius={'0px 4px'}
          bgcolor={theme => theme.palette.common.white}
          mb={1}
          maxWidth="auto"
          sx={{
            backgroundColor: '#0072ce15',
            height: '72px',
          }}
        >
          <ECBox display="flex" flexDirection="column" width="100%">
            <ECBox display="flex" alignItems="center"></ECBox>
          </ECBox>
        </ECBox>
      )}
    </>
  );
};
