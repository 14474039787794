import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { setFilterEndpoint } from 'store/slice/page';
import { AccountingViewSetByEnum } from 'types/Accounting';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { ECDynamicPageTemplate, MAX_SELECTABLE_OPTIONS } from 'app/components';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { useLazyGetAccountingGLCodesQuery } from 'services/accountingApi';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { useGetBranchesListQuery } from 'services/branchApi';

export function AccountingGLCodesPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/accounting'));
  }, [dispatch]);

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeSetBy = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]]
      ?.setBy;
  }, [window.location.href, activeFilterFromSelector]);

  const setByOptions = [
    AccountingViewSetByEnum.COMPANY,
    AccountingViewSetByEnum.LOCATIONS,
    AccountingViewSetByEnum.CONCEPTS,
  ];

  const { data: assetTypes } = useGetAssetTypesListQuery({
    t: 0,
  });

  const assetTypeFilterOptions = useMemo(() => {
    return (
      _.orderBy(assetTypes?.data, 'name')?.map(assetType => ({
        id: assetType?.id,
        label: assetType?.name,
        fieldName: assetType?.name,
        isInitialSelected:
          assetTypes?.data &&
          assetTypes?.data?.length <= MAX_SELECTABLE_OPTIONS,
      })) || []
    );
  }, [assetTypes?.data]);

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:pages.accounting.title')}
        useLazyGetListQuery={useLazyGetAccountingGLCodesQuery}
        customParams={{
          ob: 'assttp.name',
          o: 'a',
        }}
        useCreateMutation={{}}
        marginTop={false}
        withDrawer={false}
        shouldNotUseActiveFilter
        showAssetTypeNameFilter
        showConceptsFilter={activeSetBy === AccountingViewSetByEnum.CONCEPTS}
        showLocationFilter={activeSetBy === AccountingViewSetByEnum.LOCATIONS}
        useLocationFilterQuery={useGetBranchesListQuery}
        showSetByFilter
        setByFilterOptions={setByOptions}
        assetTypeFilterOptions={assetTypeFilterOptions}
        exportModuleName="accounting"
        enableExport
        enableTableCsvExport
      />
    </>
  );
}
