import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { formatDateForHistory } from 'utils/strings/formatDate';
import { ArrowForward, KeyboardArrowDown } from '@mui/icons-material';
import { ECGrid } from '../ECGrid';
import { camelCaseToWords } from 'utils/common';
import { Method } from 'types/BaseType';
import { HistoryList } from 'types/History';
import { P } from 'types/Permission';
import parse from 'html-react-parser';
import { ECCollapse } from '../ECCollapse';
import { ECButton } from '../ECButton';
import {
  checkIfValidArrayEntry,
  getHistoryEntry,
  isValidHistoryEntry,
  removeLeadingTrailingQuotes,
} from './historyUtil';
import moment from 'moment';
import _ from 'lodash';
import { processRfpBody, RfpHistoryAction } from './rfpHistory';

const CARD_MAX_HEIGHT_COLLAPSED = 66;
const invoiceProposalHeader = {
  invoice_reset: {
    header1: 'Invoice Reset AW',
    header2: 'Approval Workflow Reset Invoice',
  },
  proposal_reset: {
    header1: 'Proposal Reset AW',
    header2: 'Approval Workflow Reset proposal',
  },
};

const moduleToShowHeaderLabels = [
  'asset',
  'assetgeneral',
  'supplier',
  'purchaseorder',
];

const fieldsToConvert = ['purchaseorder.workflowStatus'];

const showAddedLabelForNewAction = ['purchaseorder'];

export interface ECHistoryEntryProps {
  moduleName: string;
  key?: string;
  previousBody?: Record<string, any>;
  entry: HistoryList;
  showVerticalDivider?: boolean;
  isApproved?: boolean;
}

const parseSupplierContacts = (module, data) => {
  if (module !== 'supplier') return [];

  const { supplierContacts, locationAddress, ...otherFieldsToRender } =
    data ?? {};
  const supplierContactsObjects = supplierContacts?.reduce(
    (acc, item, index) => {
      const indexToUse = index + 1;
      acc = {
        ...acc,
        [`contact${indexToUse}FirstName`]: item.firstName,
        [`contact${indexToUse}LastName`]: item.lastName,
        [`contact${indexToUse}Email`]: item.email,
        [`contact${indexToUse}PhoneNumber`]: item.phone,
        [`contact${indexToUse}JobTitle`]: item.jobTitle,
      };
      return acc;
    },
    {},
  );
  if (typeof otherFieldsToRender.status === 'boolean')
    otherFieldsToRender.status = otherFieldsToRender.status
      ? 'Active'
      : 'Inactive';
  otherFieldsToRender.location = locationAddress;
  return [supplierContactsObjects, otherFieldsToRender];
};

export const ECHistoryEntry = ({
  entry,
  key,
  moduleName,
  previousBody,
  showVerticalDivider,
  isApproved,
}: ECHistoryEntryProps) => {
  let {
    beforeBody,
    createdAt: date,
    fullName: author,
    jobTitle,
    email,
    company,
    method,
    body,
    targetPermission,
    description,
    module,
    id,
  } = entry;

  body = useMemo(() => {
    if (module === 'supplier') {
      const [bodySupplierContactsObjects, otherBodyToRender] =
        parseSupplierContacts(module, body);
      return {
        ...otherBodyToRender,
        ...bodySupplierContactsObjects,
      };
    }
    return body;
  }, [body, module]);

  beforeBody = useMemo(() => {
    if (module === 'supplier') {
      const [beforeBodySupplierContactsObjects, otherBeforeBody] =
        parseSupplierContacts(module, beforeBody);
      return {
        ...otherBeforeBody,
        ...beforeBodySupplierContactsObjects,
      };
    }
    return beforeBody;
  }, [beforeBody, module]);

  previousBody = useMemo(() => {
    if (module === 'supplier') {
      const [previousBodySupplierContactsObjects, otherPreviousBody] =
        parseSupplierContacts(module, previousBody);
      return {
        ...otherPreviousBody,
        ...previousBodySupplierContactsObjects,
      };
    }
    return previousBody;
  }, [previousBody, module]);

  const isUserApprovedHistory = useMemo(() => {
    if (!isApproved) return false;
    const isApprovedStatusUpdate =
      body?.statusUpdated?.toLowerCase() === 'approved';
    return isApprovedStatusUpdate;
  }, [isApproved, body]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowSeeMoreButton, setShouldShowSeeMoreButton] = useState(false);
  const [cardHeight, setCardHeight] = useState(1);
  const cardRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (cardRef.current?.clientHeight > CARD_MAX_HEIGHT_COLLAPSED) {
      setShouldShowSeeMoreButton(true);
    } else {
      setCardHeight(cardRef.current?.clientHeight);
    }
  }, [body, key]);

  const isResetWorkflowHistoryEntry = useMemo(() => {
    const _description = description?.toLowerCase();
    return (
      _description === 'invoice_reset' || _description === 'proposal_reset'
    );
  }, [description]);

  const invoiceProposalResetEntryFields = useMemo(() => {
    if (!isResetWorkflowHistoryEntry) return {};
    const approvalResetFields = {
      approvalWorkflowName: body?.approvalWorkflowName,
      resetDateAndTime: date,
      userName: author,
    };
    return approvalResetFields;
  }, [isResetWorkflowHistoryEntry, body, date, author]);

  const invoiceProposalResetHeader = useMemo(() => {
    if (!isResetWorkflowHistoryEntry) return null;
    const _description = description?.toLowerCase();
    return _description ? invoiceProposalHeader[_description] : null;
  }, [isResetWorkflowHistoryEntry, description]);

  const resetLabel = isResetWorkflowHistoryEntry
    ? description?.toLowerCase() === 'invoice_reset'
      ? `Invoice ID: ${body?.id}`
      : `Proposal ID: ${body?.id}`
    : null;

  const isAttachmentType = !!(body?.documentType ?? beforeBody?.documentType);

  const attachmentFields = useMemo(() => {
    if (!isAttachmentType) return {};
    return {
      docType: body?.documentType,
      expirationDate: moment(date).format('L'),
      description: body?.description,
    };
  }, [body?.description, body?.documentType, date, isAttachmentType]);

  const headerCardLabel = useMemo(() => {
    const action =
      method === Method.PUT
        ? 'Updated'
        : isAttachmentType
          ? 'Uploaded'
          : showAddedLabelForNewAction.includes(module)
            ? 'Added'
            : 'Created';

    switch (module) {
      case 'asset':
      case 'assetgeneral':
        const assetId = id ? id : body?.id ?? beforeBody?.id;
        return {
          label1: `Asset ID: ${assetId} `,
          label2: `Asset ${isAttachmentType ? 'Attachment ' : ''}${action}`,
        };

      case 'supplier':
        const supplierId = id ? id : body?.supplierId ?? beforeBody?.SupplierId;
        return {
          label1: `Supplier ID: ${supplierId} `,
          label2: `Supplier ${action}`,
        };
      case 'purchaseorder':
        const purchaseOrderId = id ? id : body?.id ?? beforeBody?.id;
        return {
          label1: `Purchase Order ID: ${purchaseOrderId} `,
          label2: `Purchase Order ${action}`,
        };
      default:
        return { label1: '', label2: '' };
    }
  }, [id, body?.id, beforeBody?.id, method, isAttachmentType, module]);

  const isGeneralAsset = useMemo(() => {
    return module === 'assetgeneral';
  }, [module]);

  const generalAssetsFields = useMemo(() => {
    if (!isGeneralAsset) return {};
    const fields = {};
    if (!body?.conceptName && beforeBody?.conceptName?.length > 0) {
      fields['conceptName'] = [];
    }
    if (!body?.flagUpdates && beforeBody?.flagUpdates?.length > 0) {
      fields['flagUpdates'] = [];
    }
    if (!body?.areaName && beforeBody?.areaName) {
      fields['areaName'] = null;
    }
    if (!body?.tag && beforeBody?.tag) {
      fields['tag'] = null;
    }
    return {
      ...body,
      ...fields,
    };
  }, [isGeneralAsset, body]);

  const parseHistoryValue = useCallback(
    (key: string, value: any) => {
      if (!fieldsToConvert.includes(`${module}.${key}`)) {
        return value;
      }
      if (typeof value === 'string' && JSON.parse(value))
        value = JSON.parse(value);
      if (value instanceof Object) {
        return value.name ? value.name : value.id ?? value;
      }

      return value;
    },
    [module],
  );

  const renderChanges = useCallback(() => {
    const assetGeneralArrayFields = ['conceptname', 'flagupdates'];
    let bodyToRender: any = isResetWorkflowHistoryEntry
      ? invoiceProposalResetEntryFields
      : isAttachmentType
        ? attachmentFields
        : isGeneralAsset
          ? generalAssetsFields
          : body;
    if (module === 'rfp') {
      bodyToRender = processRfpBody(bodyToRender, method);
    }
    if (module === 'purchaseorder') {
      const { updatedAt, ...newBodyToRender } = bodyToRender;
      bodyToRender = newBodyToRender;
    }

    return (
      <>
        {body && (
          <>
            <ECCollapse
              in={isExpanded}
              collapsedSize={
                shouldShowSeeMoreButton ? CARD_MAX_HEIGHT_COLLAPSED : cardHeight
              }
              key={key}
              sx={{ overflow: 'visible' }}
            >
              <ECBox ref={cardRef} overflow="visible">
                {isUserApprovedHistory && (
                  <ECTypography
                    variant="subtitle2"
                    width="fit-content"
                    color={theme => theme.palette.text.primary}
                    fontSize={13}
                    sx={{
                      background: theme => theme.palette.other.ecYellow,
                      paddingX: 0.5,
                      paddingY: 0.1,
                      mb: 1,
                    }}
                  >
                    Approved
                  </ECTypography>
                )}
                {isResetWorkflowHistoryEntry && (
                  <>
                    <ECTypography
                      variant="subtitle2"
                      width="fit-content"
                      color={theme => theme.palette.text.secondary}
                      fontSize={13}
                      sx={{
                        fontWeight: 500,
                        mb: 1,
                      }}
                    >
                      {resetLabel}
                    </ECTypography>
                    <ECTypography
                      variant="subtitle2"
                      width="fit-content"
                      color={theme => theme.palette.text.secondary}
                      fontSize={13}
                      sx={{
                        background: theme => theme.palette.other.divider,
                        paddingX: 2,
                        paddingY: 0.2,
                        mb: 0.5,
                      }}
                    >
                      {invoiceProposalResetHeader?.header2}
                    </ECTypography>
                  </>
                )}
                {moduleToShowHeaderLabels?.includes(module) && (
                  <>
                    <ECTypography
                      variant="subtitle2"
                      width="fit-content"
                      color={theme => theme.palette.text.secondary}
                      fontSize={13}
                      sx={{
                        fontWeight: 500,
                        mb: 1,
                      }}
                    >
                      {headerCardLabel.label1}
                    </ECTypography>

                    <ECTypography
                      variant="subtitle2"
                      width="fit-content"
                      color={theme => theme.palette.text.secondary}
                      fontSize={13}
                      sx={{
                        background: theme => theme.palette.other.divider,
                        paddingX: 2,
                        paddingY: 0.5,
                        mb: 0.5,
                      }}
                    >
                      {headerCardLabel.label2}
                    </ECTypography>
                  </>
                )}
                {module === 'rfp' && (
                  <RfpHistoryAction
                    id={id}
                    body={body}
                    beforeBody={beforeBody}
                    method={method}
                    isAttachmentType={isAttachmentType}
                  />
                )}
                {entry.endpoint.includes('replace') &&
                entry.endpoint.includes('asset') &&
                entry.endpoint.includes('general-asset') &&
                !body?.newAttachment ? (
                  <>
                    <ECTypography
                      variant="subtitle2"
                      minWidth="15vw"
                      color={theme => theme.palette.text.secondary}
                      fontSize={13}
                    >
                      {entry?.body?.replacedBy && (
                        <>
                          Asset replaced by Asset ID #
                          {entry?.body?.replacedBy.match(/\d+/)[0]}
                        </>
                      )}
                      {entry?.body?.replacedAssetId && (
                        <>
                          Asset replaces Asset ID #
                          {entry?.body?.replacedAssetId}
                        </>
                      )}
                    </ECTypography>
                  </>
                ) : (
                  <>
                    {targetPermission.some(
                      perm =>
                        perm === P.GetAttachment ||
                        perm === P.AddAttachment ||
                        perm === P.EditAttachment ||
                        perm === P.ReplaceAsset,
                    ) && (
                      <ECTypography
                        variant="subtitle2"
                        minWidth="15vw"
                        color={theme => theme.palette.text.secondary}
                        fontSize={13}
                      >
                        Updated{' '}
                        <span
                          style={{
                            fontWeight: 700,
                            fontStyle: 'italic',
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontSize: '14px',
                          }}
                        >
                          {body?.newAttachment || 'new'}
                        </span>{' '}
                        file (View Attachment Tab)
                      </ECTypography>
                    )}
                    {Object.keys(bodyToRender).map(bodyKey => {
                      const formattedTitleText: string =
                        method === Method.PUT &&
                        bodyKey === 'statusUpdated' &&
                        (module === 'rfp' || module === 'purchaseorder')
                          ? 'Status'
                          : `${camelCaseToWords(bodyKey).replace('Rfp', module !== 'rfp' ? 'Rfp' : 'RFP')}`;

                      return (
                        <>
                          {bodyToRender?.[bodyKey] !==
                            previousBody?.[bodyKey] &&
                            checkIfValidArrayEntry(
                              previousBody,
                              bodyToRender,
                              bodyKey,
                            ) &&
                            (isValidHistoryEntry(bodyToRender, bodyKey) ||
                              (isGeneralAsset &&
                                assetGeneralArrayFields?.includes(
                                  bodyKey?.toLowerCase(),
                                ))) && (
                              <ECBox display="flex" width="100%">
                                <ECBox display="flex" flex={1} width={0}>
                                  <ECTypography
                                    variant="subtitle2"
                                    minWidth="15vw"
                                    maxWidth="20vw"
                                    color={theme =>
                                      theme.palette.text.secondary
                                    }
                                  >
                                    {formattedTitleText}:&nbsp;
                                  </ECTypography>

                                  <ECBox
                                    display="flex"
                                    flex={1}
                                    justifyContent="center"
                                  >
                                    {(previousBody?.[bodyKey] ||
                                      previousBody?.[bodyKey] === false) &&
                                      !Array.isArray(
                                        previousBody?.[bodyKey],
                                      ) && (
                                        <ECBox
                                          display="flex"
                                          flex={1}
                                          width={0}
                                          justifyContent="flex-end"
                                        >
                                          <ECTypography
                                            variant="subtitle2"
                                            maxWidth="100%"
                                            color={theme =>
                                              theme.palette.error.dark
                                            }
                                            sx={{
                                              textDecoration: 'line-through',
                                            }}
                                          >
                                            {entry.endpoint.includes(
                                              'general-asset',
                                            ) &&
                                            bodyKey?.toLowerCase() === 'state'
                                              ? removeLeadingTrailingQuotes(
                                                  JSON.stringify(
                                                    previousBody?.[bodyKey],
                                                  ),
                                                ) === 'true'
                                                ? 'Active'
                                                : 'Inactive'
                                              : bodyKey?.toLowerCase() !==
                                                  'conceptname' &&
                                                removeLeadingTrailingQuotes(
                                                  JSON.stringify(
                                                    parseHistoryValue(
                                                      bodyKey,
                                                      previousBody?.[bodyKey],
                                                    ),
                                                  ),
                                                )}
                                          </ECTypography>
                                        </ECBox>
                                      )}

                                    {Array.isArray(previousBody?.[bodyKey]) &&
                                      (bodyKey?.toLowerCase() ===
                                        'conceptname' ||
                                        bodyKey?.toLowerCase() ===
                                          'flagupdates') &&
                                      previousBody?.[bodyKey]?.length > 0 && (
                                        <ECBox
                                          display="flex"
                                          flex={1}
                                          width={0}
                                          justifyContent="flex-end"
                                        >
                                          <ECTypography
                                            variant="subtitle2"
                                            maxWidth="100%"
                                            color={theme =>
                                              theme.palette.error.dark
                                            }
                                            sx={{
                                              textDecoration: 'line-through',
                                            }}
                                          >
                                            {previousBody?.[bodyKey]?.map(
                                              item => {
                                                return (
                                                  <p
                                                    style={{
                                                      marginTop: 1,
                                                      marginBottom: 1,
                                                    }}
                                                  >
                                                    {item}
                                                  </p>
                                                );
                                              },
                                            )}
                                          </ECTypography>
                                        </ECBox>
                                      )}

                                    <ECBox
                                      display="flex"
                                      visibility={
                                        ((previousBody?.[bodyKey] ||
                                          previousBody?.[bodyKey] === false) &&
                                          !Array.isArray(
                                            previousBody?.[bodyKey],
                                          ) &&
                                          bodyToRender?.[bodyKey] !== null &&
                                          bodyToRender?.[bodyKey] !==
                                            undefined) ||
                                        ((bodyKey?.toLowerCase() ===
                                          'conceptname' ||
                                          bodyKey?.toLowerCase() ===
                                            'flagupdates') &&
                                          previousBody?.[bodyKey]?.length > 0 &&
                                          bodyToRender?.[bodyKey]?.length > 0)
                                          ? 'visible'
                                          : 'hidden'
                                      }
                                    >
                                      <ArrowForward
                                        sx={theme => ({
                                          color: theme.palette.action.active,
                                          mx: 1,
                                        })}
                                        fontSize="small"
                                      />
                                    </ECBox>

                                    <ECBox
                                      display="flex"
                                      flex={1}
                                      width={0}
                                      justifyContent={
                                        previousBody?.[bodyKey] ||
                                        (previousBody?.[bodyKey] === false &&
                                          !bodyKey
                                            ?.toLowerCase()
                                            .includes('removed'))
                                          ? Array.isArray(bodyToRender[bodyKey])
                                            ? 'flex-start'
                                            : 'flex-start'
                                          : 'center'
                                      }
                                      visibility={
                                        bodyToRender?.[bodyKey]
                                          ? 'visible'
                                          : 'hidden'
                                      }
                                    >
                                      <ECTypography
                                        variant="subtitle2"
                                        maxWidth="100%"
                                        color={theme =>
                                          bodyKey
                                            ?.toLowerCase()
                                            .includes('removed')
                                            ? theme.palette.error.dark
                                            : theme.palette.success.dark
                                        }
                                      >
                                        {entry.endpoint.includes(
                                          'general-asset',
                                        ) &&
                                          (bodyKey?.toLowerCase() ===
                                            'conceptname' ||
                                            bodyKey?.toLowerCase() ===
                                              'flagupdates') &&
                                          bodyToRender?.[bodyKey]?.map(item => {
                                            return (
                                              <p
                                                style={{
                                                  marginTop: 1,
                                                  marginBottom: 1,
                                                }}
                                              >
                                                {item}
                                              </p>
                                            );
                                          })}
                                        {entry.endpoint.includes(
                                          'general-asset',
                                        ) && bodyKey?.toLowerCase() === 'state'
                                          ? getHistoryEntry(
                                              bodyToRender,
                                              bodyKey,
                                            ) === 'true'
                                            ? 'Active'
                                            : 'Inactive'
                                          : bodyKey?.toLowerCase() !==
                                              'conceptname' &&
                                            bodyKey?.toLowerCase() !==
                                              'flagupdates' &&
                                            parseHistoryValue(
                                              bodyKey,
                                              getHistoryEntry(
                                                bodyToRender,
                                                bodyKey,
                                              ),
                                            )}
                                      </ECTypography>
                                    </ECBox>
                                  </ECBox>
                                </ECBox>
                              </ECBox>
                            )}
                        </>
                      );
                    })}
                  </>
                )}
              </ECBox>
            </ECCollapse>
            {shouldShowSeeMoreButton && (
              <>
                <ECBox
                  display="flex"
                  width="100%"
                  flexDirection="column"
                  mt={-9}
                  mb={-2}
                >
                  <ECBox
                    width="100%"
                    height={96}
                    sx={{
                      background: isExpanded
                        ? 'transparent'
                        : 'linear-gradient(to bottom, #FFFFFF00, #FFFFFFFF)',
                    }}
                    justifyContent="center"
                    alignItems="flex-end"
                    display="flex"
                  />
                  <ECBox
                    display="flex"
                    bgcolor="white"
                    width="100%"
                    justifyContent="center"
                  >
                    <ECButton
                      variant="text"
                      onClick={() =>
                        setIsExpanded(prevIsExpanded => !prevIsExpanded)
                      }
                      endIcon={
                        <KeyboardArrowDown
                          sx={{
                            transform: isExpanded
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                            transition: '100ms linear all',
                          }}
                        />
                      }
                    >
                      {isExpanded ? 'See less' : 'See More'}
                    </ECButton>
                  </ECBox>
                </ECBox>
              </>
            )}
          </>
        )}
      </>
    );
  }, [
    body,
    previousBody,
    isExpanded,
    shouldShowSeeMoreButton,
    cardHeight,
    entry,
    key,
    targetPermission,
  ]);

  return (
    <ECBox display="flex" flexDirection="column" flexGrow={1} key={key}>
      {isResetWorkflowHistoryEntry && (
        <ECTypography
          variant="subtitle2"
          width="fit-content"
          color={theme => theme.palette.text.primary}
          fontSize={13}
          sx={{
            background: theme => theme.palette.other.divider,

            paddingX: 2,
            paddingY: 0.1,
            mb: 4,
          }}
        >
          {invoiceProposalResetHeader?.header1}
        </ECTypography>
      )}
      <ECBox sx={{ flexGrow: 1 }}>
        <ECGrid container spacing={2}>
          <ECGrid item xs>
            <ECBox display="flex" alignItems="center">
              <ECBox
                height={12}
                width={12}
                borderRadius={6}
                bgcolor={theme => theme.palette.primary.dark}
                mr={2}
              />
              <ECBox display="flex" flexDirection="column">
                <ECTypography variant="subtitle2" fontWeight="bold">
                  {author}
                </ECTypography>

                <ECTypography
                  variant="body2"
                  color={theme => theme.palette.primary.main}
                >
                  {email}
                </ECTypography>
              </ECBox>
            </ECBox>
          </ECGrid>

          <ECGrid item xs>
            <ECBox display="flex" flexDirection="column">
              <ECTypography variant="body2" fontWeight="bold">
                {jobTitle}
              </ECTypography>

              <ECTypography
                variant="subtitle2"
                color={theme => theme.palette.text.secondary}
              >
                {company}
              </ECTypography>
            </ECBox>
          </ECGrid>

          <ECGrid item xs>
            <ECBox display="flex" flexDirection="column">
              <ECTypography
                variant="body2"
                color={theme => theme.palette.text.secondary}
              >
                {formatDateForHistory(date)}
              </ECTypography>
            </ECBox>
          </ECGrid>
        </ECGrid>
      </ECBox>

      <ECBox display="flex" gap={4}>
        <ECBox
          bgcolor={theme => theme.palette.grey[400]}
          width="1px"
          display="flex"
          sx={{ marginLeft: '6px !important' }}
        />
        <ECBox
          display="flex"
          flexDirection="column"
          boxShadow={2}
          mt={5}
          borderRadius="md"
          minWidth={0}
          width="100%"
          p={2}
          position="relative"
          overflow="hidden"
        >
          {body?.rejectReason ? (
            <ECTypography
              variant="subtitle2"
              minWidth="15vw"
              color={theme => theme.palette.text.secondary}
              fontSize={13}
            >
              <span
                style={{
                  fontWeight: 700,
                  color: 'rgba(255, 0, 0, 0.87)',
                  fontSize: '14px',
                }}
              >
                Reject{' '}
              </span>
              with the reason{' '}
              <span
                style={{
                  fontWeight: 700,
                  fontStyle: 'italic',
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontSize: '14px',
                }}
              >
                {body?.rejectReason}
              </span>
              {body.note && (
                <ECTypography
                  variant="subtitle2"
                  minWidth="15vw"
                  maxWidth="20vw"
                  color={theme => theme.palette.text.secondary}
                >
                  A note was added
                </ECTypography>
              )}
            </ECTypography>
          ) : (
            <>
              {targetPermission.includes(P.AddComment) ? (
                <>
                  {moduleToShowHeaderLabels?.includes(module) && (
                    <>
                      <ECTypography
                        variant="subtitle2"
                        width="fit-content"
                        color={theme => theme.palette.text.secondary}
                        fontSize={13}
                        sx={{
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        {headerCardLabel.label1}
                      </ECTypography>

                      <ECTypography
                        variant="subtitle2"
                        width="fit-content"
                        color={theme => theme.palette.text.secondary}
                        fontSize={13}
                        sx={{
                          background: theme => theme.palette.other.divider,
                          paddingX: 2,
                          paddingY: 0.5,
                          mb: 0.5,
                        }}
                      >
                        Asset Comment Added
                      </ECTypography>
                    </>
                  )}
                  <ECBox display="flex" flexDirection="column">
                    <ECTypography
                      variant="subtitle2"
                      minWidth="15vw"
                      maxWidth="20vw"
                      color={theme => theme.palette.text.secondary}
                    >
                      A note was added
                    </ECTypography>

                    <ECTypography variant="subtitle2">
                      {parse(body?.body)}
                    </ECTypography>
                  </ECBox>
                </>
              ) : (
                <>
                  {method !== Method.PUT && (method as any) !== '' ? (
                    <>
                      {module !== 'rfp' && description && (
                        <ECBox display="flex" flexDirection="column">
                          <ECTypography
                            variant="subtitle2"
                            minWidth="15vw"
                            maxWidth="20vw"
                            color={theme => theme.palette.text.secondary}
                          >
                            {description}
                          </ECTypography>
                        </ECBox>
                      )}
                      {renderChanges()}
                    </>
                  ) : (
                    <>
                      {module !== 'rfp' &&
                        description &&
                        !isResetWorkflowHistoryEntry && (
                          <ECBox display="flex" flexDirection="column">
                            <ECTypography
                              variant="subtitle2"
                              minWidth="15vw"
                              maxWidth="20vw"
                              color={theme => theme.palette.text.secondary}
                            >
                              {description}
                            </ECTypography>
                          </ECBox>
                        )}

                      {renderChanges()}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {(targetPermission.includes(P.ChangeInvoiceStatus) ||
            targetPermission.includes(P.ChangeProposalStatus)) &&
          beforeBody?.statusUpdated === body?.statusUpdated ? (
            <ECBox display="flex" flexDirection="column">
              <ECTypography
                variant="subtitle1"
                minWidth="15vw"
                maxWidth="20vw"
                color={theme => theme.palette.text.secondary}
              >
                Resubmit was executed.
              </ECTypography>
              <ECTypography variant="subtitle2">{body?.body}</ECTypography>
            </ECBox>
          ) : null}
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
