import React, { useMemo } from 'react';
import { ECTypography } from '../ECTypography';
import { Method } from '../../../types/BaseType';
import _ from 'lodash';

interface RfpHistoryProps {
  id: number;
  body: any;
  beforeBody: any;
  method: Method;
  isAttachmentType: boolean;
}

export const RfpHistoryAction: React.FC<RfpHistoryProps> = props => {
  const { id, body, beforeBody, method, isAttachmentType } = props;

  const headerCardLabelOfRFP = useMemo(() => {
    const rfpId = id ? id : body?.id ?? beforeBody?.id;
    const action =
      method === Method.PUT
        ? body?.statusUpdated
          ? 'Status Updated'
          : 'Updated'
        : isAttachmentType
          ? 'Uploaded'
          : 'Created';

    return {
      label1: `RFP ID: ${rfpId}`,
      label2: `RFP ${action}`,
    };
  }, [id, body?.id, beforeBody?.id, method, isAttachmentType]);

  return (
    <>
      <ECTypography
        variant="subtitle2"
        width="fit-content"
        color={theme => theme.palette.text.primary}
        fontSize={13}
        sx={{ fontWeight: 'bold', mb: 1 }}
      >
        {headerCardLabelOfRFP.label1}
      </ECTypography>

      <ECTypography
        variant="subtitle2"
        width="fit-content"
        color={theme => theme.palette.text.secondary}
        fontSize={13}
        sx={{
          background: theme => theme.palette.other.divider,
          fontWeight: 'bold',
          paddingX: 2,
          paddingY: 0.5,
          mb: 0.5,
        }}
      >
        {headerCardLabelOfRFP.label2}
      </ECTypography>
    </>
  );
};

export const processRfpBody = (bodyToRender, method) => {
  if (method === Method.POST) {
    const customOrder = [
      'rfpTitle',
      'rfpStatus',
      'workOrderId',
      'location',
      'storeNumber',
      'asset',
      'category',
      'area',
      'assetGroup',
      'problem',
      'customerAddress',
      'rfpDescription',
      'serviceProviders',
      'submittedDate',
    ];

    // Clone bodyToRender and apply modifications
    const updatedBody = {
      ...bodyToRender,
      rfpTitle: bodyToRender?.name,
    };
    delete updatedBody?.description;

    // Rename and order fields
    const renamedAndOrderedObject = customOrder.reduce((acc, key) => {
      if (updatedBody.hasOwnProperty(key)) {
        acc[key] = updatedBody[key];
      }
      return acc;
    }, {});

    // Deep clone the final object if necessary
    return _.cloneDeep(renamedAndOrderedObject);
  }

  if (method === Method.PUT && bodyToRender?.description) {
    const updatedBody = {
      ...bodyToRender,
      rfpDescription: bodyToRender?.description,
    };
    delete updatedBody?.description;
    return _.cloneDeep(updatedBody);
  }

  return bodyToRender;
};
