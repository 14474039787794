import { Close } from '@mui/icons-material';
import { ECBox, ECGrid, ECTypography, ECIconButton } from '..';
import { EmptyStatePage } from 'app/pages/EmptyStatePage';
import { formatDateForTable } from 'utils/strings/formatDate';
import { themes } from 'styles/theme/themes';
import { StyleConstants } from 'styles/StyleConstants';
import { useMemo } from 'react';

export interface ApprovalHistoryData {
  assignee: { fullName: string };
  date?: string;
  assigneePosition?: string;
  jobTitle: { name: string };
  workflowStatus?: { name: string };
  id?: number;
  action: { name: string; timestamp: string, workflowIteration?: number };
  firstName?: string;
  lastName?: string;
  createdAt?: string;
  description?: string;
  workflowIteration?: number;
}

export interface ApprovalProcessData {
  createdBy: number;
  createdAt: string;
  updatedBy: number;
  updatedAt: string;
  id: number;
  title: string;
  description: string;
  amount: number;
  workflowStatusGroupNameId: number;
  companyId: number;
  status: number;
}

export enum ApprovalHistoryActions {
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  'NOT FIXED' = 'Not Fixed',
}

export interface ECApprovalHistoryProps {
  handleCloseModal?: () => void;
  data: {
    currentAssignees?: ApprovalHistoryData[];
    futureAssignees?: ApprovalHistoryData[];
    pastAssignees?: ApprovalHistoryData[];
    approvalProcess?: ApprovalProcessData;
  };
}

function approvalResetBanner(
  currentAssignee: ApprovalHistoryData,
  index: number,
  allAssignees?: ApprovalHistoryData[],
) {
  if (!allAssignees || index === 0) return null;

  const previousAssignee = allAssignees[index - 1];

  const currentIteration = currentAssignee?.workflowIteration ?? currentAssignee?.action?.workflowIteration;
  const previousIteration = previousAssignee?.workflowIteration ?? previousAssignee?.action?.workflowIteration;

  return (
    currentIteration != previousIteration
    ? (
      <ECBox
        display="flex"
        flexDirection="column"
        flexGrow={1}
        p={2}
        bgcolor={theme => theme.palette.grey[300]}
        borderRadius="md"
        mb={2}
      >
        <ECTypography variant="body2" fontWeight="bold">
          Approval Restarted
        </ECTypography>
      </ECBox>
    ) : null
  );
}

export const ECApprovalHistory = (props: ECApprovalHistoryProps) => {
  const { handleCloseModal, data } = props;

  const pastAssigneesMemoized = useMemo(() => {
    return (
      data?.pastAssignees?.map((item, index) => {
        return (
          <ECBox
            display="flex"
            flexDirection="column"
            flexGrow={1}
            key={`${item.id}-${item.date}-${item.assignee?.fullName}`}
            p={2}
          >
            {approvalResetBanner(item, index, data?.pastAssignees)}
            <ECBox sx={{ flexGrow: 1 }}>
              <ECGrid container spacing={2} alignItems="center">
                <ECGrid item xs>
                  <ECBox display="flex" alignItems="center">
                    <ECBox
                      minHeight={12}
                      minWidth={12}
                      borderRadius={6}
                      bgcolor={theme => theme.palette.primary.dark}
                      mr={2}
                    />
                    <ECBox display="flex" flexDirection="column">
                      <ECTypography variant="subtitle2" fontWeight="bold">
                        {item.assignee?.fullName || ''}
                      </ECTypography>
                    </ECBox>
                  </ECBox>
                </ECGrid>

                <ECGrid item xs>
                  <ECBox display="flex" flexDirection="column">
                    <ECTypography variant="body2" fontWeight="bold">
                      {item.jobTitle?.name || ''}
                    </ECTypography>
                  </ECBox>
                </ECGrid>

                <ECGrid item xs p={0}>
                  <ECBox display="flex" flexDirection="column">
                    <ECTypography
                      variant="body2"
                      color={theme => theme.palette.error.dark}
                    >
                      Past Assignee
                    </ECTypography>
                    <ECTypography
                      variant="body2"
                      color={theme => theme.palette.text.secondary}
                    >
                      {formatDateForTable(item.action?.timestamp || '')}
                    </ECTypography>
                  </ECBox>
                </ECGrid>
              </ECGrid>
            </ECBox>

            <ECBox display="flex" gap={2}>
              <ECBox
                bgcolor={theme => theme.palette.grey[400]}
                width="1px"
                display="flex"
                sx={{ marginLeft: '6px !important' }}
              />
              <ECBox
                display="flex"
                flexDirection="column"
                boxShadow={2}
                mt={3}
                borderRadius="md"
                minWidth={0}
                width="100%"
                p={2}
                position="relative"
                overflow="hidden"
              >
                <ECBox display="flex" flexDirection="column">
                  <ECTypography
                    variant="subtitle2"
                    minWidth="15vw"
                    maxWidth="20vw"
                    color={theme => theme.palette.text.secondary}
                  >
                    {item.action?.name
                      ? ApprovalHistoryActions[item.action.name]
                      : 'No Action Taken'}
                  </ECTypography>
                  {item.description && (
                    <ECTypography
                      variant="body2"
                      mt={1}
                      color={theme => theme.palette.text.secondary}
                    >
                      {item.description}
                    </ECTypography>
                  )}
                </ECBox>
              </ECBox>
            </ECBox>
          </ECBox>
        );
      })
    )
  }, [data?.pastAssignees]);

  const currentAssigneesMemoized = useMemo(() => {
    return (
      data?.currentAssignees?.map(item => {
        return (
          <ECBox
            display="flex"
            flexDirection="column"
            flexGrow={1}
            key={`${item.id}-${item.date}-${item.firstName}-${item.lastName}`}
            p={2}
          >
            <ECBox sx={{ flexGrow: 1 }}>
              <ECGrid container spacing={2}>
                <ECGrid item xs>
                  <ECBox display="flex" alignItems="center">
                    <ECBox
                      minHeight={12}
                      minWidth={12}
                      borderRadius={6}
                      bgcolor={theme => theme.palette.primary.dark}
                      mr={2}
                    />
                    <ECBox display="flex" flexDirection="column">
                      <ECTypography variant="subtitle2" fontWeight="bold">
                        {`${item.firstName} ${item.lastName || ''}`}
                      </ECTypography>
                    </ECBox>
                  </ECBox>
                </ECGrid>

                <ECGrid item xs>
                  <ECBox display="flex" flexDirection="column">
                    <ECTypography variant="body2" fontWeight="bold">
                      {item.jobTitle?.name || ''}
                    </ECTypography>
                  </ECBox>
                </ECGrid>

                <ECGrid item xs>
                  <ECBox display="flex" flexDirection="column">
                    <ECTypography
                      variant="body2"
                      sx={theme => ({ color: theme.palette.success.dark })}
                    >
                      Current Assignee
                    </ECTypography>
                  </ECBox>
                </ECGrid>
              </ECGrid>
            </ECBox>
            <ECBox display="flex" gap={2}>
              <ECBox
                bgcolor={theme => theme.palette.grey[400]}
                width="1px"
                display="flex"
                sx={{ marginLeft: '6px !important' }}
              />
              <ECBox
                display="flex"
                flexDirection="column"
                boxShadow={2}
                mt={3}
                borderRadius="md"
                minWidth={0}
                width="100%"
                p={2}
                position="relative"
                overflow="hidden"
              >
                <ECBox display="flex" flexDirection="column">
                  <ECTypography
                    variant="subtitle2"
                    minWidth="15vw"
                    maxWidth="20vw"
                    color={theme => theme.palette.text.secondary}
                  >
                    {item.action?.name
                      ? ApprovalHistoryActions[item.action.name]
                      : ''}
                  </ECTypography>
                  {item.description && (
                    <ECTypography
                      variant="body2"
                      mt={1}
                      color={theme => theme.palette.text.secondary}
                    >
                      {item.description}
                    </ECTypography>
                  )}
                </ECBox>
              </ECBox>
            </ECBox>
          </ECBox>
        );
      })
    )
  }, [data?.currentAssignees]);

  const futureAssigneesMemoized = useMemo(() => {
    return (
      data?.futureAssignees?.map(item => {
        return (
          <ECBox
            display="flex"
            flexDirection="column"
            flexGrow={1}
            key={`${item.id}-${item.date}-${item.firstName}-${item.lastName}`}
            p={2}
          >
            <ECBox sx={{ flexGrow: 1 }}>
              <ECGrid container spacing={3}>
                <ECGrid item xs>
                  <ECBox display="flex" alignItems="center">
                    <ECBox
                      minHeight={12}
                      minWidth={12}
                      borderRadius={6}
                      bgcolor={theme => theme.palette.primary.dark}
                      mr={2}
                    />
                    <ECBox display="flex" flexDirection="column">
                      <ECTypography variant="subtitle2" fontWeight="bold">
                        {`${item.firstName} ${item.lastName || ''}`}
                      </ECTypography>
                    </ECBox>
                  </ECBox>
                </ECGrid>

                <ECGrid item xs>
                  <ECBox display="flex" flexDirection="column">
                    <ECTypography variant="body2" fontWeight="bold">
                      {item.jobTitle?.name || ''}
                    </ECTypography>
                  </ECBox>
                </ECGrid>

                <ECGrid item xs>
                  <ECBox display="flex" flexDirection="column">
                    <ECTypography
                      variant="body2"
                      color={theme => theme.palette.text.disabled}
                    >
                      Due Assignee
                    </ECTypography>
                  </ECBox>
                </ECGrid>
              </ECGrid>
            </ECBox>
          </ECBox>
        );
      })
    )
  }, [data?.futureAssignees]);

  return (
    <>
      <ECBox
        sx={{
          width: '100%',
          height: StyleConstants.DRAWER_TITLE_HEIGHT,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 1rem',
          backgroundColor: themes.light.palette.other.divider,
        }}
      >
        <ECTypography variant="h4">Approval History</ECTypography>
        <ECIconButton
          size="medium"
          withBorder
          onClick={handleCloseModal}
          sx={{ backgroundColor: 'white' }}
        >
          <Close sx={theme => ({ color: theme.palette.grey[600] })} />
        </ECIconButton>
      </ECBox>
      {!data?.pastAssignees?.length &&
      !data?.currentAssignees?.length &&
      !data?.futureAssignees?.length ? (
        <EmptyStatePage />
      ) : (
        <>
          {data?.approvalProcess?.title && (
            <ECTypography
              sx={theme => ({
                color: theme.palette.grey[700],
                padding: '1rem',
              })}
              variant="subtitle1"
            >
              Approval Workflow: {data?.approvalProcess?.title}
            </ECTypography>
          )}
          {pastAssigneesMemoized}
          {data?.currentAssignees?.[0] &&
            data?.pastAssignees &&
            data?.pastAssignees?.length &&
              approvalResetBanner(
                data?.currentAssignees?.[0], 
                data?.pastAssignees?.length, 
                data?.pastAssignees
              )
          }
          {currentAssigneesMemoized}
          {futureAssigneesMemoized}
        </>
      )}
    </>
  );
};
