import { BaseType } from 'types/BaseType';
import { emptyApi } from './emptyApi';
import {
  AccountingGeneralLedgerCodes,
  AccountingQueryParams,
} from 'types/Accounting';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Accounting', 'AccountingGeneralLedgerCodes'],
});

// Define a service using a base URL and expected endpoints
export const accountingApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAccountingGLCodes: builder.query<
      BaseType<AccountingGeneralLedgerCodes>,
      AccountingQueryParams
    >({
      query: params => {
        return {
          url: `accounting/general-ledger-codes`,
          params: params,
        };
      },
    }),
  }),
});

export const {
  useGetAccountingGLCodesQuery,
  useLazyGetAccountingGLCodesQuery,
} = accountingApi;
