import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  TreeItem,
  TreeItemProps,
  treeItemClasses,
} from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { ECBox } from '../ECBox';
import { CheckBoxOutlineBlank, Edit } from '@mui/icons-material';
import { ECTypography } from '../ECTypography';
import { ECButton, ECIconButton, ECStack } from '..';
import { CheckBox, ChevronRight, ExpandMore } from '@mui/icons-material';
import {
  findNodeById,
  findPartialParentNodesIds,
  getAllNodes,
  getAllNodesIds,
  getChildrenNodes,
  selectParentNodes,
  selectParentNodesIds,
} from 'utils/tree';
import _ from 'lodash';
import { ECCheckbox } from '../ECCheckbox';

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
}));

export interface TreeNode {
  label: string;
  data?: Record<string, any>;
  nodeId: number;
  parentNodeId?: number;
  children?: TreeNode[];
  organizationType?: number;
}

interface ECTreeProps {
  tree: TreeNode[];
  defaultExpanded?: string[];
  initialSelectedNodesIds?: number[];
  selectedNodes?: any[];
  readOnly?: boolean;
  keepSelectedNodes?: boolean;
  showSelectAllOption?: boolean;
  showOnlyUnselectAllOption?: boolean;
  unselectAllOptionLabel?: string;
  placeholder?: string;
  onNodeClick?: (node: TreeNode) => void;
  onEditNodeClick?: (node: TreeNode) => void;
  onSelectedNodesIdsChange?: (id: number[]) => void;
  onSelectedNodesChange?: (nodes: TreeNode[]) => void;
  setAllSelected?: (allSelectedNodes: boolean) => void;
  maxSelection?: number;
  customHeight?: string;
}

export const ECTree = ({
  tree: initialTree,
  initialSelectedNodesIds = [],
  readOnly,
  selectedNodes: selectedNodesFromProps,
  keepSelectedNodes,
  showSelectAllOption,
  showOnlyUnselectAllOption,
  unselectAllOptionLabel,
  placeholder,
  defaultExpanded,
  onNodeClick,
  onEditNodeClick,
  onSelectedNodesChange,
  onSelectedNodesIdsChange,
  setAllSelected,
  maxSelection,
  customHeight,
}: ECTreeProps) => {
  const [tree, setTree] = useState(initialTree);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
  const [partialSelectedNodesIds, setPartialSelectedNodesIds] = useState<
    number[]
  >([]);

  const selectedNodes = useMemo(() => {
    return selectedNodesFromProps?.map(node => (node as any).nodeId || node);
  }, [selectedNodesFromProps]);

  useEffect(() => {
    let newSelectedNodes =
      keepSelectedNodes && !readOnly
        ? selectedNodes ?? []
        : initialSelectedNodesIds;

    if (initialTree.length > 0) {
      newSelectedNodes = _.uniq(
        initialTree.flatMap(node =>
          selectParentNodesIds(
            node,
            keepSelectedNodes && !readOnly
              ? selectedNodes ?? []
              : initialSelectedNodesIds,
          ),
        ),
      );
    }

    // this was causing INDETERMINATE to not be correct populated
    if (!tree) {
      const partialNodes = _.uniq(
        initialTree.flatMap(t =>
          findPartialParentNodesIds(t, initialSelectedNodesIds),
        ),
      );
      setPartialSelectedNodesIds(partialNodes);
    }
    setTree(initialTree);
    if (!_.isEqual(newSelectedNodes, selectedNodes)) {
      onSelectedNodesIdsChange?.(newSelectedNodes);
    }
  }, [initialTree, readOnly, initialSelectedNodesIds]);

  useEffect(() => {
    if (defaultExpanded && !expanded?.length) {
      setExpanded(defaultExpanded);
    }
  }, [defaultExpanded]);

  const allNodes = useMemo(() => getAllNodes(tree), [tree]);
  const allNodesIds = useMemo(() => getAllNodesIds(tree, 'nodeId'), [tree]);

  useEffect(() => {
    if (selectedNodes) {
      if (allNodesIds.every(node => selectedNodes.includes(node))) {
        setIsSelectAllSelected(true);
        setAllSelected?.(true);
      } else {
        setIsSelectAllSelected(false);
        setAllSelected?.(false);
      }
    }
  }, [selectedNodes, allNodesIds]);

  const handleNodeClick = (node: TreeNode) => () => {
    onNodeClick?.(node);
  };

  const handleEditNodeClick = (node: TreeNode) => e => {
    e.stopPropagation();
    onEditNodeClick?.(node);
  };

  const getParentNode = (node?: TreeNode) => {
    if (!node) return null;

    const parentNodes: any[] = [];

    let parentNode = findNodeById(tree, node.parentNodeId);
    parentNodes.push(parentNode);
    while (parentNode?.parentNodeId) {
      parentNode = findNodeById(tree, parentNode?.parentNodeId);
      parentNodes.push(parentNode);
    }

    return parentNodes;
  };

  const handleSelectNode = useCallback(
    (node: TreeNode) => (e?: any) => {
      e?.stopPropagation();
      if (selectedNodes?.includes(node.nodeId)) {
        const allParentsNodesIds =
          getParentNode(node)?.map(parentNode => parentNode?.nodeId) ?? [];
        const unselectedNodes = [
          ...getChildrenNodes(node).map(childNode => childNode.nodeId),
          ...allParentsNodesIds,
          node.nodeId,
        ];
        const updatedNodes = selectedNodes?.filter(
          n => !unselectedNodes.includes(n),
        );
        const newSelectedNodesIds = _.uniq(
          tree.flatMap(t => selectParentNodesIds(t, updatedNodes)),
        );
        const newSelectedNodes = _.uniq(
          tree.flatMap(t => selectParentNodes(t, updatedNodes)),
        );
        const partialNodes = _.uniq(
          tree.flatMap(t => findPartialParentNodesIds(t, newSelectedNodesIds)),
        );
        setPartialSelectedNodesIds(partialNodes);
        onSelectedNodesIdsChange?.(newSelectedNodesIds);
        onSelectedNodesChange?.(newSelectedNodes);
      } else {
        const childrenNodes = getChildrenNodes(node);
        const updatedNodes = [
          node.nodeId,
          ...childrenNodes?.map(childNode => childNode.nodeId),
          ...(selectedNodes ?? []),
        ];
        const newSelectedNodesIds = _.uniq(
          tree.flatMap(t => selectParentNodesIds(t, updatedNodes)),
        );
        const newSelectedNodes = _.uniq(
          tree.flatMap(t => selectParentNodes(t, updatedNodes)),
        );
        const partialNodes = _.uniq(
          tree.flatMap(t => findPartialParentNodesIds(t, newSelectedNodesIds)),
        );
        setPartialSelectedNodesIds(partialNodes);
        onSelectedNodesIdsChange?.(newSelectedNodesIds);
        onSelectedNodesChange?.(newSelectedNodes);
      }
    },
    [selectedNodes, tree],
  );

  const partialNodes = useMemo(() => {
    return _.uniq(
      tree.flatMap(t => findPartialParentNodesIds(t, selectedNodes ?? [])),
    );
  }, [tree, selectedNodes]);

  const renderTree = useCallback(
    (node: TreeNode) => {
      if (!node?.nodeId) {
        return;
      }
      const isSelected = selectedNodes?.includes(node.nodeId);
      const isPartialSelected =
        !isSelected &&
        (partialSelectedNodesIds?.includes(node.nodeId) ||
          partialNodes.includes(node.nodeId));

      return (
        <StyledTreeItem
          label={
            <ECBox height="48px" display="flex" alignItems="center">
              <ECCheckbox
                disabled={
                  readOnly ||
                  (!isSelected && maxSelection && selectedNodes
                    ? maxSelection <= selectedNodes?.length
                    : false)
                }
                checked={isSelected}
                indeterminate={isPartialSelected}
                onChange={handleSelectNode(node)}
                onClick={e => e.stopPropagation()}
              />

              <ECTypography variant="subtitle1">{node.label}</ECTypography>

              {onEditNodeClick && (
                <ECIconButton size="small" onClick={handleEditNodeClick(node)}>
                  <Edit />
                </ECIconButton>
              )}
            </ECBox>
          }
          nodeId={`${node.nodeId}`}
          onClick={handleNodeClick(node)}
          key={`tree-node-${node.label}-${node.nodeId}`}
        >
          {node.children?.map(renderTree)}
        </StyledTreeItem>
      );
    },
    [
      selectedNodes,
      partialSelectedNodesIds,
      readOnly,
      onEditNodeClick,
      maxSelection,
      partialNodes,
    ],
  );

  const selectAllNodes = useCallback(() => {
    setIsSelectAllSelected(true);
    setPartialSelectedNodesIds([]);
    onSelectedNodesIdsChange?.([...(selectedNodes ?? []), ...allNodesIds]);
    onSelectedNodesChange?.([...allNodes]);
  }, [allNodesIds, allNodes, selectedNodes]);

  const unselectAllNodes = useCallback(() => {
    setIsSelectAllSelected(false);
    onSelectedNodesIdsChange?.(
      selectedNodes?.filter(node => !allNodesIds.includes(node)) ?? [],
    );
    onSelectedNodesChange?.([]);
    setPartialSelectedNodesIds([]);
  }, [allNodesIds, allNodes, selectedNodes]);

  const toggleSelectAll = useCallback(() => {
    if (isSelectAllSelected) {
      unselectAllNodes();
    } else {
      selectAllNodes();
    }
  }, [isSelectAllSelected, selectAllNodes, unselectAllNodes]);

  return (
    <ECStack direction="column" width="100%" spacing={1} height={customHeight}>
      {!!showSelectAllOption && tree.length > 0 && (
        <ECBox display="flex" gap={1} ml="28px" px={1}>
          <ECIconButton
            noPadding
            disabled={
              readOnly || (maxSelection ? maxSelection <= tree.length : false)
            }
            onClick={toggleSelectAll}
            size="small"
          >
            {isSelectAllSelected ? (
              <CheckBox
                color={readOnly ? 'disabled' : 'primary'}
                fontSize="small"
              />
            ) : (
              <CheckBoxOutlineBlank
                color={readOnly ? 'disabled' : 'primary'}
                fontSize="small"
              />
            )}
          </ECIconButton>
          <ECTypography variant="body2">Select All</ECTypography>
        </ECBox>
      )}
      {!!showOnlyUnselectAllOption && tree.length > 0 && (
        <ECBox
          display="flex"
          justifyContent={'space-between'}
          alignItems="center"
        >
          <ECButton variant="text" onClick={unselectAllNodes}>
            {unselectAllOptionLabel || 'Unselect All'}
          </ECButton>
          {maxSelection && (
            <ECTypography variant="caption">
              {selectedNodes?.length} / {maxSelection}
            </ECTypography>
          )}
        </ECBox>
      )}
      {tree.length > 0 ? (
        <TreeView
          aria-label="customized"
          expanded={expanded}
          onNodeToggle={(event, nodes) => setExpanded(nodes)}
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
          {tree.map(renderTree)}
        </TreeView>
      ) : (
        <ECTypography textAlign="center">{placeholder}</ECTypography>
      )}
    </ECStack>
  );
};
