import { ListItemText, Stack } from '@mui/material';
import { ECTypography } from '../ECTypography';
import { ECMenuItem, ECSelect } from '../ECSelect';
import { useGetEtasQuery } from 'services/lookupApi';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'index';

export const ECEtaFilter = () => {
  const dispatch = useDispatch();

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const { data: etas } = useGetEtasQuery();

  const etasWithAllOption = useMemo(() => {
    if (!etas) {
      return [];
    }

    return [
      {
        id: 'allTime',
        name: 'All Time',
        days: undefined,
      },
      ...etas,
    ];
  }, [etas]);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      width="220px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-around"
    >
      {!!etasWithAllOption?.length && (
        <>
          <ECTypography
            color={theme => theme.palette.text.secondary}
            variant="caption"
            minWidth="75px"
          >
            Current ETA:
          </ECTypography>
          <ECSelect
            labelId="mutiple-select-label"
            variant="standard"
            disableUnderline
            value={activeFilter.eta}
            onChange={e => {
              const value = e.target.value as any;

              dispatch(
                setActiveFilter({
                  eta: value,
                }),
              );
            }}
            displayEmpty
            renderValue={(selected: any) => {
              if (!selected) {
                return 'All Time';
              }

              const label = etasWithAllOption?.find(
                option => option?.days === selected,
              )?.name;

              return label;
            }}
          >
            {etasWithAllOption.map(option => (
              <ECMenuItem key={option?.id} value={option?.days}>
                <ListItemText primary={option?.name} />
              </ECMenuItem>
            ))}
          </ECSelect>
        </>
      )}
    </Stack>
  );
};
