import { IconButton, InputAdornment, Stack } from '@mui/material';
import { ECTypography } from '../ECTypography';
import { ECTextField } from '../ECTextField';
import { ArrowDropDown } from '@mui/icons-material';
import { ECPopover } from '../ECPopover';
import { ECBox } from '../ECBox';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetOrganizationListQuery } from 'services/organizationApi';
import { OrganizationIdTypeEnum } from 'types/Organization';
import { ECTree, TreeNode } from '../ECTree';
import { useDispatch } from 'react-redux';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { setActiveFilter } from 'store/slice/page';
import { ECChip } from '../ECChip';
import { ECIconButton } from '../ECIconButton';
import { getAllNodes } from 'utils/tree';

// only props needed so far is onChange
type ECHierarchyTableFilterProps = {
  onChange?: (nodeTree: TreeNode[]) => void;
  shouldReturnEmptyWhenSelectingAll?: boolean;
};

const LABEL_MAX_LENGTH = 24;

export const ECHierarchyTableFilter = ({
  onChange,
  shouldReturnEmptyWhenSelectingAll,
}: ECHierarchyTableFilterProps) => {
  const dispatch = useDispatch();

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  // orgs data will bring the REGIONS with it's DISTRICTS
  const { data: orgs } = useGetOrganizationListQuery();

  const [searchTerm, setSearchTerm] = useState<string | undefined>('');

  const hierarchyRef = useRef(null);

  const [anchorElHierarchy, setAnchorElHierarchy] =
    useState<HTMLDivElement | null>(null);

  const hierarchyTreeOptions = useMemo(() => {
    if (!orgs) {
      return [];
    }

    /*
        filter logic:
        if the parent matches, show all children
        If one children matches, show the parent and the matching children
    */
    const orgsFilteredBySearchTerm = !searchTerm
      ? orgs[0].children
      : orgs[0].children?.filter(
          org =>
            org.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            org.children?.some(child =>
              child.name?.toLowerCase().includes(searchTerm?.toLowerCase()),
            ),
        );

    return orgsFilteredBySearchTerm?.map(org => {
      const orgLabel =
        org.organizationTypeId === OrganizationIdTypeEnum.Region
          ? ' (Region)'
          : ' (District)';

      // if there is searchTerm and the parent name doesn't match searchTerm, filter children that matches searchTerm
      const shouldFilterChildren =
        searchTerm &&
        !org.name?.toLowerCase().includes(searchTerm?.toLowerCase() ?? '');
      const childrenFilteredBySearchTerm = shouldFilterChildren
        ? org.children?.filter(child =>
            child.name?.toLowerCase().includes(searchTerm?.toLowerCase() || ''),
          )
        : org.children;

      return {
        nodeId: org.id,
        label: org.name + orgLabel,
        organizationType: org.organizationTypeId,
        children: childrenFilteredBySearchTerm?.map(child => {
          const childrenLabel =
            child.organizationTypeId === OrganizationIdTypeEnum.Region
              ? ' (Region)'
              : ' (District)';
          return {
            nodeId: child.id,
            label: child.name + childrenLabel,
            organizationType: child.organizationTypeId,
            parentNodeId: org.id,
          };
        }),
      };
    });
  }, [orgs, searchTerm, anchorElHierarchy, activeFilter]);

  const handleChangeSearchTerm = useCallback(e => {
    setSearchTerm(e.target.value);
  }, []);

  const handleClickHierarchy = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      setAnchorElHierarchy(hierarchyRef?.current);
    },
    [],
  );

  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    // if ALL is selected and shouldReturnEmptyWhenSelectingAll is true, return empty array
    if (shouldReturnEmptyWhenSelectingAll && isAllSelected) {
      onChange?.([]);
    }
  }, [isAllSelected, shouldReturnEmptyWhenSelectingAll]);

  const handleCloseHierarchy = useCallback(() => {
    setAnchorElHierarchy(null);
    setSearchTerm('');
  }, []);

  const inputChip = useMemo(() => {
    // if none or all, text should be ALL
    if (!activeFilter?.hierarchy?.length || isAllSelected) {
      return (
        <ECChip
          sx={{ width: '48px', height: '24px' }}
          label="All"
          color="Light Grey"
        />
      );
    }

    return (
      <ECChip
        sx={{ width: '48px', height: '24px' }}
        label={activeFilter?.hierarchy?.length}
        color="Dark Blue"
      />
    );
  }, [isAllSelected, activeFilter?.hierarchy]);

  const handleSelectNode = useCallback(
    (nodeTree: TreeNode[]) => {
      dispatch(
        setActiveFilter({
          hierarchy: nodeTree,
        }),
      );
      onChange?.(nodeTree);
    },
    [onChange],
  );
  useEffect(() => {
    if (!orgs || !hierarchyTreeOptions.length || isAllSelected) return;
    const allNodes = getAllNodes(hierarchyTreeOptions);
    setIsAllSelected(true);
    handleSelectNode(allNodes);
  }, [orgs]);

  // memoize activeFilter.hierarchy and create copy to trigger rerender
  const activeFilterHierarchy = useMemo(() => {
    return activeFilter?.hierarchy ? [...activeFilter?.hierarchy] : [];
  }, [activeFilter?.hierarchy]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="200px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-around"
    >
      {hierarchyTreeOptions && (
        <>
          <ECTypography
            color={theme => theme.palette.text.secondary}
            variant="caption"
            minWidth="80px"
          >
            Org Structure:
          </ECTypography>
          <ECBox
            display="flex"
            alignItems="center"
            onClick={handleClickHierarchy}
            ref={hierarchyRef}
          >
            {inputChip}
            <ECIconButton
              type="default"
              size="small"
              noPadding
              sx={{ marginLeft: '5px' }}
            >
              <ArrowDropDown />
            </ECIconButton>
          </ECBox>
          <ECPopover
            id={'hierarchy-popover'}
            open={Boolean(anchorElHierarchy)}
            anchorEl={anchorElHierarchy}
            onClose={handleCloseHierarchy}
            anchorOrigin={{
              vertical: 35,
              horizontal: 90,
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              bgcolor: 'transparent',
              '& .MuiPopover-paper': {
                minWidth: '350px',
                bgcolor: 'transparent',
                overflow: 'hidden !important',
              },
              overflow: 'hidden !important',
            }}
          >
            <ECBox
              bgcolor="white !important"
              height="450px"
              width="350px"
              p={1}
            >
              <ECTextField
                fullWidth
                label="Search"
                variant="filled"
                onChange={handleChangeSearchTerm}
                sx={{
                  marginBottom: '10px',
                }}
              />
              <ECTree
                tree={hierarchyTreeOptions}
                initialSelectedNodesIds={[]}
                selectedNodes={activeFilterHierarchy}
                onSelectedNodesChange={handleSelectNode}
                showSelectAllOption
                setAllSelected={setIsAllSelected}
                customHeight="calc(100% - 56px)" //56px is the height of the search input
              />
            </ECBox>
          </ECPopover>
        </>
      )}
    </Stack>
  );
};
