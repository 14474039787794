import {
  ECBox,
  ECButton,
  ECDynamicPageTemplate,
  ECEasyFormCreate,
  ECIconButton,
  ECTypography,
  ECWorkflowTemplate,
} from 'app/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { useGetManufactureListQuery } from 'services/manufactureApi';
import {
  useGetInventoryItemByIdQuery,
  useLazyGetInventoryStorageListByInvetoryIdQuery,
  useGetInventoryStorageLocationsListQuery,
  useUpdateInventoryItemByIdMutation,
  useAdjustInventoryItemStockMutation,
  useUpdateInventoryItemStockMutation,
  useLazyGetInventoryAllocatedToQuery,
  useLazyGetValidatePartNumberQuery,
} from 'services/inventoryApi';
import { Outlet, useParams } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { StyleConstants } from 'styles/StyleConstants';
import * as _ from 'lodash';
import { currencyFormatter } from 'utils/strings/currency-formatter';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import { TransferInventory } from './TransferInventory';
import { useTranslation } from 'react-i18next';
import { useCustomerUser } from 'app/hooks';
import { Attachment } from '@mui/icons-material';
import { ECAttachmentsListPopover } from 'app/components/ECAttachmentsListPopover';
import { PartNumberType } from 'types/Inventory';
import { themes } from 'styles/theme/themes';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';

const fancyFormElementsAdjustInventoryItemStock = require('./fancy_form_config_adjust.json');
const fancyFormElementsEditInventoryItemStock = require('./fancy_form_config_edit.json');

export const InventoryDetailsPage = () => {
  const [trigger, result] = useLazyGetInventoryAllocatedToQuery();
  const [formElementsAdjust, setFormElementsAdjust] = useState<any>(
    fancyFormElementsAdjustInventoryItemStock,
  );

  const [formElementsEdit, setFormElementsEdit] = useState<any>(
    fancyFormElementsEditInventoryItemStock,
  );
  const [
    hasEditInventoryPermission,
    hasGetInventoryStoragePermission,
    hasTransferStockPermission,
    hasAdjustStockPermission,
  ] = useHasPermission([
    P.EditInventory,
    P.GetInventoryStorage,
    P.TransferStock,
    P.AdjustStock,
  ]);

  const isCustomer = useCustomerUser();

  const [isOpen, setIsOpen] = useState(false);

  const [currentRowData, setCurrentRowData] = useState<any>({});

  const { id } = useParams();
  const { t } = useTranslation();

  const [drawerType, setDrawerType] = useState<'transferInventory'>();
  const [lastChangedControl, setLastChangedControl] = useState<PartNumberType>(
    PartNumberType.None,
  );
  const [triggerValidatePartNumber, resultValidatePartNumber] =
    useLazyGetValidatePartNumberQuery();
  const { data: validatePartNumberData } = resultValidatePartNumber;

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('activity') &&
    !window.location.href.includes('warranty') &&
    !window.location.href.includes('history') &&
    !window.location.href.includes('allocated-to');

  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});

  const { data: inventoryItem } = useGetInventoryItemByIdQuery(Number(id), {
    skip: !id,
  });

  const [updateInventoryItem, { isError, error, isSuccess, isLoading }] =
    useUpdateInventoryItemByIdMutation();

  const [invalidFields, setInvalidFields] = useState<any[]>([]);
  const [helperTextForFields, setHelperTextForFields] = useState<any[]>([]);
  const useUpdateMutation = useCallback(() => {
    const doUpdate = async data => {
      if (!id) {
        return;
      }

      if (_.isEmpty(summaryFieldsValues?.name?.trim())) {
        setInvalidFields([
          {
            id: 'name',
            message: 'Required',
          },
        ]);

        return;
      }
      if (
        invalidFields.length > 0 &&
        invalidFields.some(field => field.id === PartNumberType.PartNumber)
      ) {
        setInvalidFields([
          {
            id: PartNumberType.PartNumber,
            message: 'Part # is already in use',
          },
        ]);

        return;
      }
      setInvalidFields([]);

      updateInventoryItem({
        id,
        ...summaryFieldsValues,
        assetTypeIds: summaryFieldsValues?.assetTypes?.map(
          assetTypes => assetTypes.id,
        ),
        storageLocationIds: summaryFieldsValues?.storageLocations?.map(
          storageLocation => storageLocation.id,
        ),
        manufactureId: summaryFieldsValues?.manufacture?.id,
        status: summaryFieldsValues?.status ? 1 : 0,
      });
    };
    return [
      doUpdate,
      {
        isError,
        error,
        isSuccess,
        isLoading,
      },
    ];
  }, [
    id,
    isError,
    error,
    isSuccess,
    isLoading,
    summaryFieldsValues,
    updateInventoryItem,
    invalidFields,
  ]);

  useEffect(() => {
    // Part # section
    const isDuplicatePartNumber =
      validatePartNumberData?.isDuplicate &&
      validatePartNumberData.matchingField === PartNumberType.PartNumber &&
      lastChangedControl === PartNumberType.PartNumber;

    if (isDuplicatePartNumber) {
      setInvalidFields([
        {
          id: PartNumberType.PartNumber,
          message: 'Part # is already in use',
        },
      ]);
      setHelperTextForFields(prevFields =>
        prevFields.filter(field => field.id !== PartNumberType.PartNumber),
      );
    }
    if (
      validatePartNumberData &&
      !validatePartNumberData.isDuplicate &&
      lastChangedControl === PartNumberType.PartNumber
    ) {
      setInvalidFields([]);
    }
    const isDuplicateOtherPartNumber =
      validatePartNumberData?.isDuplicate &&
      (validatePartNumberData.matchingField ===
        PartNumberType.ManufacturePartNumber ||
        validatePartNumberData.matchingField ===
          PartNumberType.SupplierPartNumber) &&
      lastChangedControl === PartNumberType.PartNumber;
    if (isDuplicateOtherPartNumber) {
      setHelperTextForFields(prevFields => [
        ...prevFields,
        {
          id: PartNumberType.PartNumber,
          message: `This Part # is used as ${_.startCase(validatePartNumberData.matchingField)} in ${validatePartNumberData.inventoryName}`,
        },
      ]);

      setInvalidFields(prevFields =>
        prevFields.filter(field => field.id !== PartNumberType.PartNumber),
      );
    }
    if (
      validatePartNumberData &&
      !validatePartNumberData.isDuplicate &&
      lastChangedControl === PartNumberType.PartNumber
    ) {
      setHelperTextForFields(prevFields =>
        prevFields.filter(field => field.id !== PartNumberType.PartNumber),
      );
    }

    // Supplier Part # section
    const isDuplicateSupplierPartNumber =
      validatePartNumberData?.isDuplicate &&
      validatePartNumberData.matchingField !==
        PartNumberType.SupplierPartNumber &&
      lastChangedControl === PartNumberType.SupplierPartNumber;
    if (isDuplicateSupplierPartNumber) {
      setHelperTextForFields(prevFields => [
        ...prevFields,
        {
          id: PartNumberType.SupplierPartNumber,
          message: `This Supplier Part # is used as ${_.startCase(validatePartNumberData.matchingField)} in ${validatePartNumberData.inventoryName}`,
        },
      ]);
    }
    if (
      validatePartNumberData &&
      !validatePartNumberData.isDuplicate &&
      lastChangedControl === PartNumberType.SupplierPartNumber
    ) {
      setHelperTextForFields(prevFields =>
        prevFields.filter(
          field => field.id !== PartNumberType.SupplierPartNumber,
        ),
      );
    }

    // Manufacturer Part # section
    const isDuplicateManufacturerPartNumber =
      validatePartNumberData?.isDuplicate &&
      validatePartNumberData.matchingField !==
        PartNumberType.ManufacturePartNumber &&
      lastChangedControl === PartNumberType.ManufacturePartNumber;
    if (isDuplicateManufacturerPartNumber) {
      setHelperTextForFields(prevFields => [
        ...prevFields,
        {
          id: PartNumberType.ManufacturePartNumber,
          message: `This Manufacturer Part # is used as ${_.startCase(validatePartNumberData.matchingField)} in ${validatePartNumberData.inventoryName}`,
        },
      ]);
    }
    if (
      validatePartNumberData &&
      !validatePartNumberData.isDuplicate &&
      lastChangedControl === PartNumberType.ManufacturePartNumber
    ) {
      setHelperTextForFields(prevFields =>
        prevFields.filter(
          field => field.id !== PartNumberType.ManufacturePartNumber,
        ),
      );
    }
  }, [validatePartNumberData, lastChangedControl]);

  const validateAndTrigger = (value: string, fieldName: string) => {
    if (value && value.trim() !== '') {
      triggerValidatePartNumber({ inputPartNumber: value, fieldName });
    } else {
      triggerValidatePartNumber({ inputPartNumber: '!!!', fieldName });
    }
  };

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    if (
      fieldName === PartNumberType.PartNumber ||
      fieldName === PartNumberType.ManufacturePartNumber ||
      fieldName === PartNumberType.SupplierPartNumber
    ) {
      validateAndTrigger(value, fieldName);
      setLastChangedControl(fieldName as PartNumberType);
    }
    setSummaryFieldsValues(prevSummaryFieldsValues => ({
      ...prevSummaryFieldsValues,
      [fieldName]: value,
    }));
  };
  const initializeSummaryFields = () => {
    setSummaryFieldsValues({
      ...inventoryItem,
      storageLocations: inventoryItem?.inventoryItemStorage?.map(
        storage => storage?.inventoryStorage,
      ),
    });
  };

  useEffect(() => {
    if (inventoryItem) {
      initializeSummaryFields();
      trigger({ id: Number(id), queryParams: { t: 1 } });
    }
  }, [inventoryItem]);

  const {
    data: inventoryStorageList,
    isFetching: isInventoryStorageListFetching,
    isLoading: isInventoryStorageListLoading,
    isSuccess: isInventoryStorageListSuccess,
  } = useGetInventoryStorageLocationsListQuery(
    {
      ob: 'invntrystrg.name',
    },
    {
      skip: !hasGetInventoryStoragePermission,
    },
  );

  useEffect(() => {
    if (isInventoryStorageListSuccess && inventoryStorageList?.data?.length) {
      const updatedFormElementsAdjust = { ...formElementsAdjust };
      const storageLocationField = updatedFormElementsAdjust.fields.find(
        field => field.fieldName === 'storageLocation',
      );

      if (storageLocationField) {
        storageLocationField.options = inventoryStorageList.data.map(
          storageLocation => ({
            id: storageLocation.id,
            label: storageLocation.name,
          }),
        );
      }

      const quantityAndCostGroup = updatedFormElementsAdjust.fields.find(
        field => field.fieldName === 'quantityAndCostGroup',
      );

      const costPerUnitField = quantityAndCostGroup?.subFields.find(
        field => field.fieldName === 'costPerUnit',
      );

      if (costPerUnitField) {
        costPerUnitField.value = inventoryItem?.avgPrice;
      }

      setFormElementsAdjust(updatedFormElementsAdjust);
    }
  }, [inventoryStorageList, isInventoryStorageListSuccess]);

  const [
    doAdjustInventoryItemStock,
    {
      data: adjustInventoryItemStockData,
      isError: adjustInventoryItemStockError,
      error: adjustInventoryItemStockErrorData,
      isLoading: isAdjustInventoryItemStockLoading,
      isSuccess: isAdjustInventoryItemStockSuccess,
      reset: resetAdjustInventoryItemStock,
    },
  ] = useAdjustInventoryItemStockMutation();

  const useAdjustInventoryItemsStockMutation = useCallback(() => {
    const doAdjust = async data => {
      if (!id) {
        return;
      }

      doAdjustInventoryItemStock({
        _id: data.storageLocation.id,
        inventoryItemId: Number(id),
        costPerUnit: parseFloat(data.costPerUnit),
        count: parseInt(data.quantityOnHand),
        addStock: data.adjustType === 'addStock' ? true : false,
        removeStock: data.adjustType === 'removeStock' ? true : false,
        description: data.description,
        lot: data.lotNumber,
        bin: data.binNumber,
        inventoryActivityReceipts: data.stockReceipts,
      });
    };
    return [
      doAdjust,
      {
        isError: adjustInventoryItemStockError,
        error: adjustInventoryItemStockErrorData,
        isSuccess: isAdjustInventoryItemStockSuccess,
        isLoading: isAdjustInventoryItemStockLoading,
        reset: resetAdjustInventoryItemStock,
      },
    ];
  }, [
    id,
    adjustInventoryItemStockError,
    adjustInventoryItemStockErrorData,
    isAdjustInventoryItemStockSuccess,
    isAdjustInventoryItemStockLoading,
    doAdjustInventoryItemStock,
    resetAdjustInventoryItemStock,
  ]);

  const renderEditStockActionButton = useCallback(
    (row: any, parentRow: any) => {
      if (inventoryItem?.status === 0) {
        return null;
      }

      return (
        <ECButton
          variant="outlined"
          size="small"
          scopes={[P.AdjustStock]}
          sx={{
            height: '2.5rem',
            width: '2.5rem',
            minWidth: 'unset',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            const count = Number(row?.count)?.toString();
            const combinedRow = {
              ...row,
              storageLocation: parentRow?.inventoryStorage?.name || '',
              quantityOnHand: count,
              costPerUnit: row?.price,
              lotNumber: row?.lot,
              binNumber: row?.bin,
            };

            setCurrentRowData(combinedRow);
            setIsOpen(true);
          }}
          key={`${row.id}-edit-stock`}
        >
          <EditIcon />
        </ECButton>
      );
    },
    [inventoryItem?.status],
  );

  const [
    doUpdateInventoryItemStock,
    {
      isError: updateInventoryItemStockError,
      error: updateInventoryItemStockErrorData,
      isLoading: isUpdateInventoryItemStockLoading,
      isSuccess: isUpdateInventoryItemStockSuccess,
      reset: resetUpdateInventoryItemStock,
    },
  ] = useUpdateInventoryItemStockMutation();

  const useUpdateInventoryItem = useCallback(() => {
    const doUpdate = async data => {
      if (!id) {
        return;
      }

      const updatedData = {
        _id: currentRowData?.id,
        inventoryItemId: parseInt(id),
        costPerUnit: parseFloat(data.costPerUnit),
        count: parseInt(data.quantityOnHand),
        description: data.description,
        lot: data.lotNumber,
        bin: data.binNumber,
        adjustReason: data.adjustReason,
      };

      doUpdateInventoryItemStock(updatedData);
    };

    return [
      doUpdate,
      {
        isError: updateInventoryItemStockError,
        error: updateInventoryItemStockErrorData,
        isLoading: isUpdateInventoryItemStockLoading,
        isSuccess: isUpdateInventoryItemStockSuccess,
        reset: resetUpdateInventoryItemStock,
      },
    ];
  }, [
    id,
    currentRowData,
    updateInventoryItemStockError,
    updateInventoryItemStockErrorData,
    isUpdateInventoryItemStockLoading,
    isUpdateInventoryItemStockSuccess,
    doUpdateInventoryItemStock,
  ]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setCurrentRowData({});
    resetUpdateInventoryItemStock();
    setDrawerType(undefined);
  }, []);

  const handleChangeEditStockItem = useCallback(() => {}, []);

  const summaryData = useMemo(() => {
    const getFieldValidity = fieldId => {
      const invalidField = invalidFields?.find(field => field.id === fieldId);
      return invalidField ? false : undefined;
    };
    const getValidationMessage = fieldId => {
      const invalidField = invalidFields?.find(field => field.id === fieldId);
      return invalidField?.message ?? '';
    };

    const getHelperTextMessage = fieldId => {
      const helperTextField = helperTextForFields?.find(
        field => field.id === fieldId,
      );
      return helperTextField?.message ?? '';
    };
    const partNumberHelperText = getHelperTextMessage('partNumber');
    const partNumberValidationMessage = getValidationMessage('partNumber');
    const manufacturePartNumberHelperText = getHelperTextMessage(
      'manufacturePartNumber',
    );
    const supplierPartNumberHelperText =
      getHelperTextMessage('supplierPartNumber');

    return [
      {
        id: 'id',
        label: 'Inventory ID',
        data: inventoryItem?.id,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'name',
        label: 'Part Name',
        data: summaryFieldsValues?.name ?? '',
        type: SummaryFieldTypes.Text,
        isEditable: true,
        onChange: handleSummaryFieldChange('name'),
        isValid: getFieldValidity('name'),
        validationMessage: getValidationMessage('name'),
      },
      {
        id: 'status',
        label: 'State',
        data: summaryFieldsValues?.status ?? '',
        type: SummaryFieldTypes.Switch,
        isEditable: true,
        onChange: handleSummaryFieldChange('status'),
      },
      {
        id: 'partNumber',
        label: 'Customer Part #',
        data: summaryFieldsValues?.partNumber ?? '',
        type: SummaryFieldTypes.Text,
        isEditable: true,
        onChange: handleSummaryFieldChange('partNumber'),
        isValid: getFieldValidity('partNumber'),
        validationMessage: partNumberHelperText || partNumberValidationMessage,
        showErrorIcon:
          _.isString(partNumberHelperText) &&
          _.trim(partNumberHelperText) !== '',
      },
      {
        id: 'manufacture',
        label: 'Manufacturer',
        data: summaryFieldsValues?.manufacture ?? '',
        type: SummaryFieldTypes.SelectPaginated,
        isEditable: true,
        useQuery: useGetManufactureListQuery,
        onChange: handleSummaryFieldChange('manufacture'),
      },
      {
        id: 'manufacturePartNumber',
        label: 'Manufacturer Part #',
        data: summaryFieldsValues?.manufacturePartNumber ?? '',
        type: SummaryFieldTypes.Text,
        isEditable: true,
        onChange: handleSummaryFieldChange('manufacturePartNumber'),
        validationMessage: manufacturePartNumberHelperText,
        showErrorIcon:
          _.isString(manufacturePartNumberHelperText) &&
          _.trim(manufacturePartNumberHelperText) !== '',
      },
      {
        id: 'supplier',
        label: 'Supplier Name',
        data: summaryFieldsValues?.supplier ?? '',
        type: SummaryFieldTypes.Text,
        isEditable: true,
        onChange: handleSummaryFieldChange('supplier'),
      },
      {
        id: 'supplierPartNumber',
        label: 'Supplier Part Number',
        data: summaryFieldsValues?.supplierPartNumber ?? '',
        type: SummaryFieldTypes.Text,
        isEditable: true,
        onChange: handleSummaryFieldChange('supplierPartNumber'),
        validationMessage: supplierPartNumberHelperText,
        showErrorIcon:
          _.isString(supplierPartNumberHelperText) &&
          _.trim(supplierPartNumberHelperText) !== '',
      },
      {
        id: 'tagId',
        label: 'Barcode / Tag ID',
        data: summaryFieldsValues?.tagId ?? '',
        type: SummaryFieldTypes.Text,
        isEditable: true,
        onChange: handleSummaryFieldChange('tagId'),
      },
      {
        id: 'minimumQuantity',
        label: 'Minimum Quantity',
        data: summaryFieldsValues?.minimumQuantity ?? '',
        type: SummaryFieldTypes.Text,
        isNumeric: true,
        isEditable: true,
        onChange: handleSummaryFieldChange('minimumQuantity'),
      },
      {
        id: 'assetTypes',
        label: 'Asset Group',
        data: summaryFieldsValues?.assetTypes,
        type: SummaryFieldTypes.AutocompleteChips,
        isEditable: true,
        useQuery: useGetAssetTypesListQuery,
        obAlias: 'assttp.name',
        onChange: handleSummaryFieldChange('assetTypes'),
        limitTags: 3,
        required: true,
      },
      {
        id: 'storageLocations',
        label: 'Storage Locations',
        data: summaryFieldsValues?.storageLocations,
        type: SummaryFieldTypes.AutocompleteChips,
        limitTags: 3,
      },
      {
        id: 'description',
        label: 'Description',
        data: summaryFieldsValues?.description ?? '',
        type: SummaryFieldTypes.Text,
        isEditable: true,
        onChange: handleSummaryFieldChange('description'),
      },
    ];
  }, [
    summaryFieldsValues,
    invalidFields,
    inventoryItem,
    handleSummaryFieldChange,
    helperTextForFields,
  ]);

  const renderCustomSummaryComponent = useMemo(() => {
    return (
      <>
        <ECBox display="flex" flexDirection="column" gap={2}>
          <ECBox
            display="flex"
            alignItems="flex-end"
            gap={1}
            justifyContent="flex-end"
          >
            <ECTypography
              variant="body1"
              sx={theme => ({
                color: theme.palette.primary.main,
                lineHeight: 0.8,
              })}
            >
              Quantity On Hand:
            </ECTypography>
            <ECTypography
              variant="h4"
              sx={theme => ({
                color: theme.palette.primary.main,
                lineHeight: 0.8,
              })}
            >
              {inventoryItem?.totalQuantity ?? ''}
            </ECTypography>
          </ECBox>

          <ECBox
            display="flex"
            alignItems="flex-end"
            gap={1}
            justifyContent="flex-end"
          >
            <ECTypography
              variant="body1"
              sx={theme => ({
                color: theme.palette.primary.main,
                lineHeight: 0.8,
              })}
            >
              Average Cost per Unit:
            </ECTypography>
            <ECTypography
              variant="h4"
              sx={theme => ({
                color: theme.palette.primary.main,
                lineHeight: 0.8,
              })}
            >
              {currencyFormatter.format(inventoryItem?.avgPrice || 0) ?? ''}
            </ECTypography>
          </ECBox>
        </ECBox>
      </>
    );
  }, [inventoryItem, inventoryItem?.totalQuantity, inventoryItem?.avgPrice]);

  const existingDataTransferInventory = useMemo(() => {
    return {
      inventoryItemId: inventoryItem?.id,
      inventoryItemName: inventoryItem?.name,
    };
  }, [inventoryItem]);

  const additionalActions = useMemo(() => {
    let actions: any = [];
    if (hasTransferStockPermission && inventoryItem?.id) {
      actions.push(
        <ECButton
          key={'transferInventory'}
          variant="outlined"
          onClick={() => setDrawerType('transferInventory')}
          startIcon={<ShortcutIcon />}
        >
          Transfer Inventory
        </ECButton>,
      );
    }
    return actions.length > 0 ? actions : undefined;
  }, [inventoryItem]);

  const [row, setSelectedRow] = useState<any>(null);
  const [anchorElAttachment, setAnchorElAttachment] = useState<any>(null);
  const isAttachmentOpen = Boolean(anchorElAttachment);

  const renderAttachmentButton = (row: any) => {
    return (
      <ECBox display="flex" justifyContent="center" width="100%" py={0.5}>
        <ECIconButton
          squared
          withBorder
          withWhiteBackground
          sx={{ color: theme => theme.palette.primary.main }}
          key={`attachment-${row.id}`}
          onClick={e => {
            setAnchorElAttachment(e.currentTarget);
            setSelectedRow(row);
          }}
        >
          <Attachment />
        </ECIconButton>
      </ECBox>
    );
  };

  const handleCloseAttachmentPopover = () => {
    setAnchorElAttachment(null);
  };

  return (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        summaryData={summaryData}
        customSummaryComponents={renderCustomSummaryComponent}
        moduleName="Inventory"
        title={inventoryItem?.name || `Inventory #${id}`}
        showEditTitleStartAdornment={true}
        detailsData={{}}
        editConfig={{
          submitTitle: 'Save',
        }}
        editFields={[]}
        detailsConfig={{}}
        detailsFields={[]}
        imgSrc={inventoryItem?.photo}
        useUpdateMutation={useUpdateMutation}
        hideNoteTab
        customDetailsTabLabel="Quantity and Cost"
        showEditForm={false}
        hideAttachmentTab={!isCustomer}
        hideActivityTab={!isCustomer}
        additionalTabs={[
          {
            value: 'allocated-to',
            link: 'allocated-to',
            label: `${t('translation:panelDrawer.inventoryAllocatedTo')} (${result?.data?.config?.pagination?.totalCount || 0})`,
            editable: false,
            content: <Outlet />,
          },
        ]}
        submissionConfirmationModal={{
          title: 'Confirm you want to deactivate this Inventory Item.',
          buttonText: 'Deactivate',
          bodyText:
            'By deactivating this Inventory Item you will block allocating and transferring stock. Any stock allocated in an active Work Order will not be affected.',
          isVisible: !summaryFieldsValues?.status,
          onClose: () => handleSummaryFieldChange('status')(true),
        }}
        additionalActions={additionalActions}
        onExitEditModeWithoutSave={initializeSummaryFields}
        isEditAllowed={hasEditInventoryPermission}
      />
      {isDetailsTab && (
        <>
          <TransferInventory
            isOpen={drawerType === 'transferInventory'}
            onClose={handleClose}
            existingData={existingDataTransferInventory}
          />
          <ECDynamicPageTemplate
            marginTop={false}
            pageTitle="Inventory"
            useLazyGetListQuery={
              useLazyGetInventoryStorageListByInvetoryIdQuery
            }
            idToGetListBy={id}
            useCreateMutation={
              inventoryItem?.status ? useAdjustInventoryItemsStockMutation : {}
            }
            useUpdateMutation={{}}
            showLocationFilter
            createFormConfig={
              inventoryItem?.status && hasAdjustStockPermission
                ? formElementsAdjust.config
                : undefined
            }
            createFormFields={
              inventoryItem?.status && hasAdjustStockPermission
                ? formElementsAdjust.fields
                : undefined
            }
            useLocationFilterQuery={
              hasGetInventoryStoragePermission
                ? useGetInventoryStorageLocationsListQuery
                : undefined
            }
            moduleName="inventory"
            renderCustomActionComponent={renderEditStockActionButton}
            renderCustomAttachmentComponent={renderAttachmentButton}
            withDrawer={false}
            shouldNotUseActiveFilter
            drawerTitleBarBGColor={themes.light.palette.other.divider}
          />

          <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
            <ECBox display="flex" pt={StyleConstants.NAV_BAR_HEIGHT}>
              <ECEasyFormCreate
                useCreateMutation={useUpdateInventoryItem}
                formConfig={formElementsEdit.config}
                formFields={formElementsEdit.fields}
                existingData={currentRowData}
                onClose={handleClose}
                onChange={handleChangeEditStockItem}
                stickyFooter={true}
                drawerTitleBarBGColor={themes.light.palette.other.divider}
              />
            </ECBox>
          </ECDrawerDetails>

          {anchorElAttachment && (
            <ECAttachmentsListPopover
              moduleName="inventory-activity"
              row={row}
              isAttachmentOpen={isAttachmentOpen}
              anchorElAttachment={anchorElAttachment}
              onCloseAttachmentPopover={handleCloseAttachmentPopover}
              label="Stock Receipt"
            />
          )}
        </>
      )}
    </>
  );
};
