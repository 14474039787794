import {
  useLazyGetBranchesListQuery,
  useCreateBranchMutation,
  useGetBranchTypesQuery,
} from 'services/branchApi';
import { useGetOrganizationParentListQuery } from 'services/organizationApi';
import { ECDynamicPageTemplate } from 'app/components';
import { populateDropDownFields } from 'utils/pageUtils';
import { loadPageScopes } from 'utils/pageScopes';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { useGetBranchesListQuery } from 'services/branchApi';

const editFormElements = require('./fancy_form_config_edit.json');
const createFormElements = require('./fancy_form_config_create.json');
const formElementsDetails = require('./fancy_form_config_details.json');

export function Branches({ marginTop = true }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('branch');
    dispatch(setFilterEndpoint('/filter/branches'));
  }, []);

  const [createFormFields, setCreateFormFields] = useState<any>(
    _.clone(createFormElements.fields) || [],
  );

  const { data: userProfile } = useGetUserProfileQuery();

  useEffect(() => {
    if (userProfile?.costCenterEnabled) {
      // create
      const costCenterField = createFormFields
        .find(field => field.groupName === 'salesAndCostCenter')
        ?.subFields?.find(field => field.fieldName === 'costCenter');
      costCenterField.visible = true;

      setCreateFormFields([...createFormFields]);
    }
  }, [userProfile?.costCenterEnabled]);

  useEffect(() => {
    const createAssetTypeField = createFormFields.find(
      field => field.fieldName === 'branchType',
    );
    createAssetTypeField.useQuery = useGetBranchTypesQuery;
    createAssetTypeField.obAlias = 'name';

    setCreateFormFields([...createFormFields]);
  }, []);

  useEffect(() => {
    const copyTradeAssignmentLocationIdField = createFormFields.find(
      field => field.fieldName === 'copyTradeAssignmentLocationId',
    );
    copyTradeAssignmentLocationIdField.useQuery = useGetBranchesListQuery;
    copyTradeAssignmentLocationIdField.obAlias = 'brnch.name';
    copyTradeAssignmentLocationIdField.queryParams = { st: 1 };
    setCreateFormFields([...createFormFields]);
  }, []);

  const { data: dataOrgLevelList, isSuccess: isSuccessOrgLevel } =
    useGetOrganizationParentListQuery();

  useEffect(() => {
    if (isSuccessOrgLevel && dataOrgLevelList) {
      populateDropDownFields({
        responseData: dataOrgLevelList,
        createFormFields,
        isPopulateForChipsComponent: true,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'organization',
      });
    }
  }, [isSuccessOrgLevel, dataOrgLevelList]);

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:panelDrawer.branches')}
        useLazyGetListQuery={useLazyGetBranchesListQuery}
        useCreateMutation={useCreateBranchMutation}
        editFormConfig={editFormElements.config}
        editFormFields={editFormElements.fields}
        createFormConfig={createFormElements.config}
        createFormFields={createFormFields}
        hasDetails={false}
        detailsFields={formElementsDetails.fields}
        withDrawer={false}
        showStatusActiveFilter
        showOnlyAttachmentsTab={true}
        marginTop={marginTop}
        enableExport
        exportModuleName="branch"
        shouldNotUseActiveFilter
        showHierarchyFilter
        showDrawerDetailTitle={false}
      />
    </>
  );
}
