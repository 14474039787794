import type { QueryParams } from './QueryParams';

export interface AccountingGeneralLedgerCodes {
  assetGroupName: string;
  branchName?: string;
}

export interface AccountingQueryParams extends QueryParams {
  setBy: AccountingViewSetByEnum;
}

export enum AccountingViewSetByEnum {
  COMPANY = 'company',
  LOCATIONS = 'locations',
  CONCEPTS = 'concepts',
}
