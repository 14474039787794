import { useCallback, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  ECBox,
  ECButton,
  ECEasyForm,
  ECWorkflowTemplate,
  FailureWarrantyOutput,
} from 'app/components';
import {
  SummaryFieldTypes,
  SummaryFieldsData,
} from 'app/components/ECWorkflowSummaryField';
import { useLazyGetAttachmentQuery } from 'services/attachmentApi';
import {
  useUpdateInvoiceMutation,
  useGetNextStatusListQuery,
  useUpdateStatusMutation,
  useGetWarrantyComponentForInvoiceIdQuery,
} from 'services/invoiceApi';
import { useLazyGetAssetsByBranchIdAndAssetGroupIdQuery } from 'services/branchApi';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { useGetProblemListQuery } from 'services/problemApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { populateDropDownFields } from 'utils/pageUtils';
import {
  CostTableData,
  InventoryItemShapeWithReadOnly,
} from '../../components/ECWorkflowCostTable';
import { WorkflowNextStatus, StatusActions } from 'types/WorkflowStatus';
import { useLazyGetRepairsListForAssetQuery } from 'services/repairApi';
import { approvalApi, useGetApprovalHistoryQuery } from 'services/approvalApi';
import { useDispatch } from 'react-redux';
import { InvoiceDetails } from 'types/Invoice';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { setSnackbar } from 'store/slice/page';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { Popover } from '@mui/material';
import { ECUsersTableModal } from 'app/components/ECUsersTableModal';
import { useTranslation } from 'react-i18next';
import { ECModal } from 'app/components/ECModal';
import { moduleApi, useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { formatDecimalHours } from 'utils/dates';
import { ECApprovalHistoryButton } from 'app/components/ECApprovalHistory/ECApprovalHistoryButton';
import _ from 'lodash';
import { WorkflowStatus } from 'app/components/ECWorkflowStatusBadge';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import { groupAndProcessInventoryItems } from 'utils/common';
import moment from 'moment';
import InvoicePrintCard from './InvoicePrintCard';

const formElementsEdit = require('./fancy_form_config_edit.json');
const detailsForm = require('./details-form.json');
const fancyFormRejectInvoice = require('./fancy_form_config_reject.json');

export function InvoicesDetailsPage() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [openZeroTotalInfo, setOpenZeroTotalInfo] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<any[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [anchorElPrint, setAnchorElPrint] = useState<any>(null);
  const openPrintActionPopover = Boolean(anchorElPrint);

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('activity');

  const { data: invoice } = useGetModuleDetailsByIdQuery(
    { id: id ?? '', moduleName: 'invoice' },
    { skip: !id },
  );

  const hasEditInvoicePermission = useHasPermission([P.EditInvoice]);

  const { data: problems } = useGetProblemListQuery(
    {},
    {
      skip:
        !invoice?.mutableFields?.includes('problemId') ||
        !isDetailsTab ||
        !isEditMode,
    },
  );
  const { data: warrantyComponents } = useGetWarrantyComponentForInvoiceIdQuery(
    id ?? '',
    { skip: !id || !isDetailsTab },
  );
  const { t } = useTranslation();

  const { data: userProfile } = useGetUserProfileQuery();

  useEffect(() => {
    if (invoice) {
      setFormFields(
        detailsForm.fields.map(field => {
          if (field.type === 'group') {
            field.subFields.forEach(subField => {
              if (invoice.request?.purchaseOrder) {
                if (
                  [
                    'request.asset.assetType.name',
                    'request.trade.name',
                    'request.problem.name',
                    'warrantyComponents',
                    'warrantyUsed',
                    'request.customerCode',
                    'onSiteTime',
                    'invoiceDate',
                  ].includes(subField.fieldName)
                ) {
                  subField.visible = false;
                }

                if (
                  [
                    'request.purchaseOrder.shipToUser',
                    'request.purchaseOrder.shipToUserPhone',
                    'request.purchaseOrder.shipToUserEmail',
                    'createdByUser.email',
                  ].includes(subField.fieldName)
                ) {
                  subField.visible = true;
                }
              } else {
                subField.visible = true;

                if (
                  [
                    'request.purchaseOrder.shipToUser',
                    'request.purchaseOrder.shipToUserPhone',
                    'request.purchaseOrder.shipToUserEmail',
                  ].includes(subField.fieldName)
                ) {
                  subField.visible = false;
                }
              }

              if (
                invoice?.mutableFields?.includes(subField.fieldName) &&
                isEditMode
              ) {
                subField.readOnly = false;
              } else {
                subField.readOnly = true;

                if (subField.fieldName === 'problemId') {
                  subField.type = 'text_field';
                }
              }
            });
          }

          if (invoice?.mutableFields?.includes(field.fieldName) && isEditMode) {
            field.readOnly = false;
          } else {
            field.readOnly = true;
          }

          if (field.fieldName === 'invoiceDate') {
            field.label = invoice.request?.purchaseOrder
              ? 'Invoice Date'
              : 'Service Provider Invoice Date';
          }

          if (
            [
              'request.customerCode',
              'request.asset.branch.phone',
              'address',
              'createdAt',
            ].includes(field.fieldName)
          ) {
            field.visible = invoice.request?.purchaseOrder ? false : true;
          }

          return field;
        }),
      );
    }
  }, [invoice, invoice?.mutableFields, isEditMode]);

  const [invoiceEdited, setInvoiceEdited] = useState(invoice);

  const initializeSummaryFields = useCallback(() => {
    setInvoiceEdited({
      ...invoice,
      assetId: invoice.request?.asset?.id,
      categoryId: invoice.request?.category?.id,
      flagsIds: invoice.flags,
    });
  }, [invoice]);

  useEffect(() => {
    if (invoice) {
      initializeSummaryFields();
    }
  }, [invoice, initializeSummaryFields]);

  const [validations, setValidations] = useState<any>([]);

  const [assetTrigger, assetResult] =
    useLazyGetAssetsByBranchIdAndAssetGroupIdQuery();

  const [failuresTrigger, failures] = useLazyGetRepairsListForAssetQuery();

  const { data: categoryListRawData } = useGetCategoryListQuery({ st: 1 });

  const isPOInvoice = !!invoice?.request?.purchaseOrder;

  const categoryListData = useMemo(() => {
    if (categoryListRawData) {
      return {
        data: categoryListRawData?.data
          ?.filter(category => {
            if (isPOInvoice) {
              return category?.module?.name === 'Inventory';
            }

            return !category?.module;
          })
          .map(category => ({
            id: category.id,
            name: category.name,
          })),
      };
    }
  }, [categoryListRawData, isPOInvoice]);

  const dropdownCategoryList = useMemo(() => {
    if (categoryListData) {
      const categories = categoryListData?.data?.map(category => ({
        label: category?.name,
        data: category?.id,
      }));

      // if current category is not on list, add it
      if (
        invoice?.request.category?.name &&
        invoice?.request.category?.id &&
        !categories?.some(
          category => category?.data === invoice?.request.category?.id,
        )
      ) {
        categories?.push({
          label: invoice?.request.category?.name,
          data: invoice?.request.category?.id,
        });
      }
      return categories;
    } else {
      return [];
    }
  }, [categoryListData, invoice?.request.category]);

  const [attachmentTrigger, attachmentsResult] = useLazyGetAttachmentQuery();
  const { data: attachments } = attachmentsResult;

  const { data: workflowStatusList } = useGetNextStatusListQuery(
    Number(id) || 0,
    { skip: !id || !isDetailsTab },
  );

  const { data: approvalHistory, isSuccess: isSuccessApprovalHistory } =
    useGetApprovalHistoryQuery(
      {
        id: Number(id) || 0,
        module: 'invoice',
      },
      {
        skip: !id || !isDetailsTab,
      },
    );

  // useEffect to add the view approval history button to the form
  useEffect(() => {
    // DETAILS FORM
    const approvalHistoryField = detailsForm.fields
      ?.find(field => field.groupName === 'customButtonsAndStatus')
      ?.subFields?.find(f => f.fieldName === 'approvalHistoryButton');

    if (approvalHistoryField) {
      approvalHistoryField.value = (
        <ECApprovalHistoryButton approvalHistory={approvalHistory} />
      );
    }

    detailsForm.fields = [...detailsForm.fields];
  }, [approvalHistory]);

  useEffect(() => {
    // EDIT FORM
    const approvalHistoryField = formElementsEdit.fields
      ?.find(field => field.groupName === 'customButtonsAndStatus')
      ?.subFields?.find(f => f.fieldName === 'approvalHistoryButton');

    if (approvalHistoryField) {
      approvalHistoryField.value = (
        <ECApprovalHistoryButton approvalHistory={approvalHistory} disabled />
      );
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  //const [assignedToSelected, setAssignedToSelected] = useState<any>(null);

  const onClickViewUsersTable = useCallback((field, event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    //setAssignedToSelected(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
    //setAssignedToSelected(false);
  }, []);

  useEffect(() => {
    const assignedToField = detailsForm?.fields[0]?.subFields?.find(
      x => x.fieldName === 'assignedTo',
    );
    if (assignedToField) {
      assignedToField.onClickViewUsersTable = onClickViewUsersTable;
    }
  }, [onClickViewUsersTable]);

  const assignedToUsers = useMemo(() => {
    return approvalHistory?.currentAssignees?.map(assignee => {
      return {
        fullName: assignee.fullName,
        jobTitle: assignee.jobTitle,
        email: assignee.email,
      };
    });
  }, [isSuccessApprovalHistory, approvalHistory]);

  const [
    doUpdateStatus,
    { isLoading: isLoadingActions, isSuccess: isSuccessUpdateAction },
  ] = useUpdateStatusMutation();

  useEffect(() => {
    if (
      invoice?.request?.asset?.branch?.id &&
      invoice?.request?.asset?.assetType?.id &&
      isDetailsTab
    ) {
      assetTrigger({
        id: invoice?.request?.asset?.branch?.id,
        agId: invoice?.request?.asset?.assetType?.id,
      });
    }
  }, [
    assetTrigger,
    isDetailsTab,
    invoice?.request?.asset?.assetType?.id,
    invoice?.request?.asset?.branch?.id,
  ]);

  useEffect(() => {
    if (
      invoice?.request?.asset?.branch?.id &&
      invoice?.request?.asset?.assetType?.id &&
      isDetailsTab
    ) {
      failuresTrigger(invoice?.request.asset?.id?.toString() ?? '');
    }
  }, [
    failuresTrigger,
    isDetailsTab,
    invoice?.request.asset?.assetType?.id,
    invoice?.request.asset?.branch?.id,
    invoice?.request.asset?.id,
  ]);

  useEffect(() => {
    if (isSuccessUpdateAction) {
      dispatch(approvalApi.util.invalidateTags(['Approval']));
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Invoice status updated successfully',
        }),
      );
    }
  }, [isSuccessUpdateAction]);

  const [
    doUpdateInvoice,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateInvoiceMutation();

  const costTableData = useMemo(
    (): CostTableData | undefined =>
      invoice && {
        nte: invoice.request.workorders?.[0]?.nte,
        openZeroTotalInfo,
        onClickAwayZeroTotalInfo: () => {
          if (openZeroTotalInfo) {
            setOpenZeroTotalInfo(false);
          }
        },
        editingTotalCost: isEditMode,
        itemsRepair: [
          {
            label: 'Labor',
            value: invoice.costLabor,
            dataPath: 'costLabor',
            readOnly: !invoice?.mutableFields?.includes('costLabor'),
          },
          {
            label: 'Material',
            value: invoice.costMaterial,
            dataPath: 'costMaterial',
            readOnly: !invoice?.mutableFields?.includes('costMaterial'),
          },
          {
            label: 'Tax',
            value: invoice.costTax,
            dataPath: 'costTax',
            readOnly: !invoice?.mutableFields?.includes('costTax'),
          },
          {
            label: 'Freight',
            value: invoice.costFreight,
            dataPath: 'costFreight',
            readOnly: !invoice?.mutableFields?.includes('costFreight'),
          },
          {
            label: 'Other',
            value: invoice.costOther,
            dataPath: 'costOther',
            readOnly: !invoice?.mutableFields?.includes('costOther'),
          },
        ],
        itemsInventory: groupAndProcessInventoryItems(
          invoice.allocatedInventory,
        )?.map(inventory =>
          typeof inventory === 'object'
            ? { ...inventory, readOnly: true }
            : inventory,
        ),
        // show if credit flag is enabled in customerProfile via invoice response
        shouldShowAddCredit:
          hasEditInvoicePermission && invoice.invoiceCreditEnabled,
        // if status is PENDING APPROVAL hide the add credit button
        shouldShowAddCreditButton:
          invoice.workflowStatus.name === WorkflowStatus.Approved &&
          !userProfile?.coupa?.enableCoupa,
        creditAmount: invoice.credit,

        totalWithDiscountAmount: invoice.totalWithDiscount,
        invoiceId: Number(id),
      },
    [
      invoice,
      id,
      hasEditInvoicePermission,
      openZeroTotalInfo,
      isEditMode,
      userProfile,
    ],
  );

  const purchaseOrderCostTableData = useMemo((): CostTableData | undefined => {
    if (isPOInvoice) {
      const transformedPurchaseOrderItems =
        invoice.request.purchaseOrder.purchaseOrderItems?.map(item => ({
          id: item.id,
          costPerUnit: item.price,
          itemName: item.inventoryItem.name,
          quantity: item.count,
          quantityReceived: item.countDelivered,
          subTotal: item.countDelivered * item.price,
        }));

      const validInventoryItems =
        groupAndProcessInventoryItems(invoice.allocatedInventory)
          ?.filter(
            (inventory): inventory is InventoryItemShapeWithReadOnly =>
              inventory !== undefined &&
              inventory !== null &&
              typeof inventory === 'object' &&
              Object.keys(inventory).length > 0,
          )
          ?.map(inventory => ({ ...inventory, readOnly: true })) || [];

      return {
        openZeroTotalInfo,
        onClickAwayZeroTotalInfo: () => {
          if (openZeroTotalInfo) {
            setOpenZeroTotalInfo(false);
          }
        },
        editingTotalCost: isEditMode,
        itemsRepair: [
          {
            label: 'Freight',
            value: invoice.costFreight,
            dataPath: 'costFreight',
            readOnly: !invoice?.mutableFields?.includes('costFreight'),
          },
          {
            label: 'Tax',
            value: invoice.costTax,
            dataPath: 'costTax',
            readOnly: !invoice?.mutableFields?.includes('costTax'),
          },
          {
            label: 'Other',
            value: invoice.costOther,
            dataPath: 'costOther',
            readOnly: !invoice?.mutableFields?.includes('costOther'),
          },
          {
            label: 'Purchase Order Items',
            value: transformedPurchaseOrderItems,
            dataPath: 'purchaseOrderItems',
            readOnly: !invoice?.mutableFields?.includes('price'),
            hidden: true,
          },
        ],
        itemsInventory: validInventoryItems,
        shouldShowAddCredit: false,
        shouldShowAddCreditButton: false,
        totalWithDiscountAmount: invoice.totalWithDiscount,
        invoiceId: Number(id),
      };
    }
  }, [invoice, openZeroTotalInfo, isEditMode, isPOInvoice]);

  const currentCostTableData = isPOInvoice
    ? purchaseOrderCostTableData
    : costTableData;

  useEffect(() => {
    if (warrantyComponents) {
      setFormFields(prev => {
        const groupedFieldsEdit = prev?.find(
          field => field.type === 'group',
        )?.subFields;
        if (!groupedFieldsEdit) return prev;

        const warrantyComponentsEditField = groupedFieldsEdit.find(
          field => field.fieldName === 'warrantyComponents',
        );

        const groupedFieldsDetails = detailsForm.fields.find(
          field => field.type === 'group',
        ).subFields;
        const warrantyComponentsDetailsField = groupedFieldsDetails.find(
          field => field.fieldName === 'warrantyComponents',
        );

        const warrantyComponentsValue: FailureWarrantyOutput[] =
          warrantyComponents?.map(warrantyComponent => ({
            repairId: warrantyComponent.repair?.id,
            repairName: warrantyComponent.repair?.name,
            warranty: {
              id: warrantyComponent.id,
              noWarranty: warrantyComponent.noWarranty,
              period: warrantyComponent.duration,
              periodLabel: warrantyComponent.period?.description,
              periodCode: warrantyComponent.period?.code,
              resolutionId: warrantyComponent.resolution?.id,
              resolution: warrantyComponent.resolution?.name,
            },
          }));

        if (warrantyComponentsEditField) {
          warrantyComponentsEditField.value = warrantyComponentsValue;
          warrantyComponentsEditField.overrideRowValue = true;
        }

        if (warrantyComponentsDetailsField) {
          warrantyComponentsDetailsField.value = warrantyComponentsValue;
          warrantyComponentsDetailsField.overrideRowValue = true;
        }

        formElementsEdit.fields = [...prev];
        return prev;
      });
    }
  }, [warrantyComponents]);

  useEffect(() => {
    if (failures) {
      setFormFields(prev => {
        const groupedFieldsEdit = prev?.find(
          field => field.type === 'group',
        )?.subFields;
        if (!groupedFieldsEdit) return prev;

        const warrantyComponentsEditField = groupedFieldsEdit.find(
          field => field.fieldName === 'warrantyComponents',
        );

        warrantyComponentsEditField.options = failures?.data?.map(failure => ({
          name: failure.name,
          id: failure.id,
        }));

        formElementsEdit.fields = [...prev];
        return prev;
      });
    }
  }, [failures]);

  const isAsset = useMemo(
    () => _.isNil(invoice?.request?.asset?.generalAsset),
    [invoice?.request?.asset?.generalAsset],
  );

  const moduleName = useMemo(() => {
    return isAsset ? 'asset' : 'general-asset';
  }, [isAsset]);

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  useEffect(() => {
    if (invoice) {
      attachmentTrigger({
        module: moduleName,
        moduleId: isAsset
          ? invoice.request.asset?.id
          : invoice.request.asset?.generalAsset?.id,
        category: AttachmentCategory.Photo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (invoice?.request?.category?.name === 'Maintenance') {
      setFormFields(prev => {
        const groupedFieldsEdit = prev?.find(
          field => field.type === 'group',
        )?.subFields;
        if (!groupedFieldsEdit) return prev;

        const groupedFieldsDetails = detailsForm.fields.find(
          field => field.type === 'group',
        ).subFields;

        // problem override
        const problemFieldEdit = groupedFieldsEdit.find(
          field => field.fieldName === 'request.problem.name',
        );

        problemFieldEdit.readOnly = true;
        problemFieldEdit.type = 'text_field';

        const problemFieldDetails = groupedFieldsDetails.find(
          field => field.fieldName === 'request.problem.name',
        );

        problemFieldDetails.type = 'text_field';
        problemFieldDetails.readOnly = true;

        // failure types override

        const warrantyComponentsEditField = groupedFieldsEdit.find(
          field => field.fieldName === 'warrantyComponents',
        );

        warrantyComponentsEditField.readOnly = true;

        formElementsEdit.fields = [...prev];
        return prev;
      });
    }
  }, [invoice]);

  useEffect(() => {
    let validation: any = [];
    if (invoiceEdited?.assetId === -1) {
      validation.push({
        field: 'assetId',
        validationMessage: 'Asset is required!',
      });
    }
    if (invoiceEdited?.categoryId === -1) {
      validation.push({
        field: 'categoryId',
        validationMessage: 'Category is required!',
      });
    }

    if (validation.length > 0) {
      setValidations(validation);
    } else setValidations([]);
  }, [invoiceEdited]);

  const { data: flagsData, isSuccess: isSuccessFlag } = useGetFlagsListQuery(
    {},
    { skip: !isDetailsTab || !isEditMode },
  );

  useEffect(() => {
    const flagsField = formFields?.find(
      field => field.fieldName === 'flagsIds',
    );

    if (
      flagsField &&
      !flagsField?.options?.length &&
      flagsData &&
      isSuccessFlag
    ) {
      setFormFields(prev => {
        return prev.map(field => {
          if (field.fieldName === 'flagsIds') {
            return {
              ...field,
              options:
                flagsData.data.map(d => ({
                  label: d.label,
                  color: d.color,
                  id: d.id,
                })) || [],
              optionValues: flagsData.data.map(d => d.label) || [],
            };
          }
          return field;
        });
      });
    }
  }, [flagsData, formFields]);

  useEffect(() => {
    if (problems) {
      const problemsOptions = [...problems.data];

      // check if current option is not prsente and push it
      if (
        invoice?.request.problemId &&
        invoice?.request.problem?.name &&
        !problemsOptions.some(p => p.id === invoice?.request.problemId)
      ) {
        problemsOptions.push({
          id: invoice?.request.problemId,
          name: invoice?.request.problem?.name,
        });
      }

      const [_newCreateForm, newEditForm] = populateDropDownFields({
        responseData: { ...problems, data: problemsOptions },
        editFormFields: formFields[0].subFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'problemId',
      });

      setFormFields(prev => {
        prev[0].subFields = newEditForm;

        return prev;
      });
    }
  }, [problems]);

  const onCancelReject = () => {
    setOpenReject(false);
  };

  const handleRejectInvoice = useCallback(
    (formData, fieldData, clearOnSuccess?: () => void) => {
      const rejectReasonField = fieldData.find(
        data => data.fieldName === 'rejectReason',
      );
      const rejectReason = rejectReasonField?.comment;

      const recipientIds = rejectReasonField?.selectedUsers?.map(
        user => user.nodeId,
      );

      const rejectActionId = workflowStatusList?.find(
        action => action.name === StatusActions.REJECT,
      )?.actionId;

      if (!rejectActionId) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Reject action not found. Please try again.',
          }),
        );
        return;
      }

      doUpdateStatus({
        id: Number(id),
        actionId: rejectActionId,
        declineReason: rejectReason,
        recipientIds,
      });
    },
    [doUpdateStatus, id, workflowStatusList],
  );

  useEffect(() => {
    // needs to insert value into moduleId field of reject form to load the notifier users
    if (invoice?.id) {
      const addCommentField = fancyFormRejectInvoice.fields.find(
        f => f.fieldName === 'rejectReason',
      );

      if (addCommentField) {
        addCommentField.moduleId = invoice?.id;
      }
    }
  }, [invoice?.id, fancyFormRejectInvoice]);

  useEffect(() => {
    if (
      (!isLoadingActions && isSuccessUpdateAction) ||
      (!isUpdateLoading && isUpdateSuccess)
    ) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
    }
  }, [
    isLoadingActions,
    isSuccessUpdateAction,
    isUpdateLoading,
    isUpdateSuccess,
  ]);

  const rejectModal = useMemo(() => {
    return (
      <ECEasyForm
        pattern="modal"
        config={fancyFormRejectInvoice.config}
        fields={fancyFormRejectInvoice.fields}
        isSendingData={isLoadingActions}
        isLoading={isLoadingActions}
        isLoadingForm={false}
        onFormSubmit={handleRejectInvoice}
        saveButtonColor="error"
        additionalActions={
          <ECButton type="button" variant="text" onClick={onCancelReject}>
            {t('translation:dynamicForm.cancel')}
          </ECButton>
        }
      />
    );
  }, [onCancelReject, handleRejectInvoice]);

  const updateStatus = useCallback(
    async (data: WorkflowNextStatus) => {
      if (data.name === 'REJECT') {
        setOpenReject(true);
        return;
      }

      doUpdateStatus({
        id: Number(id),
        statusTo: data.statusTo,
        actionId: data.actionId,
      });
    },
    [doUpdateStatus, id],
  );

  const useUpdateInvoice = useCallback(() => {
    const doUpdate = async data => {
      const flagsIds = data.flagsIds?.map(flag => flag?.id);
      const invoiceDto: any = {
        id: Number(id) ?? 0,
        invoiceNumber: invoiceEdited.invoiceNumber,
        //TODO: add customerCode to backend DTOs
        customerCode: data.customerCode,
        costLabor: data.costLabor,
        costTax: data.costTax,
        costMaterial: data.costMaterial,
        costOther: data.costOther,
        costFreight: data.costFreight,
        requestId: data.requestId,
        description: data.description,
        assetId: invoiceEdited?.assetId,
        categoryId: invoiceEdited?.categoryId,
        purchaseOrderItems: isPOInvoice
          ? data?.purchaseOrderItems?.map(item => ({
              id: item?.id,
              price: item?.costPerUnit,
            }))
          : undefined,
        invoiceDate: data.invoiceDate,
        warrantyUsed: data.warrantyUsed ? 1 : 0,
        flagsIds,
        problemId: data?.problemId,
      };

      if (
        data.warrantyComponents &&
        data.warrantyComponents.length > 0 &&
        !data.warrantyComponents.includes('Scheduled Service')
      ) {
        invoiceDto.warrantyComponents =
          data.warrantyComponents ??
          data?.warrantyComponents?.map(warrantyComponent => ({
            id: warrantyComponent?.warranty?.id,
            repairId: warrantyComponent?.repairId,
            assetId: data?.assetId,
            noWarranty: warrantyComponent?.warranty?.noWarranty ? 1 : 0,
            resolutionId: warrantyComponent?.warranty?.resolutionId,
            duration: warrantyComponent?.warranty?.period,
            periodCode: warrantyComponent?.warranty?.periodCode,
          }));
      }

      // remove problem ID if not editable or not a number
      if (
        !invoice?.mutableFields?.includes('problemId') ||
        typeof invoiceDto.problemId !== 'number'
      ) {
        delete invoiceDto.problemId;
      }

      if (data) {
        const totalCost =
          Number(data.costLabor) +
            Number(data.costMaterial) +
            Number(data.costTax) +
            Number(data.costOther) +
            Number(data.costFreight) ?? 0;

        const subtotalForInventoryCost = (groupAndProcessInventoryItems(
          invoice.allocatedInventory,
        )
          ?.map(inventory =>
            typeof inventory === 'object'
              ? { ...inventory, readOnly: true }
              : inventory,
          )
          ?.reduce((acc, value: any) => acc + (value?.total ?? 0), 0) ??
          0) as number;
        if (totalCost + subtotalForInventoryCost === 0) {
          const zeroInfoBox = document.querySelector('#ec-costtable-total');
          if (zeroInfoBox && zeroInfoBox.getBoundingClientRect()?.top < 0) {
            zeroInfoBox?.scrollIntoView();
          }
          setOpenZeroTotalInfo(true);
          return;
        }
      }

      doUpdateInvoice(invoiceDto);
    };
    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess,
        isLoading: isUpdateLoading,
      },
    ];
  }, [
    doUpdateInvoice,
    id,
    isUpdateError,
    isUpdateLoading,
    isUpdateSuccess,
    updateError,
    invoiceEdited,
    invoice,
    isPOInvoice,
  ]);

  const handlePrintButtonClick = (e: any) => {
    setAnchorElPrint(e?.currentTarget);
  };

  const handleSetAnchorElPrint = anchor => {
    setAnchorElPrint(anchor);
  };

  const invoicesDetails = useMemo(() => {
    // if BE returns assigneTo empty it means that the field should not be shown
    const assignedTo = invoice?.assignedTo ? assignedToUsers : null;
    const onSiteTime =
      invoice?.onSiteTime > 0 ? formatDecimalHours(invoice.onSiteTime) : '--';
    const approvedDate =
      invoice?.workflowStatus?.name === WorkflowStatus.Approved
        ? moment(invoice.approvedDate).format('MM/DD/YYYY')
        : '-';

    return {
      ...(invoiceEdited || invoice),
      assignedTo,
      onSiteTime,
      approvedDate,
    };
  }, [invoice, invoiceEdited, assignedToUsers]);

  const handleChangeSelect = (field, newValue, list) => {
    if (newValue?.data) {
      const newData = list?.find(f => f.id === newValue.data);
      if (newData) {
        setInvoiceEdited(
          prevState =>
            ({
              ...prevState,
              [field]: newData.id,
            }) as InvoiceDetails | undefined,
        );
      }
    } else {
      setInvoiceEdited(
        prevState =>
          ({
            ...prevState,
            [field]: -1,
          }) as InvoiceDetails | undefined,
      );
    }
  };

  const summaryDataInvoice = useMemo(() => {
    const summaryFields: SummaryFieldsData[] = [
      {
        id: 'invoiceStatus',
        label: 'Invoice Status',
        type: SummaryFieldTypes.Text,
        data: invoice?.workflowStatus.name,
      },
      {
        id: 'storageLocation',
        label: 'Storage Location',
        type: SummaryFieldTypes.Text,
        data: invoice?.request?.purchaseOrder?.inventoryStorage?.name,
        isVisible: !!invoice?.request?.purchaseOrder?.inventoryStorage?.name,
      },
      {
        id: 'shippingAddress',
        label: 'Shipping Address',
        type: SummaryFieldTypes.Text,
        data: invoice?.shippingAddress,
        isVisible: !!invoice?.shippingAddress,
      },
      {
        id: 'invoiceNumber',
        label: 'Invoice Number',
        type: SummaryFieldTypes.Text,
        data: invoiceEdited?.invoiceNumber,
        isEditable: true,
        onChange: newValue => {
          setInvoiceEdited(prev => ({ ...prev, invoiceNumber: newValue }));
        },
        isVisible: isPOInvoice && !!invoice?.invoiceNumber,
      },
      {
        id: 'supplier',
        label: 'Supplier',
        type: SummaryFieldTypes.Text,
        data: invoice?.request?.purchaseOrder?.supplier?.name,
        isVisible: !!invoice?.request?.purchaseOrder?.supplier?.name,
      },
      {
        id: 'location',
        label: 'Location',
        type: SummaryFieldTypes.Text,
        data: invoice?.request?.asset?.branch?.name,
        isVisible: !invoice?.request?.purchaseOrder?.id,
      },
      {
        id: 'invoiceNumber',
        label: 'Service Provider Invoice Number',
        type: SummaryFieldTypes.Text,
        data: invoice?.invoiceNumber,
        isVisible: !invoice?.request?.purchaseOrder?.id,
      },
      invoice?.request?.pmId
        ? {
            id: 'asset',
            label: 'Asset',
            data: invoice?.request?.asset?.name,
            type: SummaryFieldTypes.Text,
            isVisible: !invoice?.request?.purchaseOrder?.id,
          }
        : {
            id: 'asset',
            label: 'Asset',
            data: invoiceEdited?.assetId,
            type: SummaryFieldTypes.Select,
            isEditable: invoice?.mutableFields?.includes('assetId'),
            isValid: !validations?.some(v => v.field === 'assetId'),
            validationMessage: validations?.find(v => v.field === 'assetId')
              ?.validationMessage,
            onChange: newValue =>
              handleChangeSelect('assetId', newValue, assetResult?.data?.data),
            options: assetResult?.data?.data?.map(asset => {
              return {
                label: asset?.generalAsset?.name || asset?.name,
                data: asset?.id,
              };
            }),
            required: true,
            isVisible: !invoice?.request?.purchaseOrder?.id,
          },
      {
        id: 'serviceProvider',
        label: 'Service Provider',
        type: SummaryFieldTypes.Text,
        data: invoice?.serviceProvider?.name,
        isVisible: !invoice?.request?.purchaseOrder?.id,
      },
      {
        id: 'category',
        label: 'Category',
        data: invoiceEdited?.categoryId,
        type: SummaryFieldTypes.Select,
        isEditable:
          invoice?.mutableFields?.includes('requestCategoryId') && !isPOInvoice,
        isValid: !validations?.some(v => v.field === 'categoryId'),
        validationMessage: validations?.find(v => v.field === 'categoryId')
          ?.validationMessage,
        onChange: newValue =>
          handleChangeSelect('categoryId', newValue, categoryListData?.data),
        options: dropdownCategoryList,
        required: true,
      },
    ];

    if (invoice?.coupaSetting?.coupaInvoiceUrl) {
      summaryFields.push({
        id: 'coupaId',
        label: '',
        coupaLinkText: 'CLICK HERE TO VIEW THE INVOICE IN COUPA',
        type: SummaryFieldTypes.CoupaCode,
        placeholder: 'Please log in to Coupa to view your invoice',
        data: invoice?.coupaId,
        href: invoice?.coupaSetting?.coupaInvoiceUrl || '',
      });
    }

    return summaryFields;
  }, [
    invoice,
    invoiceEdited,
    assetResult,
    dropdownCategoryList,
    validations,
    userProfile,
    categoryListData?.data,
    isPOInvoice,
  ]);

  const invoiceLocation = useMemo(() => {
    return {
      href: String(invoice?.request.asset?.branch?.id),
      text: invoice?.request.asset?.branch?.name || '',
    };
  }, [invoice]);

  const invoiceAsset = useMemo(() => {
    return !invoice?.request?.pmId && invoice?.request.asset?.id
      ? {
          href: String(invoice?.request.asset?.id),
          text: invoice?.request.asset?.name || '',
        }
      : undefined;
  }, [invoice]);

  const invoicePm = useMemo(() => {
    return invoice?.request?.pmId
      ? {
          href: String(invoice?.request?.pmId),
          text:
            invoice?.request?.pm?.name || String(invoice?.request?.pmId) || '',
        }
      : undefined;
  }, [invoice]);

  const invoiceRfps = useMemo(() => {
    return invoice ? { content: invoice?.rfps } : undefined;
  }, [invoice]);

  const invoicePO = useMemo(() => {
    return isPOInvoice
      ? {
          href: invoice?.request?.purchaseOrder?.id,
          text: invoice?.request?.purchaseOrder?.id,
        }
      : undefined;
  }, [isPOInvoice]);

  const invoiceLink = useMemo(() => {
    return {
      content: [
        {
          href: invoice?.id,
          text: invoice?.viewId,
        },
      ],
    };
  }, [invoice]);

  const invoicePOSupplier = useMemo(() => {
    return isPOInvoice
      ? {
          href: invoice?.request?.purchaseOrder?.supplier?.name,
          text: invoice?.request?.purchaseOrder?.supplier?.name,
        }
      : undefined;
  }, [isPOInvoice]);

  const invoiceProposal = useMemo(() => {
    return invoice ? { content: invoice?.proposals } : undefined;
  }, [invoice]);

  return invoice ? (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        summaryData={summaryDataInvoice}
        title={`Invoice #${invoice.viewId}`}
        showEditTitleStartAdornment={true}
        detailsData={invoicesDetails}
        editConfig={formElementsEdit.config}
        editFields={formFields}
        detailsConfig={detailsForm.config}
        detailsFields={detailsForm.fields}
        imgSrc={assetProfileAttachment?.url}
        profileAttachment={assetProfileAttachment}
        moduleName={moduleName}
        moduleId={id}
        useUpdateMutation={useUpdateInvoice}
        isEditAllowed={
          hasEditInvoicePermission[0] && !!invoice.mutableFields?.length
        }
        asset={invoiceAsset}
        workOrder={invoice.workorderLink}
        rfps={invoiceRfps}
        invoices={invoiceLink}
        pm={invoicePm}
        proposals={invoiceProposal}
        po={invoicePO}
        supplier={invoicePOSupplier}
        costTableData={currentCostTableData}
        actions={workflowStatusList}
        onActionClick={updateStatus}
        onEditButtonClick={setIsEditMode}
        showPrintButton={true}
        onPrintButtonClick={handlePrintButtonClick}
        onExitEditModeWithoutSave={initializeSummaryFields}
        checkNothingToUpdateEditForm={false}
        isLoadingActions={isLoadingActions}
        showLogo={!invoice?.request?.purchaseOrder?.id}
      />
      {isDetailsTab && (
        <>
          <ECModal
            isOpen={openReject && !isSuccessUpdateAction}
            onClose={() => setOpenReject(false)}
            noPadding
          >
            {rejectModal}
          </ECModal>
          <Popover
            id={'popover-users-table'}
            open={open}
            anchorEl={anchorEl}
            onClose={onCloseModal}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              minHeight: '300px',
              minWidth: '300px',
            }}
          >
            <ECBox
              minHeight={150}
              minWidth={650}
              maxHeight={400}
              sx={{
                '&::before': {
                  backgroundColor: 'white',
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  width: 12,
                  height: 12,
                  top: -6,
                  transform: 'rotate(45deg)',
                  left: 'calc(50% - 6px)',
                },
              }}
              p={2}
            >
              <ECUsersTableModal
                module="invoice"
                preExistingUsers={assignedToUsers}
                id={invoice.id}
              />
            </ECBox>
          </Popover>
        </>
      )}

      {openPrintActionPopover && (
        <InvoicePrintCard
          open={openPrintActionPopover}
          handleClose={() => {
            setAnchorElPrint(null);
          }}
          anchorEl={anchorElPrint}
          invoice={invoice}
          assetObject={assetResult?.data?.data}
          handleSetAnchorElPrint={handleSetAnchorElPrint}
          itemsInventory={costTableData?.itemsInventory}
        />
      )}
    </>
  ) : null;
}
