import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { ECBox } from 'app/components';

type SortableWorkOrderCardItemProps = {
  children: React.ReactNode;
  id: string;
};

const SortableWorkOrderCardItem = ({
  children,
  id,
}: SortableWorkOrderCardItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  return (
    <ECBox ref={setNodeRef} {...attributes} {...listeners}>
      {isDragging ? (
        <ECBox
          key="dragItem"
          display="flex"
          borderColor={theme => theme.palette.other.divider}
          boxShadow={2}
          p={1}
          borderRadius="0px 4px"
          bgcolor={theme => theme.palette.common.white}
          mb={1}
          maxWidth="auto"
          sx={{
            backgroundColor: '#0072ce15',
            height: '72px',
            border: '2px dashed #0090FF88',
          }}
        >
          <ECBox display="flex" flexDirection="column" width="100%">
            <ECBox display="flex" alignItems="center"></ECBox>
          </ECBox>
        </ECBox>
      ) : (
        children
      )}
    </ECBox>
  );
};

export default SortableWorkOrderCardItem;
