import { Add, AutoAwesome, SwapHorizontalCircle } from '@mui/icons-material';
import {
  useCreateAssetMutation,
  useGetAndCalculateAssetInsightsQuery,
  useGetAssetConditionListQuery,
  useUpdateAssetMutation,
} from 'services/assetApi';
import {
  ECBox,
  ECButton,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECInsightsButton,
  ECInsightsDrawer,
  ECWorkflowTemplate,
  FieldTypes,
} from 'app/components';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import {
  useGetAssetTypesListQuery,
  useGetPeriodEULListQuery,
  useGetPriorityListQuery,
} from 'services/assetTypeApi';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { useGetLocationsQuery } from 'services/locationsApi';
import {
  populateAutoCompleteFields,
  populateDropDownFields,
} from 'utils/pageUtils';
import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useTranslation } from 'react-i18next';
import { useCreateServiceRequestContext } from 'store/contexts';
import { P } from 'types/Permission';
import { useLazyGetDesignatedSPsQuery } from 'services/serviceProviderApi';
import { useGetAreaListQuery } from 'services/areaApi';
import { StyleConstants } from 'styles/StyleConstants';
import { useCustomerUser } from 'app/hooks';
import { useGetAssetWarrantyQuery } from 'services/assetApi';
import moment from 'moment';
import * as _ from 'lodash';
import { useLazyGetAssetTypesToNameByTypeIdBrachTypeIdQuery } from 'services/assetTypeToNameApi';
import { AssetTypeToNameResponse } from 'types/AssetTypeToName';
import { moduleApi, useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { useDispatch } from 'react-redux';
import { useGetManufactureListQuery } from 'services/manufactureApi';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';
import { ReplaceAsset } from './ReplaceAsset';
import { useGetAssetOpenWorkOrdersListQuery } from 'services/assetApi';
import { themes } from 'styles/theme/themes';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';

const fancyFormElementsCopy = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');

const initialFormElementsFieldsCopy = [...fancyFormElementsCopy.fields];

function extractWarrantyStartComponentFeatures(
  warrantyStartDateComponent: any,
): any[] {
  const { fieldName, value, otherOptions } = warrantyStartDateComponent;
  const features: any[] = [];
  const mainFeature: any = {
    [fieldName]: value,
  };
  features.push(mainFeature);
  if (otherOptions && Array.isArray(otherOptions)) {
    otherOptions.forEach(option => {
      const { fieldName, value } = option;
      const feature: any = {
        [fieldName]: value,
      };
      features.push(feature);
    });
  }
  features.push({ warrantyStartDate: value });
  return features;
}

export function AssetDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const match = useMatch('/panel/*');
  const dispatchRedux = useDispatch();
  const hasEditAssetPermission = useHasPermission([P.EditAsset]);

  const isDetailsTab = useMemo(() => {
    return (
      !window.location.href.includes('attachments') &&
      !window.location.href.includes('notes') &&
      !window.location.href.includes('activity') &&
      !window.location.href.includes('warranty') &&
      !window.location.href.includes('history')
    );
  }, [window.location.href]);

  const isCustomer = useCustomerUser();
  const [isEditClicked, setIsEditClicked] = useState<any>(false);
  const [isCopyClicked, setIsCopyClicked] = useState<any>(false);
  const [isAssetInsightsClicked, setIsAssetInsightsClicked] =
    useState<any>(false);
  const [isReplaceClicked, setIsReplaceClicked] = useState<any>(false);
  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});

  const [formElementsCopy, setFormElementsCopy] = useState(
    fancyFormElementsCopy,
  );
  const [formElementsEdit, setFormElementsEdit] = useState<any>(
    fancyFormElementsEdit,
  );

  const newManufacturedDate = useRef<string | null>(null);
  const newInstallationDate = useRef<string | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const { data: assetFromQuery, isSuccess: isSuccessAsset } =
    useGetModuleDetailsByIdQuery(
      { id: id || '', moduleName: 'asset' },
      { skip: !id },
    );

  const customerProfile = useCompanyCustomerProfile();
  const areaEnabled = customerProfile?.enableArea;

  const asset = useMemo(() => {
    if (!assetFromQuery) {
      return;
    }

    return {
      ...assetFromQuery,
      moduleName: 'asset',
      estimatedUsefulLifeText: assetFromQuery?.estimatedUsefulLife
        ? `${assetFromQuery?.estimatedUsefulLife} ${
            assetFromQuery?.eulPeriodCode === 'Y' ? 'Years' : 'Months'
          }`
        : '',
    };
  }, [assetFromQuery]);

  const { data: assetWarranty } = useGetAssetWarrantyQuery(Number(id), {
    skip: !id || !isDetailsTab,
  });

  useEffect(() => {
    if (!isOpen) {
      setFormElementsCopy(prevFormElements => ({
        ...prevFormElements,
        fields: initialFormElementsFieldsCopy,
      }));
    }
  }, [isOpen]);

  useEffect(() => {
    setFormElementsCopy(prevFormElements => ({
      ...prevFormElements,
      config: {
        ...prevFormElements.config,
        title: `Copy ${asset?.name}`,
      },
      fields: initialFormElementsFieldsCopy,
    }));
  }, [id, asset]);

  const [_forceRefresh, setForceRefresh] = useState(new Date());

  const [branchId, setBranchId] = useState(0);
  const [branchTypeId, setBranchTypeId] = useState(0);
  const [assetTypeId, setAssetTypeId] = useState(0);

  const [trigger, result] = useLazyGetDesignatedSPsQuery();

  const { data: designatedSPs, isSuccess: isSuccessDesignatedSPs } = result;

  useEffect(() => {
    if (branchId && assetTypeId && isDetailsTab) {
      trigger({
        branchId,
        assetTypeId,
      });
    }
  }, [trigger, branchId, assetTypeId, isDetailsTab]);

  const [triggerAssetNames, assetNamesResult, lastPromiseInfo] =
    useLazyGetAssetTypesToNameByTypeIdBrachTypeIdQuery();

  const { data: assetTypesToList, isSuccess: isSuccessAssetTypesToList } =
    assetNamesResult;

  useEffect(() => {
    if (asset?.assetTypeId && asset?.branchId && isDetailsTab) {
      trigger({
        assetTypeId: asset?.assetTypeId,
        branchId: asset?.branchId,
      });
    }
  }, [asset]);

  useEffect(() => {
    if (isSuccessDesignatedSPs && designatedSPs) {
      populateAutoCompleteFields({
        responseData: designatedSPs,
        createFormFields: formElementsCopy.fields,
        editFormFields: formElementsEdit?.fields,
        dataGroupFieldName: 'group',
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'spWarrantyCompany',
      });
      setForceRefresh(new Date());
    }
  }, [isSuccessDesignatedSPs, designatedSPs]);

  useEffect(() => {
    const manufactureFieldCopy = formElementsCopy.fields.find(
      field => field.fieldName === 'manufacture',
    );
    manufactureFieldCopy.useQuery = useGetManufactureListQuery;

    const manufactureFieldEdit = formElementsEdit.fields.find(
      field => field.fieldName === 'manufacture',
    );
    manufactureFieldEdit.useQuery = useGetManufactureListQuery;
  }, []);

  useEffect(() => {
    const assetTypeField = formElementsCopy.fields.find(
      field => field.fieldName === 'assetType',
    );
    assetTypeField.useQuery = useGetAssetTypesListQuery;
    assetTypeField.obAlias = 'assttp.name';

    const branchField = formElementsCopy.fields.find(
      field => field.fieldName === 'branch',
    );
    branchField.useQuery = useGetLocationsQuery;
    branchField.obAlias = 'brnch.name';

    const areaField = formElementsCopy.fields.find(
      field => field.fieldName === 'area',
    );
    if (areaEnabled) {
      areaField.useQuery = useGetAreaListQuery;
      areaField.obAlias = 'area.name';
    } else {
      areaField.visible = false;
    }
  }, [areaEnabled]);

  const { data: dataAssetCondition, isSuccess: isSuccessAssetCondition } =
    useGetAssetConditionListQuery(undefined, { skip: !isDetailsTab });

  useEffect(() => {
    if (isSuccessAssetCondition && dataAssetCondition) {
      populateDropDownFields({
        responseData: dataAssetCondition,
        createFormFields: formElementsCopy.fields,
        editFormFields: formElementsEdit?.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'condition.name',
      });
    }
  }, [isSuccessAssetCondition, dataAssetCondition]);

  useEffect(() => {
    if (asset && isSuccessAsset) {
      const warrantyStartDateFieldCopy = formElementsCopy.fields.find(
        field => field.fieldName === 'warrantyStartDateComponent',
      );
      const installationDateCopyIndex =
        warrantyStartDateFieldCopy?.options?.findIndex(
          field => field.fieldName === 'installationDate',
        );

      warrantyStartDateFieldCopy.options[installationDateCopyIndex] = {
        ...warrantyStartDateFieldCopy.options[installationDateCopyIndex],
        value: asset?.installationDate,
      };

      const manufactureWarrantyDateCopyIndex =
        warrantyStartDateFieldCopy?.options?.findIndex(
          field => field.fieldName === 'purchaseDate',
        );
      warrantyStartDateFieldCopy.options[manufactureWarrantyDateCopyIndex] = {
        ...warrantyStartDateFieldCopy.options[manufactureWarrantyDateCopyIndex],
        value: asset?.purchaseDate,
      };

      const storeOpenDateCopyIndex =
        warrantyStartDateFieldCopy?.options?.findIndex(
          field => field.fieldName === 'storeOpenDate',
        );
      if (warrantyStartDateFieldCopy?.options?.[storeOpenDateCopyIndex]) {
        warrantyStartDateFieldCopy.options[storeOpenDateCopyIndex] = {
          ...warrantyStartDateFieldCopy.options[storeOpenDateCopyIndex],
          value: asset?.branch?.openDate,
          required: true,
        };
      }
      if (asset?.warrantyStartDate === asset?.installationDate) {
        warrantyStartDateFieldCopy.value =
          warrantyStartDateFieldCopy.options[installationDateCopyIndex];
      } else if (
        !_.isNil(asset?.purchaseDate) &&
        asset?.warrantyStartDate ===
          moment(asset.purchaseDate).format('YYYY-MM-DD')
      ) {
        warrantyStartDateFieldCopy.value =
          warrantyStartDateFieldCopy.options[manufactureWarrantyDateCopyIndex];
      } else if (asset?.warrantyStartDate === asset?.branch?.openDate) {
        warrantyStartDateFieldCopy.value =
          warrantyStartDateFieldCopy.options[storeOpenDateCopyIndex];
      }
    }
  }, [asset, isSuccessAsset]);

  const { data: priorityData, isSuccess: idPrioritySuccess } =
    useGetPriorityListQuery({}, { skip: !isDetailsTab });

  useEffect(() => {
    if (idPrioritySuccess && priorityData) {
      populateDropDownFields({
        responseData: priorityData.data,
        createFormFields: formElementsCopy.fields,
        editFormFields: formElementsEdit?.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'priority.name',
      });
    }
  }, [idPrioritySuccess, priorityData]);

  const { data: flagsData, isSuccess: isSuccessFlag } = useGetFlagsListQuery(
    {},
    { skip: !isDetailsTab },
  );
  useEffect(() => {
    if (flagsData && isSuccessFlag) {
      let createSelectField = formElementsCopy.fields.find(
        field => field.fieldName === 'flags',
      );
      createSelectField.options =
        flagsData?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      createSelectField.optionValues = flagsData?.data.map(d => d.label) || [];

      let editSelectField = formElementsEdit?.fields.find(
        field => field.fieldName === 'flags',
      );
      editSelectField.options =
        flagsData?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      editSelectField.optionValues = flagsData?.data.map(d => d.label) || [];
    }
  }, [isSuccessFlag, flagsData]);

  const { data: eulPeriodData, isSuccess: isEulPeriodSuccess } =
    useGetPeriodEULListQuery(undefined, { skip: !isDetailsTab });

  useEffect(() => {
    if (isEulPeriodSuccess && eulPeriodData) {
      const periodValues = eulPeriodData?.map(period => ({
        label: period.description,
        id: period.code,
      }));

      const eulGroupFieldCreate = formElementsCopy?.fields?.find(
        field => field.fieldName === 'eulGroup',
      );
      const eulGroupFieldEdit = formElementsEdit?.fields.find(
        field => field.type === 'group',
      );
      if (eulGroupFieldCreate) {
        const periodFieldCreate = eulGroupFieldCreate?.subFields?.find(
          subField => subField.fieldName === 'eulPeriodCode',
        );
        periodFieldCreate.options = periodValues;
      }
      if (eulGroupFieldEdit) {
        const periodFieldEdit = eulGroupFieldEdit.subFields.find(
          subField => subField.fieldName === 'eulPeriodCode',
        );
        periodFieldEdit.options = periodValues;
      }
    }
  }, [eulPeriodData, isEulPeriodSuccess]);

  const [
    doUpdateAsset,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateAsset,
    },
  ] = useUpdateAssetMutation();

  const [
    doCreateAsset,
    {
      data: createAssetData,
      isError: isCreateError,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      reset: resetCreateAsset,
    },
  ] = useCreateAssetMutation();

  useEffect(() => {
    if (isUpdateSuccess) {
      resetUpdateAsset();
    }
  }, [isUpdateSuccess, isDetailsTab]);

  useEffect(() => {
    if (isUpdateSuccess && !isUpdateLoading) {
      dispatchRedux(moduleApi.util.invalidateTags(['ModuleDetails']));
    }
  }, [isUpdateSuccess, isUpdateLoading]);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const useUpdateAsset = useCallback(() => {
    const doUpdate = async data => {
      if (!asset?.id) {
        return;
      }

      if (
        !summaryFieldsValues?.name ||
        !summaryFieldsValues?.nte ||
        summaryFieldsValues?.warrantyNte === null ||
        summaryFieldsValues?.warrantyNte === undefined
      ) {
        setInvalidFields([
          !summaryFieldsValues?.name ? 'Required *' : '',
          !summaryFieldsValues?.nte ? 'Required *' : '',
          !summaryFieldsValues?.warrantyNte ? 'Required *' : '',
        ]);

        window.scrollTo(0, 0);

        return;
      }

      const updatedData = {
        ...data,
        name: summaryFieldsValues?.name || asset.name,
        assetType: {
          id: summaryFieldsValues?.assetType?.id || asset.assetType?.id,
        },
        area: {
          id:
            summaryFieldsValues?.area?.id !== asset.areaId
              ? summaryFieldsValues?.area?.id
              : asset.areaId,
        },
        branch: {
          id: summaryFieldsValues?.branch?.id || asset.branch?.id,
          openDate: summaryFieldsValues?.openDate || asset.branch?.openDate,
        },
        assetConditionId: data.condition?.id || null,
        priority: {
          id: summaryFieldsValues?.priorityId?.data || asset.priority?.id,
        },
        nte: summaryFieldsValues?.nte || null,
        warrantyNte: summaryFieldsValues?.warrantyNte || 0,
        status: summaryFieldsValues?.status ? 1 : 0,
        id: asset?.id,
        installationDate: newInstallationDate?.current || null,
        purchaseDate: newManufacturedDate?.current || null,
      };
      doUpdateAsset(updatedData);
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetCreateAsset,
      },
    ];
  }, [
    doUpdateAsset,
    resetCreateAsset,
    isUpdateError,
    updateError,
    isUpdateSuccess,
    isUpdateLoading,
    asset,
    summaryFieldsValues,
    newInstallationDate,
    newManufacturedDate,
  ]);
  const useCreateAsset = useCallback(() => {
    const doCreate = async data => {
      delete data.undefined;
      const { warrantyStartDateComponent, ...restData } = data;
      const warrantyStartFeatures = extractWarrantyStartComponentFeatures(
        warrantyStartDateComponent,
      );
      doCreateAsset({
        ...restData,
        ...Object.assign({}, ...warrantyStartFeatures),
        warrantyStartDateComponent,
        id: asset?.id,
      });
    };

    return [
      doCreate,
      {
        data: createAssetData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
      },
    ];
  }, [
    createAssetData,
    isCreateError,
    createError,
    isCreateLoading,
    isCreateSuccess,
    doCreateAsset,
  ]);

  useEffect(() => {
    if (createAssetData?.id) {
      handleClose();
      resetCreateAsset();
      navigate(
        `${match?.pathnameBase}/${asset?.moduleName}/${createAssetData.id}`,
      );
    }
  }, [
    createAssetData?.id,
    asset?.moduleName,
    match?.pathnameBase,
    resetCreateAsset,
    navigate,
  ]);

  const isAsset = useMemo(() => _.isNil(asset?.generalAsset), [asset]);

  const moduleName = useMemo(() => {
    return isAsset ? 'asset' : 'general-asset';
  }, [isAsset]);

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: moduleName,
      moduleId: isAsset ? asset?.id : asset?.generalAssetId,
      category: AttachmentCategory.All,
    },
    {
      skip: !isDetailsTab || !asset?.id || !asset?.moduleName,
    },
  );

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const handleClose = useCallback(() => {
    setIsCopyClicked(false);
    setIsReplaceClicked(false);
    setIsOpen(false);
    setIsAssetInsightsClicked(false);
  }, [
    setIsCopyClicked,
    setIsReplaceClicked,
    setIsOpen,
    setIsAssetInsightsClicked,
  ]);

  const handleOpen = useCallback(() => setIsOpen(true), []);

  const handleCopyClick = () => {
    setIsReplaceClicked(false);
    setIsCopyClicked(true);
    handleOpen();
  };

  const handleReplaceClick = () => {
    setIsReplaceClicked(true);
    setIsCopyClicked(false);
    handleOpen();
  };

  const { data: openWoData } = useGetAssetOpenWorkOrdersListQuery(
    {
      assetId: asset?.id,
      generalAssetId: isAsset ? undefined : asset?.generalAssetId,
      branchId: asset?.branchId,
    },
    { skip: !asset?.id || !asset?.branchId },
  );

  const { dispatch } = useCreateServiceRequestContext();

  const handleOpenServiceRequestDrawer = () =>
    dispatch({
      type: 'open',
      payload: { asset: { ...asset, openWoCount: openWoData?.length || 0 } },
    });

  const filesMemo = useMemo(() => {
    return attachments?.others?.map(attachment => ({
      id: attachment.id,
      preview: attachment.url,
      type: attachment.category,
      name: attachment.fileName,
      description: attachment.description,
      uploadedBy: attachment?.createdByUserEmail,
      uploadDate: moment(attachment?.createdAt)?.format('MM/DD/YYYY'),
      documentType: attachment?.documentCoreType?.name,
      fileType: attachment.fileType,
      attachmentType: attachment.attachmentType,
      expiresOn: attachment.expiresOn,
      originalName: attachment.originalName,
      documentRequiredId: attachment.documentRequiredId,
      moduleId: attachment.moduleId,
    }));
  }, [attachments]);

  const handleChangeCopy = (output: ECEasyFormFieldType[]) => {
    if (isCopyClicked) {
      const assetType = output.find(field => field.fieldName === 'assetType')
        ?.value as any;

      const priorityField = output.find(
        field => field.fieldName === 'priority.name',
      );
      if (priorityField && priorityField.value === undefined) {
        priorityField.value = asset?.priorityId;
      }

      const nteField = output.find(field => field.fieldName === 'nte');
      if (nteField && nteField.value === undefined) {
        nteField.value = assetType?.nte;
      }

      const warrantyNteField = output.find(
        field => field.fieldName === 'warrantyNte',
      );
      if (warrantyNteField && warrantyNteField.value === undefined) {
        warrantyNteField.value = asset?.warrantyNte;
      }

      const eulFieldGroup = output.find(field => field.type === 'group');
      const eulField = eulFieldGroup?.subFields?.find(
        field => field.fieldName === 'estimatedUsefulLife',
      );
      if (eulField && eulField.value === undefined) {
        eulField.value = assetType?.eul;
      }
      const eulPeriodCodeField = eulFieldGroup?.subFields?.find(
        field => field.fieldName === 'eulPeriodCode',
      );
      if (eulPeriodCodeField && eulPeriodCodeField.value === undefined) {
        eulPeriodCodeField.value = assetType?.period?.code;
      }

      const warrantyStartDate = (
        output.find(field => field.fieldName === 'warrantyStartDateComponent')
          ?.value as any
      )?.value;

      if (warrantyStartDate && eulField && eulPeriodCodeField) {
        const warrantyEndDate = moment(warrantyStartDate);

        if (eulPeriodCodeField.value === 'Y') {
          warrantyEndDate.add(eulField.value as number, 'years');
        } else if (eulPeriodCodeField.value === 'M') {
          warrantyEndDate.add(eulField.value as number, 'months');
        }

        const estimatedReplacementDateField = output.find(
          field => field.fieldName === 'estimatedReplacementDate',
        );

        if (estimatedReplacementDateField) {
          estimatedReplacementDateField.value = warrantyEndDate?.toDate();
          estimatedReplacementDateField.overrideRowValue = true;
        }
      }

      const branch = output.find(field => field.fieldName === 'branch')
        ?.value as any;
      const branchId = branch?.id;

      const storeOpenDate = branch?.openDate;

      const warrantyStartDateField = output.find(
        field => field.fieldName === 'warrantyStartDateComponent',
      );
      const installationDate = output.find(
        field => field.fieldName === 'installationDate',
      )?.value;
      const manufactureDate = output.find(
        field => field.fieldName === 'purchaseDate',
      )?.value;
      if (warrantyStartDateField?.options?.[2]) {
        warrantyStartDateField.options = [...warrantyStartDateField.options];
        warrantyStartDateField.options[0] = {
          ...warrantyStartDateField.options[0],
          value: installationDate,
        };
        warrantyStartDateField.options[1] = {
          ...warrantyStartDateField.options[1],
          value: manufactureDate,
        };
        warrantyStartDateField.options[2] = {
          ...warrantyStartDateField.options[2],
          value: storeOpenDate,
        };
      }

      const resourcesField = output.find(field => field.fieldName === 'files');
      if (resourcesField && resourcesField.value === undefined) {
        resourcesField.value = filesMemo?.filter(
          file => file.attachmentType === AttachmentCategory.Photo,
        );
      }

      const assetTypeId = assetType?.id;

      formElementsCopy.fields = output;
      setAssetTypeId(assetTypeId);
      setBranchId(branchId);
    }
  };

  useEffect(() => {
    if (assetTypeId && branchTypeId && isDetailsTab) {
      triggerAssetNames({
        branchTypeId,
        assetTypeId,
      });
    }
  }, [assetTypeId, branchTypeId, isDetailsTab]);

  const handleSummaryFieldChange = useCallback(
    (fieldName: string) => (value: any) => {
      setSummaryFieldsValues(prevSummaryFieldsValues => ({
        ...prevSummaryFieldsValues,
        [fieldName]: value,
      }));
      if (fieldName === 'branch') {
        const storeOpenDate = value?.openDate;

        const updatedFormElements = _.cloneDeep(formElementsEdit);

        const storeOpenDateField = updatedFormElements.fields
          .find(field => field.type === 'radio_warranty_start_date')
          ?.options.find(opt => opt.fieldName === 'storeOpenDate');

        storeOpenDateField.value = storeOpenDate;

        const newFormFields = populateEstimatedReplacementDate(
          updatedFormElements.fields,
        );
        setBranchId(value?.id);
        setBranchTypeId(value?.branchTypeId);

        triggerAssetNames({
          branchTypeId: value?.branchTypeId,
          assetTypeId: assetTypeId,
        });

        setFormElementsEdit(newFormFields);
      }

      if (fieldName === 'assetType') {
        handleSummaryFieldChange('priorityId')(value?.priority?.id);
        handleSummaryFieldChange('nte')(value?.nte);
        handleSummaryFieldChange('warrantyNte')(value?.warrantyNte);

        const updatedFormElements = _.cloneDeep(formElementsEdit);

        const eulGroup = updatedFormElements.fields.find(
          field => field.type === 'group',
        );
        const eulField = eulGroup?.subFields?.find(
          field => field.fieldName === 'estimatedUsefulLife',
        );
        if (eulField) {
          eulField.value = value?.eul;
          eulField.overrideRowValue = true;
        }
        const eulPeriodField = eulGroup?.subFields?.find(
          field => field.fieldName === 'eulPeriodCode',
        );
        if (eulPeriodField) {
          eulPeriodField.value = value?.period?.code;
          eulPeriodField.overrideRowValue = true;
        }
        const newFormFields = populateEstimatedReplacementDate(
          updatedFormElements.fields,
        );
        setAssetTypeId(value?.id);

        triggerAssetNames({
          branchTypeId,
          assetTypeId: value?.id,
        });

        setFormElementsEdit({ ...newFormFields });
      }
    },
    [assetTypeId, branchTypeId, formElementsEdit, triggerAssetNames],
  );

  const initializeFields = useCallback(() => {
    if (!isDetailsTab) {
      return;
    }

    setSummaryFieldsValues({
      ...asset,
      area: { id: asset?.areaId, name: asset?.area?.name },
      priorityId: { id: asset?.priorityId, name: asset?.priority?.name },
      assetType: {
        id: asset?.assetTypeId,
        ...asset?.assetType,
      },
    });

    setAssetTypeId(asset?.assetTypeId || 0);

    setBranchTypeId(asset?.branch?.branchTypeId || 0);

    triggerAssetNames({
      branchTypeId: asset?.branch?.branchTypeId || 0,
      assetTypeId: asset?.assetTypeId || 0,
    });

    setFormElementsEdit(prevFormElementsEdit => ({
      ...prevFormElementsEdit,
      fields: prevFormElementsEdit.fields.map(field => {
        if (field.fieldName === 'installationDate') {
          field.value = asset?.installationDate;
        }
        if (field.fieldName === 'purchaseDate') {
          field.value = asset?.purchaseDate;
        }
        if (field.fieldName === 'storeOpenDate') {
          field.value = asset?.branch?.openDate;
        }

        if (field.type === FieldTypes.RadioWarrantyStartDate) {
          field.value = {
            fieldName: asset?.warrantyStartOption,
          };
          field.overrideRowValue = true;

          field.options = [
            {
              ...field.options[0],
              value: asset?.installationDate,
            },
            {
              ...field.options[1],
              value: asset?.purchaseDate,
            },
            {
              ...field.options[2],
              value: asset?.branch?.openDate,
            },
          ];
        }
        return field;
      }),
    }));
  }, [asset]);

  useEffect(() => {
    if (asset && isSuccessAsset) {
      initializeFields();
    }
  }, [asset, isSuccessAsset]);

  const populateEstimatedReplacementDate = (output: ECEasyFormFieldType[]) => {
    const eulGroup = output.find(field => field.type === 'group');
    const eulField = eulGroup?.subFields?.find(
      field => field.fieldName === 'estimatedUsefulLife',
    );
    const eulPeriodField = eulGroup?.subFields?.find(
      field => field.fieldName === 'eulPeriodCode',
    );

    const warrantyStartDate = (
      output.find(field => field.fieldName === 'warrantyStartDateComponent')
        ?.value as any
    )?.value;

    const updatedInstallationDate = output.find(
      field => field.fieldName === 'warrantyStartDateComponent',
    )?.options?.[0]?.value;

    const updatedManufactureDate = output.find(
      field => field.fieldName === 'warrantyStartDateComponent',
    )?.options?.[1]?.value;

    const manufacturedDate = moment(updatedManufactureDate).isValid()
      ? moment(updatedManufactureDate).format('YYYY-MM-DD')
      : null;

    newManufacturedDate.current = manufacturedDate;

    const installationDate = moment(updatedInstallationDate).isValid()
      ? moment(updatedInstallationDate).format('YYYY-MM-DD')
      : null;

    newInstallationDate.current = installationDate;

    if (warrantyStartDate && eulField && eulPeriodField) {
      eulField.overrideRowValue = true;
      eulPeriodField.overrideRowValue = true;
      const warrantyEndDate = moment(warrantyStartDate);

      if (
        eulPeriodField.value === 'Y' ||
        (eulPeriodField.value as any)?.id === 'Y'
      ) {
        warrantyEndDate.add(eulField.value as number, 'years');
      } else if (
        eulPeriodField.value === 'M' ||
        (eulPeriodField.value as any)?.id === 'M'
      ) {
        warrantyEndDate.add(eulField.value as number, 'months');
      }

      const estimatedReplacementDateField = output.find(
        field => field.fieldName === 'estimatedReplacementDate',
      );

      if (estimatedReplacementDateField) {
        estimatedReplacementDateField.value = warrantyEndDate?.toDate();
        estimatedReplacementDateField.overrideRowValue = true;
      }
    }
    formElementsEdit.fields = output?.map(f => ({
      ...f,
      overrideRowValue: true,
    }));

    return formElementsEdit;
  };

  const handleChangeEdit = useCallback(
    (output: ECEasyFormFieldType[], fieldName?: string) => {
      if (isEditClicked) {
        if (
          fieldName !== 'eulPeriodCode' &&
          fieldName !== 'estimatedUsefulLife' &&
          fieldName !== 'warrantyStartDateComponent'
        ) {
          formElementsEdit.fields = output?.map(f => ({
            ...f,
            overrideRowValue: true,
          }));
          return;
        }

        const newFormFields = populateEstimatedReplacementDate(output);
        formElementsEdit.fields = newFormFields.fields;
      }
    },
    [isEditClicked],
  );

  const existingDataCopy = useMemo(() => {
    let name = formElementsCopy.fields.find(
      field => field.fieldName === 'name',
    )?.value;
    const selectedAssetType = formElementsCopy.fields.find(
      field => field.fieldName === 'assetType',
    )?.value;
    let priority = asset?.priority;
    let nte = formElementsCopy.fields.find(
      field => field.fieldName === 'nte',
    )?.value;
    let warrantyNte = formElementsCopy.fields.find(
      field => field.fieldName === 'warrantyNte',
    )?.value;

    let eulFieldGroup = formElementsCopy.fields.find(
      field => field.fieldName === 'eulGroup',
    );
    let estimatedUsefulLife = eulFieldGroup?.subFields?.find(
      field => field.fieldName === 'estimatedUsefulLife',
    )?.value;
    let eulPeriodCode = eulFieldGroup?.subFields?.find(
      field => field.fieldName === 'eulPeriodCode',
    );

    if (
      selectedAssetType &&
      selectedAssetType?.name !== asset?.assetType?.name
    ) {
      name = '';
      nte = selectedAssetType?.nte;
      warrantyNte = selectedAssetType?.warrantyNte;
      priority = selectedAssetType?.priority;
      estimatedUsefulLife = selectedAssetType?.eul;
      eulPeriodCode = selectedAssetType?.period?.code;
    } else {
      name = asset?.name;
      nte = asset?.nte;
      warrantyNte = asset?.warrantyNte;
      priority = asset?.priority;
      estimatedUsefulLife = asset?.estimatedUsefulLife;
      eulPeriodCode = asset?.eulPeriodCode;
    }

    const selectedBranch = formElementsCopy.fields.find(
      field => field.fieldName === 'branch',
    )?.value;
    const selectedArea = formElementsCopy.fields.find(
      field => field.fieldName === 'area',
    )?.value;
    const spWarrantyCompany = designatedSPs?.find(
      designatedSP =>
        designatedSP.id ===
        formElementsCopy.fields.find(
          field => field.fieldName === 'spWarrantyCompany',
        )?.value,
    );
    const flags = formElementsCopy.fields.find(
      field => field.fieldName === 'flags',
    )?.value;

    return {
      name: name,
      assetType: selectedAssetType ?? {
        ...asset?.assetType,
        period: { code: asset?.eulPeriodCode },
        eul: asset?.estimatedUsefulLife,
        id: asset?.assetTypeId,
      },
      nte: nte,
      warrantyNte: warrantyNte,
      priority: priority,
      estimatedUsefulLife: estimatedUsefulLife,
      eulPeriodCode: eulPeriodCode,
      branch: selectedBranch ?? asset?.branch,
      area: selectedArea ?? asset?.area,
      manufacture: asset?.manufacture,
      flags: flags ?? asset?.flags,
      defaultSpExpirationDate: asset?.defaultSpExpirationDate,
      description: asset?.description,
      modelNumber: asset?.modelNumber,
      serialNumber: asset?.serialNumber,
      condition: asset?.condition,
      tagId: asset?.tagId,
      originalValue: asset?.originalValue,
      replacementCost: asset?.replacementCost,
      installationDate: asset?.installationDate,
      spWarrantyCompany: spWarrantyCompany ?? asset?.spWarrantyCompany,
      purchaseDate: asset?.purchaseDate,
    };
  }, [asset, formElementsCopy.fields]);

  const { data: assetInsights, isLoading: isLoadingAssetInsights } =
    useGetAndCalculateAssetInsightsQuery(
      {
        id: asset?.id || 0,
      },
      {
        skip: !asset?.id || !isDetailsTab || !isAsset,
      },
    );

  const renderInsightsButton = useMemo(() => {
    return (
      <ECInsightsButton
        onClick={() => {
          setIsAssetInsightsClicked(true);
        }}
        title={t('assetInsights.buttonTitle')}
        status={assetInsights?.bookValue}
        icon={<AutoAwesome />}
      />
    );
  }, [assetInsights]);

  const summaryData = useMemo(() => {
    const priorityOptions = priorityData?.data.map(priority => ({
      label: priority.name,
      data: priority.id,
    }));

    const assetList = assetTypesToList as unknown as AssetTypeToNameResponse[];

    const assetNameOptions = assetList?.map(template => ({
      label: template.name,
      data: template.id,
    }));

    return [
      {
        id: 'location',
        label: 'Location',
        data: summaryFieldsValues?.branch || {},
        type: SummaryFieldTypes.SelectPaginated,
        isEditable: true,
        useQuery: useGetLocationsQuery,
        onChange: handleSummaryFieldChange('branch'),
        obAlias: 'brnch.name',
        required: true,
      },
      {
        id: 'assetGroup',
        label: 'Asset Group',
        data: summaryFieldsValues?.assetType || {},
        type: SummaryFieldTypes.SelectPaginated,
        isEditable: true,
        useQuery: useGetAssetTypesListQuery,
        obAlias: 'assttp.name',
        onChange: handleSummaryFieldChange('assetType'),
        required: true,
      },
      {
        id: 'asset',
        label: 'Asset',
        data: isEditClicked ? summaryFieldsValues?.name : asset?.name,
        type: SummaryFieldTypes.Autocomplete,
        maxLength: 200,
        isEditable: true,
        options: assetNameOptions || [],
        freeSolo: true,
        onChange: handleSummaryFieldChange('name'),
        isValid: !!summaryFieldsValues?.name,
        validationMessage: invalidFields[0],
        extraComponent: isAsset ? renderInsightsButton : null,
      },
      {
        id: 'state',
        label: 'State',
        data: summaryFieldsValues?.status === 1,
        defaultData: summaryFieldsValues?.status === 1,
        type: SummaryFieldTypes.State,
        isEditable: true,
        onChange: handleSummaryFieldChange('status'),
      },
      {
        id: 'assetId',
        label: 'Asset ID',
        data: summaryFieldsValues?.id ?? '',
        type: SummaryFieldTypes.Text,
        isEditable: false,
      },
      {
        id: 'area',
        label: 'Area',
        data: summaryFieldsValues?.area ?? {},
        type: SummaryFieldTypes.SelectPaginated,
        isEditable: true,
        useQuery: useGetAreaListQuery,
        onChange: handleSummaryFieldChange('area'),
        required: false,
        isVisible: Boolean(areaEnabled),
      },
      {
        id: 'priority',
        label: 'Priority',
        data:
          summaryFieldsValues?.priorityId?.data ||
          summaryFieldsValues?.priorityId?.id ||
          summaryFieldsValues?.priorityId ||
          '',
        options: priorityOptions ?? [],
        type: SummaryFieldTypes.Select,
        isEditable: true,
        onChange: handleSummaryFieldChange('priorityId'),
        required: true,
      },
      {
        id: 'nte',
        label: 'NTE',
        data: summaryFieldsValues?.nte,
        type: SummaryFieldTypes.Currency,
        onChange: handleSummaryFieldChange('nte'),
        isValid: !!summaryFieldsValues?.nte,
        validationMessage: invalidFields[1],
        isEditable: true,
      },
      {
        id: 'warrantyNte',
        label: 'Warranty NTE',
        data: summaryFieldsValues?.warrantyNte,
        type: SummaryFieldTypes.Currency,
        onChange: handleSummaryFieldChange('warrantyNte'),
        isValid: !!summaryFieldsValues?.warrantyNte,
        validationMessage: invalidFields[2],
        isEditable: true,
        placeholder: '',
      },
    ];
  }, [
    summaryFieldsValues,
    assetTypesToList,
    isEditClicked,
    invalidFields,
    priorityData,
    handleSummaryFieldChange,
    areaEnabled,
    renderInsightsButton,
  ]);

  const additionalActions = useMemo(() => {
    return [
      isCustomer &&
      asset?.branch?.status === 1 &&
      asset &&
      Number(asset.status) > 0 &&
      asset?.branch?.onHold !== 1 ? (
        <ECButton
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpenServiceRequestDrawer}
        >
          {t('translation:navBar.createServiceRequest')}
        </ECButton>
      ) : null,

      (!!asset?.status && (
        <ECButton
          variant="outlined"
          startIcon={<SwapHorizontalCircle />}
          onClick={handleReplaceClick}
          scopes={[P.ReplaceAsset]}
        >
          {t('translation:managedAsset.replace')}
        </ECButton>
      )) ||
        null,
    ];
  }, [isCustomer, asset, handleOpenServiceRequestDrawer, handleReplaceClick]);

  const additionalTabs = useMemo(() => {
    return [
      {
        value: 'warranty',
        label: 'Warranty',
        scopes: [P.GetAllAssets],
        content: <Outlet />,
        link: 'warranty',
        editable: true,
      },
      {
        value: 'history',
        label: t('translation:pages.serviceHistory.title'),
        scopes: [P.GetAsset],
        content: <Outlet />,
        link: 'history',
        editable: false,
      },
    ];
  }, []);

  const locationSideBar = useMemo(() => {
    if (!asset || !asset.branch) return undefined;
    return {
      href: asset.branch.id.toString(),
      text: asset.branch.name,
    };
  }, [asset?.branch]);

  const assetSideBar = useMemo(() => {
    if (!asset) return undefined;
    return {
      href: asset.id.toString(),
      text: asset.name,
    };
  }, [asset]);

  const replacedAssetSideBar = useMemo(() => {
    if (!asset || !asset.replacedAssetId) return undefined;
    return {
      href: asset.replacedAssetId.toString(),
      text: asset.replacedAsset.name ?? '',
    };
  }, [asset]);

  const replacedByAssetSideBar = useMemo(() => {
    if (!asset || !asset.replacedByAsset) return undefined;
    return {
      href: asset.replacedByAsset?.id?.toString(),
      text: asset.replacedByAsset?.name ?? '',
    };
  }, [asset]);

  const workOrderSideBar = useMemo(() => {
    return asset && asset.workorderId && asset.workorderName
      ? {
          href: asset.workorderId.toString(),
          text: asset.workorderName,
        }
      : undefined;
  }, [asset?.workorderId]);

  const handleEditButtonClick = useCallback(value => {
    setIsEditClicked(value);
  }, []);

  return (
    <>
      {asset && (
        <ECWorkflowTemplate
          tabsAsLinks
          title={asset.name}
          summaryData={summaryData}
          editConfig={formElementsEdit.config}
          editFields={formElementsEdit.fields}
          detailsConfig={formElementsDetails.data.config}
          onExitEditModeWithoutSave={initializeFields}
          detailsFields={formElementsDetails.data.fields}
          detailsData={asset}
          onChange={handleChangeEdit}
          warrantyBadgeType={assetWarranty?.[0]?.status}
          imgSrc={assetProfileAttachment?.url}
          profileAttachment={assetProfileAttachment}
          moduleName={moduleName}
          moduleId={id}
          useUpdateMutation={useUpdateAsset}
          onCopyClick={handleCopyClick}
          disableTabsOnEdit={true}
          disableSidebarOnEdit={true}
          disableAditionalActionWhenEditing={true}
          additionalTabs={additionalTabs}
          asset={assetSideBar}
          workOrder={workOrderSideBar}
          replacedAsset={replacedAssetSideBar}
          replacedByAsset={replacedByAssetSideBar}
          additionalActions={additionalActions}
          showEditTitleStartAdornment
          onEditButtonClick={handleEditButtonClick}
          isEditAllowed={hasEditAssetPermission[0]}
        />
      )}
      {isDetailsTab && (
        <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
          <ECBox display="flex" pt={StyleConstants.NAV_BAR_HEIGHT}>
            {isReplaceClicked && (
              <ReplaceAsset
                oldAsset={asset}
                handleClose={handleClose}
                oldAssetImages={filesMemo}
              />
            )}
            {isCopyClicked && (
              <ECEasyFormCreate
                useCreateMutation={useCreateAsset}
                formConfig={formElementsCopy.config}
                formFields={formElementsCopy.fields}
                existingData={existingDataCopy}
                onClose={handleClose}
                onChange={handleChangeCopy}
                stickyFooter={false}
                drawerTitleBarBGColor={themes.light.palette.other.divider}
              />
            )}
          </ECBox>
        </ECDrawerDetails>
      )}
      {asset && !isLoadingAssetInsights && (
        <ECInsightsDrawer
          isOpen={isAssetInsightsClicked}
          onClose={handleClose}
          insightsData={assetInsights || null}
          asset={asset}
        />
      )}
    </>
  );
}
