import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ECButton,
  ECEasyFormFieldType,
  ECWorkflowTemplate,
} from 'app/components';
import {
  SummaryFieldTypes,
  SummaryFieldsData,
} from 'app/components/ECWorkflowSummaryField';
import { Outlet, useParams } from 'react-router';
import { WorkflowStatusGroupName } from 'app/components/ECWorkflowStatusBadge';
import {
  useGetBranchByIdQuery,
  useGetBranchTypesQuery,
  useUpdateBranchMutation,
} from 'services/branchApi';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { ContentCopy, Pause } from '@mui/icons-material';
import { P } from 'types/Permission';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory } from 'types/Attachment';
import { CopyAssets } from './CopyAssets';
import { concatAddress } from 'utils/strings/concat-address';
import { useGetOrganizationParentListQuery } from 'services/organizationApi';
import { Address } from 'types/Address';
import _ from 'lodash';
import { useGetOnHoldReasonsQuery } from 'services/lookupApi';

const formElementsDetails = require('./fancy_form_config_details.json');
const formElementsEdit = require('./fancy_form_config_edit.json');

export function BranchDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('lease');

  const { data: branch } = useGetBranchByIdQuery(
    { id: Number(id) },
    { skip: !id || !isDetailsTab },
  );

  const OnHoldStatusToString = (status?: number) => {
    if (status && status === 1) {
      return 'On Hold';
    } else {
      return 'Live';
    }
  };

  // select_paginated queries for EDIT form
  useEffect(() => {
    // organization field
    const organizationField = formElementsEdit.fields.find(
      field => field.fieldName === 'org',
    );
    if (organizationField) {
      organizationField.useQuery = useGetOrganizationParentListQuery;
    }

    // on hold reasons field
    const onHoldReasonsField = formElementsEdit.fields.find(
      field => field.fieldName === 'onHoldReason',
    );
    if (onHoldReasonsField) {
      onHoldReasonsField.useQuery = useGetOnHoldReasonsQuery;
    }
  }, []);

  // useEffect to set the billing address fields to disabled if the mailingEqualBilling checkbox is checked
  // when first loading the page
  useEffect(() => {
    if (!branch) return;
    if (branch?.mailingEqualBilling) {
      // if there is mailingEqualBilling, set all billing address fields to disabled
      const billingAddressFields: any[] = formElementsEdit.fields?.filter(
        field =>
          field?.fieldName?.includes('billingAddress') &&
          field?.fieldName !== 'mailingEqualBilling',
      );
      const billingAddressGroupFields = formElementsEdit.fields?.find(
        field => field?.groupName === 'groupBillingAddressCodes',
      );

      billingAddressFields.forEach(billingField => {
        billingField.disabled = true;
        billingField.required = false;
      });

      billingAddressGroupFields?.subFields?.forEach(billingSubField => {
        billingSubField.disabled = true;
        billingSubField.required = false;
      });
    }
  }, [branch?.mailingEqualBilling]);

  const handleChange = (output: ECEasyFormFieldType[], fieldname, value) => {
    if (fieldname !== 'mailingEqualBilling') return;
    const isBillingSameAsAddressCheckboxSelected = (
      output.find(field => field.fieldName === 'mailingEqualBilling')
        ?.value as string[]
    )?.includes('Use same for Billing Address');

    const newFields = _.clone(output) || [];

    const billingAddressFields: any[] = newFields.filter(
      field =>
        field?.fieldName?.includes('billingAddress') &&
        field?.fieldName !== 'mailingEqualBilling',
    );

    const billingAddressGroupFields = newFields.find(
      field => field?.groupName === 'groupBillingAddressCodes',
    );
    //billingAddressFields.push(billingAddressGroupFields);

    const addressCountryCode = newFields?.find(
      data => data?.fieldName === 'mailingAddress.countryCode',
    );

    if (
      isBillingSameAsAddressCheckboxSelected &&
      Array.isArray(addressCountryCode?.value) &&
      addressCountryCode?.value?.length > 0
    ) {
      addressCountryCode.value = addressCountryCode.value[0];
    }

    if (isBillingSameAsAddressCheckboxSelected) {
      billingAddressFields.forEach(billingField => {
        billingField.value = newFields?.find(
          field =>
            field.fieldName ===
            billingField.fieldName.replace('billingAddress', 'mailingAddress'),
        )?.value;
        billingField.overrideRowValue = true;
        billingField.disabled = true;
        billingField.required = false;
      });

      const mailingGroupField = newFields.find(
        field => field?.groupName === 'groupMailingAddressCodes',
      );
      // logic for the group field of city, state and zip
      billingAddressGroupFields?.subFields?.forEach(billingSubField => {
        billingSubField.value = mailingGroupField?.subFields?.find(
          mailingSubField =>
            mailingSubField?.fieldName ===
            billingSubField?.fieldName?.replace(
              'billingAddress',
              'mailingAddress',
            ),
        )?.value;
        billingSubField.overrideRowValue = true;
        billingSubField.disabled = true;
        billingSubField.required = false;
      });
    } else {
      billingAddressFields.forEach(fieldToCopy => {
        fieldToCopy.disabled = false;

        // address 2 should not be required
        if (fieldToCopy.fieldName !== 'billingAddress.line2')
          fieldToCopy.required = true;
      });

      billingAddressGroupFields?.subFields?.forEach(billingSubField => {
        billingSubField.disabled = false;
        if (billingSubField.fieldName !== 'billingAddress.line2')
          billingSubField.required = true;
      });
    }

    let countryCode =
      newFields?.find(data => data?.fieldName === 'mailingAddress.countryCode')
        ?.value || branch?.mailingAddress?.countryCode;

    const postalCodeFields = newFields.filter(field =>
      field?.fieldName?.includes('mailingAddress.zipCodePostalCode'),
    );

    countryCode = Array.isArray(countryCode)
      ? countryCode[0]
      : countryCode?.value || countryCode;

    if (countryCode)
      postalCodeFields.forEach(postalCodeField => {
        postalCodeField.required = !(countryCode?.toLowerCase() === 'jm');
      });

    let countryCodeBilling =
      newFields?.find(data => data?.fieldName === 'billingAddress.countryCode')
        ?.value || branch?.billingAddress?.countryCode;

    const postalCodeFieldsBilling = newFields.filter(field =>
      field?.fieldName?.includes('billingAddress.zipCodePostalCode'),
    );

    countryCodeBilling = Array.isArray(countryCodeBilling)
      ? countryCodeBilling[0]
      : countryCodeBilling?.value || countryCodeBilling;

    if (countryCodeBilling)
      postalCodeFieldsBilling.forEach(postalCodeField => {
        postalCodeField.required = !(
          countryCodeBilling?.toLowerCase() === 'jm' ||
          isBillingSameAsAddressCheckboxSelected
        );
      });
  };

  const [validations, setValidations] = useState<any>([]);

  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});

  const initializeFields = useCallback(() => {
    const newSummaryFieldsValues = {
      status: branch?.status,
      name: branch?.name,
      storeNumber: branch?.storeNumber,
      branchType: { id: branch?.branchTypeId, name: branch?.branchType?.name },
      phone: branch?.phone,
      phoneExt: branch?.phoneExt,
    };
    setSummaryFieldsValues(newSummaryFieldsValues);
  }, [branch]);

  useEffect(() => {
    initializeFields();
  }, [branch]);

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    setSummaryFieldsValues(prev => ({ ...prev, [fieldName]: value }));
  };

  const summaryData: SummaryFieldsData[] = useMemo(() => {
    const mailingAddress = branch?.mailingAddress;
    const newSummaryData: SummaryFieldsData[] = [
      {
        id: 'status',
        label: 'State',
        data: summaryFieldsValues?.status,
        type: SummaryFieldTypes.State,
        isEditable: true,
        onChange: handleSummaryFieldChange('status'),
      },
      {
        id: 'onHold',
        label: 'On Hold',
        data: { label: OnHoldStatusToString(branch?.onHold), severity: 'info' },
        type: SummaryFieldTypes.SingleChip,
      },
      {
        id: 'id',
        label: 'Location ID',
        data: id,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'name',
        label: 'Location',
        data: summaryFieldsValues?.name,
        type: SummaryFieldTypes.Text,
        isEditable: true,
        required: true,
        onChange: handleSummaryFieldChange('name'),
        validationMessage: validations?.find(v => v.field === 'name')
          ?.validationMessage,
        isValid: !validations?.some(v => v.field === 'name'),
      },
      {
        id: 'storeNumber',
        label: 'Store Number',
        data: summaryFieldsValues?.storeNumber,
        type: SummaryFieldTypes.Text,
        isEditable: true,
        isNumeric: true,
        onChange: handleSummaryFieldChange('storeNumber'),
      },
      {
        id: 'branchType',
        label: 'Concept',
        data: summaryFieldsValues?.branchType,
        type: SummaryFieldTypes.SelectPaginated,
        isEditable: true,
        useQuery: useGetBranchTypesQuery,
        obAlias: 'name',
        required: true,
        onChange: handleSummaryFieldChange('branchType'),
        validationMessage: validations?.find(v => v.field === 'branchType')
          ?.validationMessage,
      },
      {
        id: 'phone',
        label: 'Phone',
        data: summaryFieldsValues?.phone,
        type: SummaryFieldTypes.Phone,
        isEditable: true,
        onChange: handleSummaryFieldChange('phone'),
      },
      {
        id: 'ext',
        label: 'Ext',
        data: summaryFieldsValues?.phoneExt,
        type: SummaryFieldTypes.Text,
        isNumeric: true,
        isEditable: true,
        onChange: handleSummaryFieldChange('phoneExt'),
      },
      {
        id: 'address',
        label: 'Address',
        data: concatAddress(
          [
            mailingAddress?.line1,
            mailingAddress?.line2,
            mailingAddress?.cityName,
            mailingAddress?.stateProvinceCode,
            mailingAddress?.zipCodePostalCode,
            mailingAddress?.countryCode,
          ].filter(Boolean),
        ),
        type: SummaryFieldTypes.Address,
      },
    ];
    return newSummaryData;
  }, [branch, id, validations, summaryFieldsValues]);

  const [isCopyAssetsModalOpen, setIsCopyAssetsModalOpen] = useState(false);
  const handleCopyAssetClick = useCallback(() => {
    setIsCopyAssetsModalOpen(true);
  }, []);

  const handleCloseCopyAssets = useCallback(() => {
    setIsCopyAssetsModalOpen(false);
  }, []);

  // On Hold Feature ticket still open
  const handleOnHoldClick = useCallback(() => {}, []);

  const addionalActions: ReactElement[] = useMemo(() => {
    const newAdditionalActions: ReactElement[] = [
      <ECButton
        variant="outlined"
        onClick={handleOnHoldClick}
        sx={{ alignSelf: 'flex-end', marginRight: 1 }}
        startIcon={<Pause />}
        scopes={[P.EditBranch]}
      >
        {OnHoldStatusToString(branch?.onHold === 1 ? 0 : 1)}
      </ECButton>,
      <ECButton
        variant="outlined"
        onClick={handleCopyAssetClick}
        sx={{ alignSelf: 'flex-end' }}
        startIcon={<ContentCopy />}
        scopes={[P.EditAsset]}
      >
        Copy Assets
      </ECButton>,
    ];

    return newAdditionalActions;
  }, [branch?.onHold]);

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: 'branch',
      moduleId: Number(id || 0),
      category: AttachmentCategory.Photo,
    },
    {
      skip: !id || !isDetailsTab,
    },
  );

  const attachmentPreview = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment => attachment?.attachmentType === AttachmentCategory.Photo,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const isCustomer = useCustomerUser();

  const [
    doUpdateLocation,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateBranchMutation();

  const useUpdateLocation = useCallback(() => {
    const doUpdate = async data => {
      /* if (data.status === 0 && !showInactiveModal) {
        setShowInactiveModal(true);
        currentData.current = data;
        return;
      } */

      const locationMailingAddress: Address = {
        line1: data.mailingAddress?.line1 || '',
        line2: data.mailingAddress?.line2 || '',
        zipCodePostalCode: data?.mailingAddress?.zipCodePostalCode || '',
        cityName: data.mailingAddress.cityName || '',
        stateProvinceCode: data.mailingAddress.stateProvinceCode || '',
        countryCode: data.mailingAddress.countryCode || '',
      };

      // validate required summary fields
      const requiredFields = ['name', 'branchType'];
      const missingFields = requiredFields.filter(
        field =>
          !summaryFieldsValues[field] || summaryFieldsValues[field] === '',
      );
      setValidations(
        missingFields.map(field => ({
          field,
          validationMessage: `Required Field`,
        })),
      );
      if (missingFields.length > 0) {
        return;
      }

      const body: /* BranchUpdateFormData */ any = {
        id: data.id,
        branchTypeId: summaryFieldsValues.branchType.id,
        organizationId: Number(data.org?.id),
        mailingAddress: locationMailingAddress,
        billingAddress: data.billingAddress,
        mailingEqualBilling: data.mailingEqualBilling?.includes(
          'Use same for Billing Address',
        )
          ? 1
          : 0,
        onHoldReasonId: data.onHoldReason.id,
        onHoldExpirationDate: data.expirationDayOnHoldStatus,
        name: summaryFieldsValues.name,
        storeNumber: summaryFieldsValues.storeNumber,
        phone: summaryFieldsValues.phone,
        phoneExt: summaryFieldsValues.phoneExt,
        averageWeeklySales: data.averageWeeklySales,
        status: summaryFieldsValues.status,
        legalEntityName: data.legalEntityName,
      };

      doUpdateLocation(body);
    };
    return [
      doUpdate,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isLoadingUpdate,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isLoadingUpdate,
    isUpdateSuccess,
    resetUpdate,
    updateData,
    id,
    summaryFieldsValues,
  ]);

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return branch ? (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        title={branch.name}
        summaryData={summaryData}
        additionalActions={addionalActions}
        useUpdateMutation={useUpdateLocation}
        onChange={handleChange}
        editConfig={formElementsEdit.config}
        editFields={formElementsEdit.fields}
        detailsConfig={formElementsDetails.config}
        detailsFields={formElementsDetails.fields}
        detailsData={branch}
        imgSrc={attachmentPreview?.url}
        profileAttachment={attachmentPreview}
        moduleName="branch"
        moduleId={id}
        hideNoteTab
        additionalTabs={[
          {
            value: 'lease',
            label: t('translation:pages.locations.lease'),
            content: <Outlet />,
            editable: true,
            link: 'lease',
          },
        ]}
      />
      <CopyAssets
        branchId={id}
        isCopyAssetsModalOpen={isCopyAssetsModalOpen}
        handleCloseCopyAssets={handleCloseCopyAssets}
      />
    </>
  ) : null;
}
