import moment from 'moment';

export const formatDateForTable = (
  rawDate: string,
  format?: string,
): string => {
  moment.updateLocale(moment.locale(), { invalidDate: 'Not available' });

  return moment(rawDate).format(format || 'MMM D, YYYY hh:mm a') ?? '';
};

export const formatDateOnlyForTable = (rawDate: string): string => {
  moment.updateLocale(moment.locale(), { invalidDate: 'Not available' });

  return moment(rawDate).format('MMM D, YYYY') ?? '';
};

export const formatDateForComment = (timestamp: number): string => {
  moment.updateLocale(moment.locale(), { invalidDate: undefined });

  return moment(timestamp).format('MMMM D, YYYY hh:mm a') ?? '';
};

export const formatDateForHistory = (timestamp: number): string => {
  moment.updateLocale(moment.locale(), { invalidDate: undefined });

  return moment(timestamp).format('MMMM D, YYYY hh:mm a') ?? '';
};

export const formatAgeForTable = (timestamp: number): string => {
  moment.updateLocale(moment.locale(), { invalidDate: undefined });

  return moment(timestamp).fromNow();
};

// new figma designs are pointing to that format, we should check what should be used;
export function formatDateForTable_2(
  date: string,
  withHours: boolean = true,
): string {
  if (!date) {
    return '-';
  }

  moment.updateLocale(moment.locale(), { invalidDate: undefined });
  return moment(date).format(`DD/MM/YYYY ${withHours ? 'hh:mm a' : ''}`) ?? '-';
}

export const isoDateWithTimezoneAtZero = (date: Date) => {
  const momentDate = moment(date);
  const isoDateWithoutTimezone = momentDate.format(
    'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  );
  return isoDateWithoutTimezone;
};
